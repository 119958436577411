import React, { Component } from "react";
// Api
import { ticketDaberna } from "../../Api";

// Components
import CountDown from "react-countdown";
import "react-owl-carousel2/src/owl.carousel.css";
import { NavLink, Redirect } from "react-router-dom";
import Imgticket from "./select-ticket.png";
import ImgDeselectticket from "./deselect-ticket.png";
import Imgbuyticket from "./buy-ticket.png";
import CountDownView from "../../components/CountDown";
import "../../assets/css/flickity.css";
import Flickity from "react-flickity-component";
import { getLongDate, interpreter, numberWithCommas } from "../../utills";

// assets
import Card1 from "../deberna-game/card-1.png";
import Card2 from "../deberna-game/card-2.png";
import Card3 from "../deberna-game/card-3.png";
class Deberna extends Component {
  state = {
    Slider: "",
    LatestDaberna: "",
    SliderTicket: "",
    Tickets: [],
    selectedTicket: [],
    totalPrice: 0,
    redirect: false,
    sliderButton: true,
    loading: false,
  };
  flickityOptions = {
    rightToLeft: true,
    cellAlign: "center",
    pageDots: false,
    prevNextButtons: true,
    contain: false,
    draggable: true,
  };

  componentDidUpdate(prev) {
    if (
      prev.LatestDaberna.StartsAt_Raw !== this.props.LatestDaberna.StartsAt_Raw
    ) {
      this.getTicket(this.props.LatestDaberna.id);
    }
  }

  componentDidMount() {
    this.getTicket(this.props.LatestDaberna.id);

    this.flkty.on("change", (e) => {
      const findIndex = this.state.selectedTicket.findIndex(
        (x) => x === this.state.Tickets[e].TicketID
      );
      this.setState({
        SliderTicket: this.state.Tickets[e].TicketID,
        sliderButton: findIndex === -1,
      });
    });
  }

  getTicket = async (id) => {
    try {
      if (id) {
        const { data } = await ticketDaberna.TicketList({ tournamentId: id });
        if (data.success) {
          localStorage.setItem("tick", JSON.stringify(data.tickets))
          this.setState({
            Tickets: data.tickets,
            SliderTicket: data.tickets[0].TicketID,
            selectedTicket: [],
            totalPrice: 0,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  addTicket = () => {
    const { SliderTicket } = this.state;
    let selectedTicket = [...this.state.selectedTicket];
    let sliderButton = "";
    const index = selectedTicket.indexOf(SliderTicket);
    if (index > -1) {
      selectedTicket = selectedTicket.filter((x) => x !== SliderTicket);
      sliderButton = true;
    } else {
      selectedTicket.push(SliderTicket);
      sliderButton = false;
    }

    const totalPrice = this.calculator(selectedTicket);
    this.setState({ selectedTicket: selectedTicket, totalPrice, sliderButton });
  };

  calculator = (selectedTicket) => {
    const { LatestDaberna } = this.props;
    return LatestDaberna.Price * selectedTicket.length;
  };

  handleSubmit = () => {
    localStorage.setItem("idGameOffline", this.props.LatestDaberna.id)

    const userAuth = JSON.parse(localStorage.getItem("data"));

    if (!userAuth) {
      localStorage.removeItem("returnPath");

      return localStorage.setItem("returnPath", this.props.location.pathname);
    }
    localStorage.setItem(
      "selectedTicket",
      JSON.stringify(this.state.selectedTicket)
    );
    localStorage.setItem("Tickets", JSON.stringify(this.state.Tickets));

    if (this.state.selectedTicket && this.state.selectedTicket.length > 0)
      this.requestBuyTicket(this.props.LatestDaberna.id)

  };
  async requestBuyTicket(id) {
    try {
      let { data } = await ticketDaberna.buyTicket({
        tournamentId: id,
        selectionArray: JSON.stringify(this.state.selectedTicket),
      });


    } catch (error) {
      console.error(error);
    }
  };
  render() {
    const userAuth = JSON.parse(localStorage.getItem("data"));

    const { LatestDaberna, showCountDown } = this.props;
    const {
      Tickets,
      selectedTicket,
      totalPrice,
      redirect,
      sliderButton,
      loading
    } = this.state;
    return (
      <>
        {redirect ? <Redirect to="/login" /> : ""}
        <div className="row columns">
          <div className="deberna_smallbox mb-50">
            <div className="row">
              {/* countdown */}
              <div className="large-9 float-center">
                <div className="deberna_countdown">
                  {(LatestDaberna &&
                    LatestDaberna.StartsAt_Raw &&
                    showCountDown && !loading) ? (
                    <>

                      <CountDown
                        //  date={  LatestDaberna.StartsAt_Raw * 1000}
                        date={getLongDate(LatestDaberna.StartsAt_gregorian)}
                        precision={3}
                        overtime={true}
                        renderer={CountDownView}
                        onComplete={() => this.setState({ loading: true })}
                        onStart={() => {
                          this.setState({ loading: false })
                        }}
                      />
                      <div className="title">{interpreter("Countdown")}</div>
                    </>
                  ) : (
                    ""
                  )}
                  {
                    loading && <span className="title">{interpreter("loadingDeberna")}  درحال بارزگاری</span>
                  }
                </div>
              </div>
              {/* // end countdown */}

              <div className="row columns">


                <div className="large-9 medium-9 small-12 columns">
                  <div className="deberna_card_slider">
                    <Flickity
                      options={this.flickityOptions}
                      flickityRef={(e) => (this.flkty = e)}
                    >
                      {/* start card */}
                      {Tickets && Tickets.length
                        ? Tickets.map((eachTicket) => (
                          <>
                            <div
                              className="deberna_card_table"
                              style={{ direction: "ltr" }}
                              key={eachTicket.TicketID}
                            >
                              {eachTicket &&
                                eachTicket.ticket &&
                                eachTicket.ticket.length
                                ? eachTicket.ticket.map((ArrayNumber) => (
                                  <div className="display_flex">
                                    {ArrayNumber.map((number) => (
                                      <span>{number ? number : ""}</span>
                                    ))}
                                  </div>
                                ))
                                : ""}
                            </div>
                          </>
                        ))
                        : ""}
                    </Flickity>
                  </div>

                  <div className="text-center mb">
                    <button onClick={this.addTicket}>
                      {sliderButton ? (
                        <img className="pointer" src={Imgticket} alt="" />
                      ) : (
                        <img
                          className="pointer"
                          src={ImgDeselectticket}
                          alt=""
                        />
                      )}
                    </button>
                  </div>
                </div>

                <div className="large-3 medium-3 columns hide-for-small-only">
                  <div className="deberna_img_tagline">
                    <img src={Card3} alt="" />
                  </div>
                  <div className="deberna_img_tagline">
                    <img src={Card2} alt="" />
                  </div>
                  <div className="deberna_img_tagline">
                    <img src={Card1} alt="" />
                  </div>
                </div>
              </div>
              <div className="deberna_prices">
                <div className="price_item">
                  <span className="text">{interpreter("cardPrice")}</span>
                  <span className="value">
                    {numberWithCommas(LatestDaberna.Price)}&nbsp;
                    {interpreter("Toman")}
                  </span>
                </div>
                <div className="price_item">
                  <span className="text">{interpreter("selectedCards")}</span>
                  <span className="value">{selectedTicket.length}</span>
                </div>
                <div className="price_item">
                  <span className="text">{interpreter("totalPrice")}</span>
                  <span className="value">
                    {numberWithCommas(totalPrice)}&nbsp;
                    {interpreter("Toman")}
                  </span>
                </div>

                <NavLink
                  onClick={this.handleSubmit}
                  to={
                    userAuth
                      ? `/bingoGame-${this.props.LatestDaberna.id}`
                      : "/login"
                  }
                >
                  <img src={Imgbuyticket} alt="" />
                </NavLink>
              </div>
            </div>
            {/* // end row */}
          </div>
        </div>
      </>
    );
  }
}
export default Deberna;
