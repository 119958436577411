import { APIClient, APIClientRippo, APIClientDaberna } from "./_api";

export function basic() {
  return APIClient.get(`/basic/`);
}

export function translator(values) {
  let bodyFormData = new FormData();

 // bodyFormData.append("lang", values.lang);
  bodyFormData.append("lang", values.lang);

  if (values.type) bodyFormData.append("type", values.type);

  return APIClient.post(`/translator/`, bodyFormData);
}

export function slidersMain() {
  return APIClient.get(`/sliders/`);
}
export function sliders() {
  return APIClientRippo.get(`/sliders/`);
}
export function slidersDaberna() {
  return APIClientDaberna.get(`/sliders/`);
}

export function getLiveStream() {
  return APIClient.get(`/livestream/`);
}
export function getcurrentDrawNumbers() {
  return APIClientRippo.get(`/currentDrawNumbers/`);
}
export function announcements() {
  return APIClient.get(`/announcements/`);
}

export function GetExtraIndexContent() {
  return APIClientRippo.get(`/extraIndexContent/`);
}
export function RulesAccordion() {
  return APIClient.get(`/extraPages/`);
}
