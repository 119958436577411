import moment from "moment-jalaali";
import momentT from "moment-timezone";
import { baseURL, baseURLPIC } from "../Api/_api";
export function numberWithCommas(x) {
  if (x || x === 0) {
    var parts = x.toString();
    parts = parts.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts;
  }
}
export function reducer(myArray) {
  if (myArray.length) {
    let dataFeildArray = myArray.reduce((total, str, index, arr) => {
      let a = total + str;
      if (index < arr.length - 1) {
        return a + ",";
      } else {
        return a;
      }
    }, "");
    return dataFeildArray;
  }
  return myArray;
}

export function getDrawTimeStart(date = "") {


  return getLongDate(date) - getLongDateNowSystem()



}

export function getLongDate(date = "") {


  if (date) {
    // const dateAndTime = date.split(" ")

    // const _date = dateAndTime[0].split("-")
    // const time = dateAndTime[1].split(":")


    // const result = new Date(Number(_date[0]), Number(_date[1]) - 1, Number(_date[2]), Number(time[0]), Number(time[1]), Number(time[2]))
    // const test = result.toLocaleString('en-IR', { timeZone: 'Asia/Tehran', });
    // console.log(date)
    // console.log()
    //  moment.utc

    //  const dateLong = momentT.tz(date, 'YYYY-MM-DD HH:mm:ss.IR').fo.valueOf();
    const dateLong = momentT.tz(date, 'Asia/Tehran').valueOf();
    return dateLong
  }
  else return 0
}
export function getLongDateNowSystem() {

  // var d = new Date();
  // const date = d.toLocaleString('en-IR', { timeZone: 'Asia/Tehran' }).replace(",", "");

  // const dateAndTime = date.split(" ")

  // const _date = dateAndTime[0].split("/").reverse()
  // const time = dateAndTime[1].split(":")
  // const type = dateAndTime[2]
  // let hour = Number(time[0])
  // hour = type === "PM" ? hour + 12 : hour

  // const result = new Date(Number(_date[0]), Number(_date[1]) - 1, Number(_date[2]), hour, Number(time[1]), Number(time[2]))

  return momentT.tz('Asia/Tehran').valueOf()

}
export function toJalali(date) {
  return moment(date).format("jYYYY/jMM/jDD");
}
export function toJalaliHour(date) {
  return moment(date).format("HH:mm jYYYY/jMM/jDD");
}

export function toIdArray(arr) {
  return arr.map((x) => {
    return x.id;
  });
}

export function toMiladi(date) {
  return moment(date).format("YYYY/MM/DD");
}
export function toMiladiHour(date) {
  return moment(date).format("HH:mm YYYY/MM/DD ");
}
export function objectToID(obj) {
  return obj.id;
}

export function stringifier(data) {
  return JSON.stringify(data);
}
export function pictureUrlMaker(url) {
  return `${baseURL}${url}`;
}
export function localStorgeSubscriber(name) {
  let localStorger = "";
  if (typeof window !== "undefined") {
    localStorger = JSON.parse(localStorage.getItem(name));
    return localStorger || "";
  } else {
    setTimeout(() => {
      localStorgeSubscriber(name);
    }, 100);
    return "";
  }
}
export function toTime(date) {
  let sec = date % 60;
  let min = Math.floor(date / 60) % 60;
  let hour = Math.floor((date / 3600) % 24);
  let day = Math.floor(date / 3600 / 24);

  const time =
    (day ? day + ":" : "") +
    (hour ? hour + ":" : "") +
    (min ? min + ":" : "") +
    (sec ? sec : "0");

  return time;
}

export function interpreter(key) {
  let lang = localStorgeSubscriber("lang");
  if (lang) {
    return lang[key] ? lang[key] : "";
  } else {
    setTimeout(() => {
      interpreter(key);
    }, 500);
  }
}
export function userDataGetter(key, localkey) {
  let lang = localStorgeSubscriber(localkey);
  if (lang) {
    if (key) {
      return lang[key] ? lang[key] : "";
    } else {
      return lang;
    }
  } else {
    setTimeout(() => {
      userDataGetter(key);
    }, 500);
  }
}
