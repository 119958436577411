import React, { Component } from "react";

// API
import { financial } from "../../Api";

// Components
import Title from "./title";
import Menu from "./menu.js";
import { interpreter } from "../../utills";
import ProfileCard from "./profile_card.js";
import { Formik, Field, Form } from "formik";
import Layout from "../../components/layout/main";
import SweetAlert from "../../components/sweetalert/v";
import Swal from "sweetalert2";

class Dashboard extends Component {
  state = {
    Link: "",
    initialValues: {
      evoucher: "",
      activationCode: "",
    },
  };

  getLinks = async () => {
    try {
      const { data } = await financial.getPaymentLink();
      if (data && data.link) {
        window.open(
          data.link,
          "_blank" // <- This is what makes it open in a new window.
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  handleSubmit = async (values) => {
    try {
      const { data } = await financial.perfectCharge(values);
      if (data.success) {
        Swal.close();
      }
    } catch (error) {
      console.error(error);
    }
  };
  openModal = () => {
    SweetAlert({
      allowOutsideClick: true,
      title: "شارژ حساب",
      width: "60vw",
      html: (
        <>
          <Formik
            initialValues={this.state.initialValues}
            onSubmit={this.handleSubmit}
          >
            {(props) => (
              <Form onSubmitCapture={props.handleSubmit}>
                <div className="row">
                  <div className="medium-6 columns">
                    <Field
                      name="evoucher"
                      placeholder={interpreter("evoucher")}
                      type="text"
                    />
                  </div>
                  <div className="medium-6 columns">
                    <Field
                      name="activationCode"
                      placeholder={interpreter("activationCode")}
                      type="text"
                    />
                  </div>
                  <div className="columns text-left">
                    <button className="button confrim" type="submit">
                      {interpreter("acceptAndSubmit")}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </>
      ),
    });
  };

  render() {
    return (
      <div>
        {/* breadcrumb */}
        <div className="row">
          <div className="large-3 medium-4 columns">
            <div className="dashboard_sidebar">
              <ProfileCard />
              <Menu />
            </div>
          </div>

          {/* start content */}
          <div className="large-9 medium-8 columns">
            <div className="dashboard_panel">
              <Title title={interpreter("addBalance")} icon="wallet" />

              <table className="ripo_table">
                <thead>
                  <tr>
                    <td>{interpreter("type")}</td>
                    <td>{interpreter("pay")}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-title={interpreter("type")}>
                      {interpreter("chargingAccount")}
                    </td>
                    <td data-title={interpreter("pay")}>
                      <button
                        className="button confrim"
                        type="button"
                        onClick={this.getLinks}
                      >
                        {interpreter("acceptAndPay")}
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td data-title={interpreter("type")}>
                      {interpreter("perfectCharge")}
                    </td>
                    <td data-title={interpreter("pay")}>
                      <div>
                        <button
                          className="button confrim"
                          onClick={() => this.openModal()}
                        >
                          {interpreter("acceptAndPay")}
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/* // end content */}
        </div>
      </div>
    );
  }
}
export default Layout(Dashboard);
