import React from 'react';

import Spinner from '../spinner';

import './style.css';

export default function loader({ text, children }) {
  return (
    <div className="loder-table-form">
      <Spinner />
      <span>{text}</span>
      {children}
    </div>
  );
}
