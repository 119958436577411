import React, { Component } from "react";

import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "../../assets/css/animate.css";

// test data
import { pictureUrlMaker } from "../../utills";
import { basic } from "../../Api";

class Slider extends Component {
  state = {
    options: {
      items: 1,
      dots: true,
      nav: false,
      rewind: true,
      rtl: true,
      loop: true,
      autoHeight: true,
      margin: 0,
      autoplay: true,
      smartSpeed: 600,
      animateIn: "fadeIn",
      animateOut: "fadeOut",
    },
    Slider: "",
  };
  componentDidMount() {
    this.getSliderData();
  }
  getSliderData = async () => {
    const { data } = await basic.sliders();
    
    this.setState({ Slider: data });
  };
  render() {
    const { Slider } = this.state;
    return (
      <>
        <div className="row columns">
          <div className="main_slider" >
           {Slider.numberOfActiveSliders > 0 &&
            <OwlCarousel ref="driknkj" options={this.state.options}>
             
                 {Slider.sliders.map((x) => (
                    <div className="item" key={Math.random()}>
                      <img
                        className="imgItem"
                        src={pictureUrlMaker(x.imageUrl)}
                        alt=""
                      />
                    </div>
                  ))}
                 
            </OwlCarousel>
            }
          </div>
        </div>
      </>
    );
  }
}

export default Slider;
