import React, { Component } from "react";

// API

// Components

import Layout from "../../components/layout/main";
import Menu from "./menu.js";
import ProfileCard from "./profile_card.js";
import Title from "./title";
import { Formik, Form, Field } from "formik";
import { user } from "../../Api";
import { interpreter, userDataGetter } from "../../utills";

class Dashboard extends Component {
  initialValues = {
    newPassword2: "",
    newPassword1: "",
    currentPassword: "",
    userMobile: ""
  };
  handleSubmit = async (values, actions) => {
    try {
      let userMobile = userDataGetter("userMobile", "data");

      const viewModel = {
        userMobile: userMobile,
        currentPassword: values.currentPassword,
        newPassword1: values.newPassword1,
        newPassword2: values.newPassword2
      };
      const { data } = await user.editProfile(viewModel);
      if (data.success) {
        actions.resetForm();
      }
    } catch (error) {
      console.error(error);
    }
  };
  render() {
    return (
      <div>
        {/* breadcrumb */}
        <div className="row">
          <div className="large-3 medium-4 columns">
            <div className="dashboard_sidebar">
              <ProfileCard />
              <Menu />
            </div>
          </div>

          {/* start content */}
          <div className="large-9 medium-8 columns">
            <div className="dashboard_panel">
              <Title title={interpreter("changePassword")} icon="lock" />

              {/* ==== */}
              <Formik
                initialValues={this.initialValues}
                onSubmit={this.handleSubmit}
              >
                {props => (
                  <Form onSubmitCapture={props.handleSubmit}>
                    <div className="form_control">
                      <Field
                        name="currentPassword"
                        placeholder={interpreter("currentPassword")}
                        type="password"
                      />
                    </div>

                    <div className="form_control">
                      <Field
                        name="newPassword1"
                        placeholder={interpreter("newPassword")}
                        type="password"
                      />
                    </div>
                    <div className="form_control">
                      <Field
                        name="newPassword2"
                        placeholder={interpreter("repeatNewPassword")}
                        type="password"
                      />
                    </div>
                    <div className="text-left">
                      <button className="button confrim">
                        {interpreter("update")}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
              {/* // end === */}
            </div>
          </div>
          {/* // end content */}
        </div>
      </div>
    );
  }
}
export default Layout(Dashboard);
