import React, { Component } from "react";

import Avatar from "../../assets/images/avatar.png";
import { interpreter, numberWithCommas } from "../../utills";
import { user } from "../../Api";

class profile_card extends Component {
  state = { Me: { userName: "", userBalance: "" } };
  componentDidMount() {
    this.setData();
  }
  setData = async () => {
    const { data } = await user.Current();
    if (data.loginStatus) {
      await this.setState({ Me: data });
      localStorage.setItem("data", JSON.stringify(data));
    }
  };
  render() {
    const { Me } = this.state;
    return (
      <div className="profile_card">
        <div className="image">
          <img src={Avatar} alt="" />
        </div>
        <div className="name">
          <span className="pe-user"></span>&nbsp;{Me && Me.userName}
        </div>
        <div className="blance">
          <span className="pe-cash"></span>&nbsp;
          {` ${interpreter("balance")}: ${numberWithCommas(Me.userBalance)} ${interpreter("Toman")}`}
        </div>
      </div>
    );
  }
}
export default profile_card;
