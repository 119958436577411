import { APIClientDaberna,APIClientDabernaNoError } from "./_api";
const base = "/ticket";

export function TicketList(values) {
  let bodyFormData = new FormData();

  const userAuth = localStorage.getItem("userAuth");
  for (let key in values) {
    bodyFormData.append(key, values[key]);
  }
  bodyFormData.append("userAuth", userAuth || "");

  return APIClientDabernaNoError.post(`${base}/list/`, bodyFormData);
}

export function buyTicket(values) {
  let bodyFormData = new FormData();
  const userAuth = localStorage.getItem("userAuth");

  for (let key in values) {
    bodyFormData.append(key, values[key]);
  }
  bodyFormData.append("userAuth", userAuth || "");
  return APIClientDaberna.post(`${base}/buy/`, bodyFormData);
}

export function boughtTicket(values) {
  let bodyFormData = new FormData();
  const userAuth = localStorage.getItem("userAuth");
  if (userAuth) {
    for (let key in values) {
      bodyFormData.append(key, values[key]);
    }
    bodyFormData.append("userAuth", userAuth || "");
    return APIClientDaberna.post(`${base}/bought/`, bodyFormData);
  }
}
