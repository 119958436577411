import React,{useEffect,useState} from "react";

import { Grid } from "@material-ui/core";
import Flickity from "react-flickity-component";
import { liveBingo } from "../../../../Api";
import { interpreter } from "../../../../utills";
import { connect } from "react-redux";
import { getBingoLive } from "../../../../redux/selectors";
import Card from './Card'


const flickityOptions = {
    rightToLeft: true,
    cellAlign: "center",
    pageDots: false,
    prevNextButtons: true,
    contain: false,
    draggable: true,
  };

const NearestCards=()=>{ 

    const [listCard, setListCard] = useState([]);

   useEffect(() => {
       setTimeout(() => {
        requestBingoNearestCards();
       }, 150);
   
      }, []);
    
      const requestBingoNearestCards = async () => {
        try {
          let { data } = await liveBingo.bingoNearestCards();
    
          if (data.success) { 
            setListCard(Object.values( data.tickets)); 
          } 
        } catch (error) {
          console.error(error);
        }
      };


    return(
        <>
        {listCard.length>0&&
        <>
         <div className="title_card">
         {interpreter("Cards_close_to_the_range_of_a_ine")}
       </div>
            <Grid container justify="center">
            <div
              className="large-9 medium-9 small-12 columns"
              style={{ margin: "auto" }}
            >
              <div className="deberna_card_slider background-card">
                <Flickity
                  options={flickityOptions}
                  //  flickityRef={(e) => (this.flkty = e)}
                >
                  
                  {listCard.map((eachTicket) => (
                      <>
                       <Card eachTicket={eachTicket}/>
                      </>
                    ))}
                </Flickity>
              </div>
            </div>
          </Grid>
        </>
        
        }
      
      </>
    
    
    )
}
 
  
export default NearestCards
 