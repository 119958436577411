import React, { Component } from "react";



// components
import OwlCarousel from "react-owl-carousel2";
import TornomentCard from '../../components/card-tornoment';
import ImgTornoment from '../../assets/images/tornoment/weekly.png';


class Tornoment extends Component {
  state = {
    options: {
      rtl: true,
      dots: false,
      nav: true,
      rewind: true,
      autoplay: true,
      smartSpeed: 500,
      items: 1
    }
  };

  render() {
    const { tournaments } = this.props;

    return (
      <>
        <div className="tornoment_card">
          <OwlCarousel ref="ss1" options={this.state.options}>
            {tournaments.map(x => (
              <TornomentCard
                tournament={x}
                key={Math.random()}
                image={ImgTornoment}
                type="weekly"
                home={true}
              />
            ))}
          </OwlCarousel>
        </div>
      </>
    );
  }
}
export default Tornoment;
