import React, { Component } from "react";

// API

// components
import Layout from "../../components/layout";

//
import "./index.css";

// svg icons
import CalendarStart from "../../assets/svg/start-date";
import CalendarEnd from "../../assets/svg/end-date";
// import StopTime from "../../assets/svg/stop-time";
import Podium from "../../assets/svg/podium";
// import People from "../../assets/svg/people";
import Cash from "../../assets/svg/cash";
import { tournaments } from "../../Api";
import {
  interpreter,
  localStorgeSubscriber,
  numberWithCommas,
  toJalaliHour,
} from "../../utills";
import { Redirect } from "react-router-dom";

class FutureTornoment extends Component {
  state = {
    details: {
      tournamentName: "",
      tournamentType: "",
      startDateTime_gregorian: "",
      startDateTime_jalali: "",
      finishDateTime_gregorian: "",
      finishDateTime_jalali: "",
      drawDateTime_gregorian: "",
      drawDateTime_jalali: "",
      noWinnersSeats: "",
      ticketPrice: "",
      totalPrize: "",
      userHasParticipatedInTheTournament: "",
    },
    id: "",
    luckyNumber: "",
    LuckyNumberSubmited: false,
    redirectLogin: "",
    redirectRegister: "",
  };
  componentDidMount() {
    this.getArchive(this.props.match.params.id);
    this.setState({ id: this.props.match.params.id });
  }

  getArchive = async (id) => {
    try {
      let { data } = await tournaments.tournamentDetails({ tournamentId: id });
      this.setState({
        details: data,
        luckyNumber:
          data.userHasParticipatedInTheTournament &&
            data.participationDetails &&
            data.participationDetails.luckyNumber
            ? data.participationDetails.luckyNumber
            : "",
        LuckyNumberSubmited:
          data.userHasParticipatedInTheTournament &&
            data.participationDetails &&
            data.participationDetails.luckyNumber
            ? true
            : false,
      });
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const {
      tournamentName,
      tournamentType,
      startDateTime_gregorian,
      finishDateTime_gregorian,
      drawDateTime_gregorian,
      noWinnersSeats,
      ticketPrice,
      totalPrize,
    } = this.state.details;
    let { userLang } = localStorgeSubscriber("data");
    return (
      <>
        {this.state.redirectLogin && <Redirect to="/login" />}
        {this.state.redirectRegister && <Redirect to="/register" />}
        <div>
          <div className="row columns tornoment_page">
            <div className="tornoment_page_title">
              <div className="txt">{tournamentName}</div>
              <div className="type">{interpreter(tournamentType)}</div>
            </div>
          </div>
          <div className="row mb">
            {/* ==== */}
            <div className="columns mb">
              <div className="all_tornoment_time horizontal section3 mb">
                <div className="text">{interpreter("eachTicketPrice")}</div>
                <div className="value">
                  {`${numberWithCommas(ticketPrice)} ${interpreter("Toman")}`}
                </div>
              </div>
            </div>
            {/* ==== */}
            <div className="large-6 medium-12 small-12 columns mb">
              <div className="all_tornoment_time section1">
                <div className="text">{interpreter("drawTime")}</div>
                <div className="value">
                  {!userLang || userLang === "fa"
                    ? toJalaliHour(drawDateTime_gregorian)
                    : drawDateTime_gregorian}
                </div>
              </div>
            </div>
            <div className="large-6 medium-12 small-12 columns mb">
              <div className="all_tornoment_time section2">
                <div className="text">{interpreter("totalPrize")}</div>
                <div className="value">
                  {`${numberWithCommas(totalPrize)} ${interpreter("Toman")}`}
                </div>
              </div>
            </div>
          </div>

          <div className="row columns mb">
            <div className="text_panel ">
              <div className="panel_title">{interpreter("details")}</div>
              <div className="tornoment_description">
                <div className="item">
                  <CalendarStart />
                  <span>{interpreter("startTime")}:</span>
                  {!userLang || userLang === "fa"
                    ? toJalaliHour(startDateTime_gregorian)
                    : startDateTime_gregorian}
                </div>
                <div className="item">
                  <CalendarEnd />
                  <span>{interpreter("endTime")}:</span>
                  {!userLang || userLang === "fa"
                    ? toJalaliHour(finishDateTime_gregorian)
                    : finishDateTime_gregorian}
                </div>
                <div className="item">
                  <Podium />
                  <span>{interpreter("winnerSitNumber")}:</span>&nbsp;&nbsp;
                  {noWinnersSeats} {interpreter("place")}
                </div>
                <div className="item">
                  <Cash />
                  <span> {interpreter("totalPrize")}:</span>&nbsp;&nbsp;
                  {`${numberWithCommas(totalPrize)} ${interpreter("Toman")}`}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Layout(FutureTornoment);
