import { Container, Grid } from "@material-ui/core"
import React from "react"

import monthlyTornoment from '../../assets/images/tornoment/monthly.png';
import weeklyTornoment from '../../assets/images/tornoment/weekly.png';
import ImgTornoment from '../../assets/images/tornoment/special.png';
import backIcon from './back1.png';
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
const ShowTournoment = () => {

    return (
        <Container maxWidth="lg"
        >
            <Grid style={{
                paddingLeft: 22,
                paddingRight: 22
            }}>
                <div className="tornoment_card_main"  >

                    <div className="total_prize" style={{ marginLeft: 10, marginRight: 30, display: "flex", flexDirection: "column" }}>
                        <span className="titleTou">تورنومنت ها</span>
                        <NavLink
                            to="/wingo"
                        >
                            <Grid className="showTou">  مشاهده </Grid>
                        </NavLink>

                    </div>
                    <div style={{ display: "flex", marginRight: 10, marginLeft: 10, marginTop: 8, marginBottom: 8 }}>
                        <img src={ImgTornoment} alt="" className="imgTou" />
                        <img src={weeklyTornoment} alt="" className="imgTou" />
                        <img src={monthlyTornoment} alt="" className="imgTou" />
                    </div>
                </div>
            </Grid>
        </Container>
    )

}

export default ShowTournoment