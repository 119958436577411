import React, { Component } from "react";

import { Grid } from "@material-ui/core";
// API
import { basic } from "../../Api";
import Header from "../../components/layout/header-main";
import YoutubeLive from "youtube-live-react";
// Components
import { Helmet } from "react-helmet";
import { Suspense, lazy } from "react";

import LiveHeader from "./liveHeader";
import {
  interpreter, 
} from "../../utills";
import LoadingProcess from "./LoadingProcess2";
import Footer from "../../components/layout/footer";
// Style

import "./index.css";
const CardsUser = React.lazy(() => import("./cardsUser"));
const Line3Cards = React.lazy(() => import("./line3Card"));
const LuckyNumbers = React.lazy(() => import("./luckyNumbers"));
const Description = React.lazy(() => import("./description"));
const BingoLive = () => {
  return (
    <>
      <Header color="red" />

      <div>
        <LiveHeader />
        
      
        <Suspense
          fallback={<div className="loadingText"> {interpreter("loading")}</div>}
        >
          <Description/>
          <LuckyNumbers />
          <Line3Cards />
          <CardsUser />
        </Suspense>
      </div>
      <Footer red />
    </>
  );
};
export default BingoLive;
