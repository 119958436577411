export default [
   
     {
      "ch": "0",
      "size": 100,
      "style": "Bold",
      "w": 59.96,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-1.027, -4.108],
                      [-2.07, -2.999],
                      [-3.209, -1.678],
                      [-4.397, 0],
                      [-3.408, 1.678],
                      [-2.194, 2.999],
                      [-1.08, 4.108],
                      [0, 4.89],
                      [1.015, 4.116],
                      [2.178, 2.982],
                      [3.342, 1.701],
                      [4.556, 0],
                      [3.242, -1.701],
                      [2.103, -2.982],
                      [1.059, -4.1],
                      [0, -4.861]
                    ],
                    "o": [
                      [1.027, 4.108],
                      [2.07, 2.999],
                      [3.209, 1.678],
                      [4.555, 0],
                      [3.407, -1.678],
                      [2.194, -2.999],
                      [1.08, -4.108],
                      [0, -4.829],
                      [-1.015, -4.116],
                      [-2.178, -2.982],
                      [-3.342, -1.701],
                      [-4.494, 0],
                      [-3.242, 1.701],
                      [-2.103, 2.982],
                      [-1.059, 4.1],
                      [0, 4.89]
                    ],
                    "v": [
                      [5.74, -18.974],
                      [10.384, -8.315],
                      [18.303, -1.298],
                      [29.712, 1.221],
                      [41.656, -1.298],
                      [50.059, -8.315],
                      [54.971, -18.974],
                      [56.592, -32.471],
                      [55.07, -45.889],
                      [50.281, -56.535],
                      [42, -63.56],
                      [30.152, -66.113],
                      [18.548, -63.56],
                      [10.531, -56.535],
                      [5.788, -45.913],
                      [4.199, -32.471]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "0",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ind": 1,
                "ty": "sh",
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-1.99, 3.491],
                      [-3.458, 0],
                      [-1.909, -3.491],
                      [0, -7.372],
                      [1.909, -3.491],
                      [3.393, 0],
                      [2.023, 3.508],
                      [0, 7.373]
                    ],
                    "o": [
                      [1.99, -3.491],
                      [3.393, 0],
                      [1.909, 3.491],
                      [0, 7.405],
                      [-1.909, 3.491],
                      [-3.393, 0],
                      [-2.023, -3.508],
                      [0, -7.372]
                    ],
                    "v": [
                      [21.783, -48.766],
                      [29.956, -54.004],
                      [37.908, -48.766],
                      [40.771, -32.471],
                      [37.908, -16.125],
                      [29.956, -10.889],
                      [21.833, -16.15],
                      [18.799, -32.471]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "0",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "0",
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Aller"
    },
     {
      "ch": "1",
      "size": 100,
      "style": "Bold",
      "w": 59.96,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-0.879, -1.787],
                      [-1.27, -1.657],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.065, -0.935],
                      [0, -0.951],
                      [-0.082, -0.935],
                      [-0.195, -1.148],
                      [0, 0],
                      [-0.066, 0.935],
                      [0, 1.083],
                      [0.065, 0.935],
                      [0.195, 1.149],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0.879, 1.755],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-0.228, 1.149],
                      [-0.066, 0.935],
                      [0, 1.083],
                      [0.081, 0.935],
                      [0, 0],
                      [0.195, -1.148],
                      [0.065, -0.935],
                      [0, -0.951],
                      [-0.066, -0.935],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.52, 1.917]
                    ],
                    "v": [
                      [10.498, -47.25],
                      [13.721, -42.133],
                      [25.977, -47.055],
                      [25.977, -12.109],
                      [11.523, -12.109],
                      [11.084, -8.984],
                      [10.986, -6.153],
                      [11.108, -3.126],
                      [11.523, 0],
                      [54.395, 0],
                      [54.785, -3.126],
                      [54.883, -6.153],
                      [54.785, -8.984],
                      [54.395, -12.109],
                      [40.088, -12.109],
                      [40.088, -65.381],
                      [37.345, -65.381],
                      [8.398, -52.806]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "1",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Aller"
    }, 
    {
      "ch": "2",
      "size": 100,
      "style": "Bold",
      "w": 59.96,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 2.196],
                      [0.391, 1.97],
                      [0, 0],
                      [0, 0],
                      [-1.596, 2.3],
                      [-1.181, 2.187],
                      [-0.655, 2.203],
                      [0, 2.415],
                      [1.181, 2.44],
                      [2.122, 1.62],
                      [2.963, 0.77],
                      [3.548, 0],
                      [1.514, -0.164],
                      [1.399, -0.311],
                      [1.367, -0.508],
                      [1.595, -0.591],
                      [-0.635, -1.965],
                      [-0.879, -1.867],
                      [-0.961, 0.359],
                      [-1.009, 0.278],
                      [-1.091, 0.13],
                      [-1.465, 0],
                      [-1.97, -1.534],
                      [0, -2.806],
                      [0.635, -1.631],
                      [1.237, -1.909],
                      [1.79, -2.26],
                      [2.278, -2.991],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0.391, -1.97],
                      [0, -1.937],
                      [0, 0],
                      [0, 0],
                      [1.978, -2.675],
                      [1.595, -2.3],
                      [1.181, -2.187],
                      [0.654, -2.202],
                      [0, -3.275],
                      [-1.181, -2.439],
                      [-2.122, -1.62],
                      [-2.963, -0.769],
                      [-1.921, 0],
                      [-1.514, 0.164],
                      [-1.4, 0.311],
                      [-1.367, 0.509],
                      [0.13, 1.999],
                      [0.635, 1.966],
                      [1.074, -0.489],
                      [0.96, -0.359],
                      [1.009, -0.278],
                      [1.09, -0.13],
                      [3.548, 0],
                      [1.969, 1.534],
                      [0, 1.697],
                      [-0.635, 1.631],
                      [-1.237, 1.909],
                      [-1.791, 2.26],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [6.299, 0],
                      [51.709, 0],
                      [52.295, -6.248],
                      [51.709, -12.109],
                      [30.713, -12.109],
                      [35.96, -19.206],
                      [41.321, -26.669],
                      [45.485, -33.399],
                      [48.238, -39.983],
                      [49.219, -46.908],
                      [47.447, -55.479],
                      [42.493, -61.568],
                      [34.865, -65.154],
                      [25.098, -66.309],
                      [19.946, -66.062],
                      [15.576, -65.349],
                      [11.426, -64.119],
                      [6.982, -62.47],
                      [8.13, -56.524],
                      [10.4, -50.774],
                      [13.452, -52.046],
                      [16.406, -53.001],
                      [19.556, -53.613],
                      [23.389, -53.809],
                      [31.665, -51.508],
                      [34.619, -44.999],
                      [33.667, -40.006],
                      [30.859, -34.696],
                      [26.318, -28.444],
                      [20.215, -20.569],
                      [5.615, -1.501]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "2",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "2",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Aller"
    },
     {
      "ch": "3",
      "size": 100,
      "style": "Bold",
      "w": 59.96,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-1.465, -0.436],
                      [-1.433, -0.259],
                      [-1.579, -0.098],
                      [-1.986, 0],
                      [-3.532, 1.087],
                      [-2.605, 2.159],
                      [-1.465, 3.23],
                      [0, 4.253],
                      [0.764, 2.136],
                      [1.432, 1.631],
                      [2.1, 1.012],
                      [2.604, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, -2.131],
                      [-0.391, -1.97],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.205, 0.066],
                      [-1.14, 0],
                      [-1.595, -0.359],
                      [-1.205, -0.8],
                      [-0.732, -1.239],
                      [0, -1.663],
                      [0.83, -1.598],
                      [1.399, -1.044],
                      [1.888, -0.489],
                      [2.148, 0],
                      [2.229, 0.522],
                      [2.539, 0.816],
                      [0.635, -2.072],
                      [0.13, -2.039]
                    ],
                    "o": [
                      [1.465, 0.436],
                      [1.432, 0.258],
                      [1.579, 0.096],
                      [4.134, 0],
                      [3.532, -1.088],
                      [2.604, -2.159],
                      [1.465, -3.23],
                      [0, -2.218],
                      [-0.765, -2.136],
                      [-1.433, -1.631],
                      [-2.1, -1.011],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-0.391, 1.97],
                      [0, 2.002],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [1.106, -0.326],
                      [1.204, -0.066],
                      [1.725, 0],
                      [1.595, 0.359],
                      [1.204, 0.8],
                      [0.732, 1.24],
                      [0, 2.284],
                      [-0.83, 1.598],
                      [-1.4, 1.044],
                      [-1.888, 0.49],
                      [-2.93, 0],
                      [-2.23, -0.522],
                      [-0.945, 1.586],
                      [-0.635, 2.072],
                      [1.79, 0.581]
                    ],
                    "v": [
                      [8.887, 4.97],
                      [13.232, 6.011],
                      [17.749, 6.544],
                      [23.096, 6.689],
                      [34.595, 5.058],
                      [43.799, 0.188],
                      [49.902, -7.895],
                      [52.1, -19.12],
                      [50.952, -25.652],
                      [47.656, -31.303],
                      [42.358, -35.266],
                      [35.303, -36.783],
                      [34.912, -36.783],
                      [50.977, -63.393],
                      [50, -65.088],
                      [8.203, -65.088],
                      [7.617, -58.936],
                      [8.203, -52.979],
                      [30.176, -52.979],
                      [16.797, -28.759],
                      [17.822, -27.242],
                      [21.289, -27.829],
                      [24.805, -27.927],
                      [29.785, -27.389],
                      [33.984, -25.652],
                      [36.89, -22.594],
                      [37.988, -18.24],
                      [36.743, -12.416],
                      [33.398, -8.453],
                      [28.467, -6.154],
                      [22.412, -5.42],
                      [14.673, -6.203],
                      [7.52, -8.209],
                      [5.151, -2.722],
                      [4.004, 3.444]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "3",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "3",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Aller"
    },
     {
      "ch": "4",
      "size": 100,
      "style": "Bold",
      "w": 59.96,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.23, -0.098],
                      [-1.23, 0],
                      [-1.08, 0.098],
                      [-1.163, 0.13],
                      [0, 0],
                      [0, 0],
                      [-0.098, 1.058],
                      [0, 1.009],
                      [0.391, 1.921],
                      [0, 0],
                      [0, 0],
                      [1.23, 0.114],
                      [1.23, 0],
                      [1.097, -0.114],
                      [1.163, -0.13],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.124, 0.7],
                      [2.278, 0.261],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [1.23, 0.13],
                      [1.23, 0.098],
                      [1.163, 0],
                      [1.08, -0.098],
                      [0, 0],
                      [0, 0],
                      [0.227, -0.944],
                      [0.098, -1.057],
                      [-0.033, -2.083],
                      [0, 0],
                      [0, 0],
                      [-1.23, -0.13],
                      [-1.23, -0.114],
                      [-1.131, 0],
                      [-1.097, 0.114],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.895, -1.465],
                      [-2.125, -0.7],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [2.49, -8.105],
                      [36.084, -8.105],
                      [36.084, 5.908],
                      [39.774, 6.25],
                      [43.464, 6.396],
                      [46.829, 6.25],
                      [50.195, 5.908],
                      [50.195, -8.105],
                      [57.373, -8.105],
                      [57.861, -11.108],
                      [58.008, -14.209],
                      [57.373, -20.215],
                      [50.195, -20.215],
                      [50.195, -36.182],
                      [46.505, -36.548],
                      [42.815, -36.719],
                      [39.474, -36.548],
                      [36.084, -36.182],
                      [36.084, -20.215],
                      [21.777, -20.215],
                      [42.516, -62.012],
                      [36.488, -65.259],
                      [29.883, -66.699],
                      [1.611, -9.521]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "4",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "4",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Aller"
    },
      {
      "ch": "5",
      "size": 100,
      "style": "Bold",
      "w": 59.96,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-1.66, -0.407],
                      [-1.498, -0.228],
                      [-1.302, -0.098],
                      [-0.879, 0],
                      [-3.742, 1.06],
                      [-2.585, 2.136],
                      [-1.404, 3.147],
                      [0, 4.143],
                      [1.037, 2.562],
                      [1.897, 1.746],
                      [2.669, 0.88],
                      [3.255, 0],
                      [0.928, -0.098],
                      [1.204, -0.26],
                      [0, 0],
                      [0, 0],
                      [0, 2.132],
                      [0.383, 1.97],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.839, 0.418],
                      [-1.921, 0],
                      [-2.197, -1.878],
                      [0, -3.368],
                      [0.537, -1.427],
                      [1.253, -1.086],
                      [2.018, -0.665],
                      [2.995, 0],
                      [2.067, 0.456],
                      [2.344, 0.814],
                      [0.684, -2.034],
                      [0.195, -2.068]
                    ],
                    "o": [
                      [1.66, 0.407],
                      [1.497, 0.227],
                      [1.302, 0.098],
                      [4.655, 0],
                      [3.742, -1.06],
                      [2.585, -2.136],
                      [1.404, -3.147],
                      [0, -3.329],
                      [-1.038, -2.562],
                      [-1.897, -1.745],
                      [-2.67, -0.88],
                      [-1.009, 0],
                      [-0.928, 0.098],
                      [0, 0],
                      [0, 0],
                      [0.383, -1.97],
                      [0, -2.002],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [1.855, -1.053],
                      [1.839, -0.417],
                      [4.948, 0],
                      [2.197, 1.879],
                      [0, 1.524],
                      [-0.537, 1.427],
                      [-1.254, 1.086],
                      [-2.019, 0.665],
                      [-2.312, 0],
                      [-2.068, -0.455],
                      [-0.781, 1.936],
                      [-0.684, 2.035],
                      [1.563, 0.52]
                    ],
                    "v": [
                      [9.912, 5.103],
                      [14.648, 6.055],
                      [18.848, 6.543],
                      [22.119, 6.689],
                      [34.715, 5.099],
                      [44.205, 0.305],
                      [50.188, -7.619],
                      [52.295, -18.554],
                      [50.739, -27.391],
                      [46.338, -33.852],
                      [39.489, -37.79],
                      [30.602, -39.111],
                      [27.697, -38.965],
                      [24.499, -38.428],
                      [25.085, -52.979],
                      [48.083, -52.979],
                      [48.657, -59.13],
                      [48.083, -65.088],
                      [12.5, -65.088],
                      [10.986, -27.008],
                      [12.5, -25.488],
                      [18.042, -27.694],
                      [23.682, -28.32],
                      [34.399, -25.502],
                      [37.695, -17.631],
                      [36.89, -13.204],
                      [34.204, -9.435],
                      [29.297, -6.808],
                      [21.777, -5.811],
                      [15.21, -6.494],
                      [8.594, -8.398],
                      [6.396, -2.442],
                      [5.078, 3.711]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "5",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "5",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Aller"
    },
     {
      "ch": "6",
      "size": 100,
      "style": "Bold",
      "w": 59.96,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-4.349, -4.859],
                      [-8.247, 0],
                      [-3.056, 1.038],
                      [-2.312, 2.061],
                      [-1.423, 3.019],
                      [0, 3.992],
                      [1.146, 2.975],
                      [1.953, 1.935],
                      [2.582, 0.975],
                      [2.84, 0],
                      [1.534, -0.434],
                      [1.158, -0.718],
                      [0.848, -0.851],
                      [0.587, -0.902],
                      [-1.302, 2.51],
                      [-2.035, 1.96],
                      [-2.865, 1.199],
                      [-3.744, 0.13],
                      [0, 1.66],
                      [0.224, 1.074],
                      [0.321, 1.074],
                      [4.928, -2.379],
                      [3.247, -3.975],
                      [1.583, -5.311],
                      [0, -6.06]
                    ],
                    "o": [
                      [4.349, 4.86],
                      [3.168, 0],
                      [3.055, -1.038],
                      [2.312, -2.061],
                      [1.422, -3.018],
                      [0, -3.934],
                      [-1.146, -2.975],
                      [-1.953, -1.934],
                      [-2.583, -0.975],
                      [-2.023, 0],
                      [-1.534, 0.435],
                      [-1.158, 0.718],
                      [-0.848, 0.852],
                      [0.455, -2.785],
                      [1.302, -2.51],
                      [2.034, -1.959],
                      [2.864, -1.199],
                      [0.578, -1.888],
                      [0, -1.106],
                      [-0.225, -1.074],
                      [-6.689, 0.327],
                      [-4.929, 2.379],
                      [-3.248, 3.975],
                      [-1.584, 5.312],
                      [0, 9.719]
                    ],
                    "v": [
                      [12.235, -6.068],
                      [31.128, 1.221],
                      [40.464, -0.337],
                      [48.515, -4.987],
                      [54.116, -12.606],
                      [56.25, -23.122],
                      [54.531, -33.488],
                      [49.883, -40.852],
                      [43.08, -45.216],
                      [34.946, -46.68],
                      [29.611, -46.028],
                      [25.573, -44.299],
                      [22.563, -41.945],
                      [20.41, -39.314],
                      [23.047, -47.258],
                      [28.052, -53.963],
                      [35.4, -58.701],
                      [45.313, -60.693],
                      [46.18, -66.016],
                      [45.843, -69.287],
                      [45.023, -72.51],
                      [27.598, -68.452],
                      [15.334, -58.922],
                      [8.088, -44.993],
                      [5.713, -27.936]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "6",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ind": 1,
                "ty": "sh",
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-1.974, 1.981],
                      [-3.328, 0],
                      [-1.974, -1.915],
                      [0, -4.42],
                      [2.072, -2.079],
                      [3.066, 0],
                      [1.909, 2.243],
                      [0, 4.224]
                    ],
                    "o": [
                      [1.974, -1.981],
                      [3.001, 0],
                      [1.974, 1.916],
                      [0, 4.224],
                      [-2.072, 2.08],
                      [-3.198, 0],
                      [-1.909, -2.243],
                      [0, -3.961]
                    ],
                    "v": [
                      [23.273, -32.233],
                      [31.226, -35.205],
                      [38.689, -32.332],
                      [41.65, -22.828],
                      [38.542, -13.373],
                      [30.835, -10.254],
                      [23.175, -13.618],
                      [20.313, -23.319]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "6",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "6",
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Aller"
    },
     {
      "ch": "7",
      "size": 100,
      "style": "Bold",
      "w": 59.96,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-0.391, -1.97],
                      [0, 0],
                      [0, 0],
                      [-2.165, -0.879],
                      [-2.409, -0.521],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, -2.131]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [2.051, 1.53],
                      [2.164, 0.879],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-0.391, 1.97],
                      [0, 2.002]
                    ],
                    "v": [
                      [6.885, -52.979],
                      [37.012, -52.979],
                      [14.111, 1.709],
                      [20.435, 5.322],
                      [27.295, 7.422],
                      [56.689, -63.925],
                      [56.104, -65.088],
                      [6.885, -65.088],
                      [6.299, -58.936]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "7",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "7",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Aller"
    },
    {
      "ch": "8",
      "size": 100,
      "style": "Bold",
      "w": 59.96,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-1.433, -2.574],
                      [-2.377, -1.645],
                      [-3.027, -0.733],
                      [-3.191, 0],
                      [-3.027, 0.732],
                      [-2.361, 1.645],
                      [-1.449, 2.558],
                      [0, 3.616],
                      [0.731, 2.05],
                      [1.096, 1.575],
                      [1.462, 1.099],
                      [1.431, 0.755],
                      [-1.847, 2.673],
                      [0, 3.412],
                      [1.187, 2.228],
                      [2.035, 1.57],
                      [2.832, 0.865],
                      [3.256, 0],
                      [2.803, -0.865],
                      [2.043, -1.57],
                      [1.165, -2.227],
                      [0, -2.756],
                      [-1.799, -2.69],
                      [-2.767, -1.541],
                      [1.401, -1.131],
                      [1.146, -1.575],
                      [0.684, -2.05],
                      [0, -2.559]
                    ],
                    "o": [
                      [1.432, 2.574],
                      [2.376, 1.645],
                      [3.027, 0.732],
                      [3.223, 0],
                      [3.027, -0.733],
                      [2.36, -1.645],
                      [1.448, -2.557],
                      [0, -2.625],
                      [-0.731, -2.05],
                      [-1.097, -1.575],
                      [-1.463, -1.099],
                      [2.787, -1.607],
                      [1.846, -2.673],
                      [0, -2.756],
                      [-1.187, -2.227],
                      [-2.036, -1.57],
                      [-2.832, -0.865],
                      [-3.242, 0],
                      [-2.804, 0.865],
                      [-2.043, 1.57],
                      [-1.166, 2.228],
                      [0, 3.445],
                      [1.798, 2.69],
                      [-1.497, 0.755],
                      [-1.401, 1.132],
                      [-1.146, 1.575],
                      [-0.684, 2.05],
                      [0, 3.584]
                    ],
                    "v": [
                      [6.836, -9.776],
                      [12.549, -3.446],
                      [20.654, 0.121],
                      [29.98, 1.221],
                      [39.355, 0.121],
                      [47.437, -3.446],
                      [53.149, -9.751],
                      [55.322, -19.012],
                      [54.225, -26.024],
                      [51.484, -31.461],
                      [47.646, -35.471],
                      [43.306, -38.252],
                      [50.257, -44.673],
                      [53.027, -53.801],
                      [51.247, -61.276],
                      [46.413, -66.972],
                      [39.113, -70.626],
                      [29.98, -71.924],
                      [20.911, -70.626],
                      [13.641, -66.972],
                      [8.828, -61.276],
                      [7.08, -53.801],
                      [9.778, -44.599],
                      [16.626, -38.252],
                      [12.28, -35.423],
                      [8.459, -31.363],
                      [5.714, -25.925],
                      [4.688, -19.012]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "8",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ind": 1,
                "ty": "sh",
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-2.002, 2.051],
                      [-3.191, 1.083],
                      [-2.084, -2.017],
                      [0, -3.215],
                      [1.839, -1.738],
                      [3.613, 0],
                      [1.904, 1.755],
                      [0, 3.149]
                    ],
                    "o": [
                      [2.002, -2.05],
                      [3.19, 1.083],
                      [2.083, 2.017],
                      [0, 3.182],
                      [-1.839, 1.739],
                      [-3.451, 0],
                      [-1.904, -1.755],
                      [0, -3.149]
                    ],
                    "v": [
                      [22.095, -28.042],
                      [29.883, -32.741],
                      [37.793, -28.091],
                      [40.918, -20.242],
                      [38.159, -12.862],
                      [29.98, -10.254],
                      [21.948, -12.886],
                      [19.092, -20.242]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "8",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ind": 2,
                "ty": "sh",
                "ix": 3,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-1.465, 1.443],
                      [-2.93, 0],
                      [-1.498, -1.443],
                      [0, -2.361],
                      [1.643, -1.64],
                      [2.669, -0.951],
                      [1.627, 1.64],
                      [0, 2.821]
                    ],
                    "o": [
                      [1.465, -1.443],
                      [2.962, 0],
                      [1.497, 1.443],
                      [0, 2.821],
                      [-1.644, 1.64],
                      [-2.605, -0.951],
                      [-1.628, -1.64],
                      [0, -2.361]
                    ],
                    "v": [
                      [23.389, -58.968],
                      [29.98, -61.133],
                      [36.67, -58.968],
                      [38.916, -53.26],
                      [36.45, -46.568],
                      [29.98, -42.68],
                      [23.633, -46.568],
                      [21.191, -53.26]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "8",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "8",
            "np": 6,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Aller"
    },
      {
      "ch": "9",
      "size": 100,
      "style": "Bold",
      "w": 59.96,
      "data": {
        "shapes": [
          {
            "ty": "gr",
            "it": [
              {
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-1.162, -2.916],
                      [-1.953, -1.917],
                      [-2.583, -0.967],
                      [-2.841, 0],
                      [-1.534, 0.451],
                      [-1.175, 0.719],
                      [-0.832, 0.835],
                      [-0.587, 0.902],
                      [1.27, -2.545],
                      [2.034, -1.987],
                      [2.881, -1.215],
                      [3.678, -0.131],
                      [0, -1.595],
                      [-0.241, -1.074],
                      [-0.322, -1.074],
                      [-4.928, 2.419],
                      [-3.232, 3.99],
                      [-1.6, 5.331],
                      [0, 6.084],
                      [4.365, 4.808],
                      [8.246, 0],
                      [3.04, -1.028],
                      [2.328, -2.042],
                      [1.422, -2.991],
                      [0, -3.955]
                    ],
                    "o": [
                      [1.162, 2.916],
                      [1.953, 1.917],
                      [2.582, 0.967],
                      [1.99, 0],
                      [1.534, -0.451],
                      [1.175, -0.718],
                      [0.832, -0.835],
                      [-0.521, 2.824],
                      [-1.27, 2.545],
                      [-2.035, 1.987],
                      [-2.881, 1.215],
                      [-0.61, 1.921],
                      [0, 1.139],
                      [0.241, 1.074],
                      [6.689, -0.261],
                      [4.928, -2.421],
                      [3.232, -3.99],
                      [1.599, -5.331],
                      [0, -9.615],
                      [-4.366, -4.807],
                      [-3.168, 0],
                      [-3.04, 1.029],
                      [-2.328, 2.042],
                      [-1.423, 2.991],
                      [0, 3.964]
                    ],
                    "v": [
                      [6.332, -31.627],
                      [11.005, -24.378],
                      [17.809, -20.053],
                      [25.943, -18.604],
                      [31.229, -19.279],
                      [35.291, -21.033],
                      [38.3, -23.363],
                      [40.43, -25.969],
                      [37.744, -17.915],
                      [32.788, -11.118],
                      [25.414, -6.316],
                      [15.575, -4.297],
                      [14.66, 0.977],
                      [15.021, 4.297],
                      [15.865, 7.52],
                      [33.29, 3.499],
                      [45.529, -6.116],
                      [52.776, -20.098],
                      [55.176, -37.219],
                      [48.628, -58.853],
                      [29.711, -66.064],
                      [20.399, -64.521],
                      [12.349, -59.914],
                      [6.724, -52.366],
                      [4.59, -41.948]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "9",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              },
              {
                "ind": 1,
                "ty": "sh",
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [-2.072, 2.043],
                      [-3.067, 0],
                      [-1.909, -2.203],
                      [0, -4.15],
                      [1.99, -1.946],
                      [3.328, 0],
                      [1.974, 1.882],
                      [0, 4.343]
                    ],
                    "o": [
                      [2.072, -2.042],
                      [3.23, 0],
                      [1.909, 2.203],
                      [0, 3.893],
                      [-1.991, 1.946],
                      [-3.001, 0],
                      [-1.974, -1.881],
                      [0, -4.149]
                    ],
                    "v": [
                      [22.297, -51.526],
                      [30.005, -54.59],
                      [37.713, -51.285],
                      [40.576, -41.755],
                      [37.591, -32.997],
                      [29.613, -30.078],
                      [22.15, -32.901],
                      [19.189, -42.238]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                "nm": "9",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
              }
            ],
            "nm": "9",
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
          }
        ]
      },
      "fFamily": "Aller"
    }

    
]