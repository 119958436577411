import React, { Component } from "react";

// Components
import IMG from "../../assets/images/wingo-winner.jpg";
import SweetAlert from "../../components/sweetalert/v";
import { interpreter } from "../../utills";

class Weekwinner extends Component {
  componentDidMount() { }

  openModal = () => {
    SweetAlert({
      allowOutsideClick: true,
      title: interpreter("WeeklyWinner"),
      width: "60vw",
      html: (
        <>
          <div className="text-center"
           style={{height:"470px"}}>
            <img src={this.props.img} alt={interpreter("WeeklyWinner")} />
          </div>
        </>
      ),
    });
  };

  render() {
    return (
      <>
        <div className="week_winner">
          <img src={IMG} alt={interpreter("WeeklyWinner")} onClick={(e) => this.openModal()} />
        </div>
      </>
    );
  }
}
export default Weekwinner;
