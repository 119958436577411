import React, { Component } from "react";

// API

import { financial } from "../../Api";

// Components

import Layout from "../../components/layout/main";
import Menu from "./menu.js";
import ProfileCard from "./profile_card.js";
import Title from "./title";
import { interpreter } from "../../utills";

class Dashboard extends Component {
  state = { Link: "" };

  //* lifeCycles
  componentDidMount() {
    this.getLinks();
  }

  getLinks = async () => {
    try {
      const { data } = await financial.getWithdrawalLink();
      this.setState({ Link: data.link });
    } catch (error) {
      console.error(error);
    }
  };
  render() {
    const { Link } = this.state;
    return (
      <div>
        {/* breadcrumb */}
        <div className="row">
          <div className="large-3 medium-4 columns">
            <div className="dashboard_sidebar">
              <ProfileCard />
              <Menu />
            </div>
          </div>

          {/* start content */}
          <div className="large-9 medium-8 columns">
            <div className="dashboard_panel">
              <Title title={interpreter("withdrawalRequest")} icon="cash" />

              <table className="ripo_table">
                <thead>
                  <tr>
                    <td>{interpreter("type")}</td>
                    <td>{interpreter("Withdrawal")}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-title={interpreter("type")}>{interpreter("withdrawalFromAccount")}</td>
                    <td data-title={interpreter("Withdrawal")}>
                      <a href={Link} target="_blank" rel="noopener noreferrer">
                        <button className="button confrim">
                          {interpreter("acceptAndSubmit")}
                        </button>
                      </a>
                    </td>
                  </tr>
                  {/* <tr>
                    <td>نوع دوم برداشت حساب</td>
                    <td>
                      <button className="button confrim">تایید و ثبت</button>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
          {/* // end content */}
        </div>
      </div>
    );
  }
}
export default Layout(Dashboard);
