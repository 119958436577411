import React, { Component } from "react";
import Img from "./checked.png";
class Card extends Component {
  render() {
    const { data, selected, ticketSelector, DrawNumbers } = this.props;

    return (
      <>
        {/* اگه بخواد حالت کلیک شدن بگیره کلاس clickable */}
        {/* اگه بخواد حالت انتخاب شده بگیره کلاس selected */}
        <div
          className={selected ? "item selected" : "item clickable"}
          style={{ direction: "ltr" }}
          onClick={() => ticketSelector(data.TicketID)}
        >
          {selected ? <img src={Img} className="checked" alt="" /> : null}
          {/* ==== */}
          <div className="deberna_card_table ">
            {data && data.ticket && data.ticket.length
              ? data.ticket.map((row) => (
                  <div className="display_flex">
                    {row && row.length
                      ? row.map((number) => {
                          const isExsit = DrawNumbers.find(
                            (x) => Number(number) === Number(x)
                          );
                          return (
                            <span style={{ color: isExsit ? "red" : "#2f2f2f" }}>
                              {number ? number : ""}
                            </span>
                          );
                        })
                      : ""}
                  </div>
                ))
              : ""}
          </div>
        </div>
      </>
    );
  }
}
export default Card;
