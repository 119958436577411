import React, { Component } from "react";

// API
import { basic } from "../../Api";

import Footer from "../../components/layout/footer";
import Header from "../../components/layout/header-main";
// Components
import { Helmet } from "react-helmet";
import ReactPlayer from "react-player";
import {
  interpreter,
  localStorgeSubscriber,
  numberWithCommas,
  toJalaliHour,
} from "../../utills";

import { withRouter } from 'react-router-dom';
import ReactHtmlParser from "react-html-parser";
import Layout from "../../components/layout/main";
import CountDownView from "../../components/CountDown";
import CardUser from './cardUser'

// Style
import "./index.css";
import Countdown from "react-countdown";

class LiveStream extends Component {
  state = {
    liveStreamUrl: "",
    liveStreamStatus: "",
    Numbers: [],
    drawDateTime_gregorian: "",
    drawDateTime_jalali: "",
    drawTime_Raw: "",
    totalPrize: "",
    tournamentName: "",
    tournamentType: "",
    tickets: []
  };
  buttonArray = [
    { num: 1 },
    { num: 2 },
    { num: 3 },
    { num: 4 },
    { num: 5 },
    { num: 6 },
    { num: 7 },
  ];
  interval;
  componentDidMount() {
    this.getLiveStream();
  }
  getLiveStream = async (id) => {
    try {
      let { data } = await basic.getLiveStream();

      if (data.success) {
        // this.setState({
        //   liveStreamStatus: data.liveStreamStatus,
        //   liveStreamUrl: data.liveStreamUrl,
        // });




        if (data.gameType === "wingo") {
          this.setState({
            drawDateTime_gregorian: data.drawDateTime_gregorian,
            drawDateTime_jalali: data.drawDateTime_jalali,
            drawTime_Raw: data.drawTime_Raw * 1000,
            tournamentName: data.tournamentName,
            totalPrize: data.totalPrize,
            liveStreamStatus: data.liveStreamStatus,
            liveStreamUrl: data.liveStreamUrl,
            tickets: data.userTickets ? data.userTickets : []
          });
          if (data.liveStreamStatus == 1) {
            this.getCurrentNumbers();
          }

        } else {

          const { history } = this.props;
          if (data.IsStarted) {
            sessionStorage.setItem("liveStreamStatus", data.liveStreamStatus);
            sessionStorage.setItem(
              "live_url_video",
              data.liveStreamDabernaUrl
            );
            if (history)
              history.push('/bingoLiveStarted');
          } else {
            if (history)
              history.push('/bingoLive');
          }

        }

      }
    } catch (error) {
    }
  };

  getCurrentNumbers = async () => {
    try {
      this.interval = setInterval(async () => {
        const { data } = await basic.getcurrentDrawNumbers();
        this.setState({ Numbers: JSON.parse(data.currentDrawNumbers) });

        if (JSON.parse(data.currentDrawNumbers)[6] != 0) clearInterval(this.interval);

      }, 3000);
    } catch (error) {
      console.error(error);
    }
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    let { userLang } = localStorgeSubscriber("data");
    const {
      liveStreamStatus,
      liveStreamUrl,
      Numbers,
      drawDateTime_gregorian,
      drawDateTime_jalali,
      totalPrize,
      tournamentName,
      drawTime_Raw,
      tickets
    } = this.state;
    //  const history = useHistory();
    return (
      <>
        <Helmet>
          <title> {interpreter("LiveStream")}</title>
        </Helmet>
        <Header color="blue" />
        <div>

          <div className="row">
            <div className="medium-4 column">
              <div className="total_countering black">
                <div className="title">
                  <span>{interpreter("drawTime")}</span>
                </div>
                <div className="value">
                  {!userLang || userLang === "fa"
                    ? toJalaliHour(drawDateTime_gregorian)
                    : drawDateTime_jalali}
                </div>
              </div>
            </div>
            <div className="medium-4 column">
              <div className="total_countering black">
                <div className="title">
                  <span>{interpreter("totalPrize")}</span>
                </div>
                <div className="value">{`${numberWithCommas(
                  totalPrize
                )} ${interpreter("Toman")}`}</div>
              </div>
            </div>
            <div className="medium-4 column">
              <div className="total_countering black">
                <div className="title">
                  <span>{interpreter("tournamentName")}</span>
                </div>
                <div className="value">{tournamentName}</div>
              </div>
            </div>
          </div>



          <div className="row columns">
            <div className="min_height_50">
              <div className="callout_panel">
                {Number(liveStreamStatus) === 1 ? (
                  <>
                    <div className="inner_panel_title">
                      {interpreter("LiveStream")}
                    </div>
                    <div className="row">
                      <div className="large-9 float-center video_box">
                        <ReactPlayer
                          url={liveStreamUrl}
                          width="100%"
                          controls={true}
                        />
                      </div>
                    </div>
                    <div className={`phone_numbers_new click_false livestream `}>
                      {this.buttonArray.map((x, i) => (
                        <button
                          key={x.num}
                          className={` ${Numbers && Numbers.length && Numbers[i]
                            ? "clicked"
                            : ""
                            }`}
                        >
                          <span className="num">{Numbers[i] || ""}</span>
                        </button>
                      ))}
                    </div>
                    <p>
                      {Number(liveStreamStatus)
                        ? ReactHtmlParser(interpreter("liveStream_Description"))
                        : ReactHtmlParser(
                          interpreter("liveStream_is_not_available")
                        )}
                    </p>
                  </>
                ) : Number(liveStreamStatus) === -1 ? (
                  <div>
                    {ReactHtmlParser(
                      interpreter("liveStream_is_not_available")
                    )}
                  </div>
                ) : drawTime_Raw ? (
                  <div className="row">
                    <br />
                    <br />
                    <br />
                    <div className="large-5 medium-6 small-12 col-2 float-center">
                      <div className="countDown">
                        <div className="countdown-wrapper">
                          <Countdown
                            date={drawTime_Raw}
                            intervalDelay={0}
                            precision={3}
                            // renderer={this.renderer}
                            renderer={CountDownView}
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                    <br />

                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>


          <div className="row columns">
            <CardUser tickets={tickets} />
          </div>


        </div>
        <Footer blue />
      </>
    );
  }
}

export default withRouter(LiveStream);