import React from "react";

export default () => (
	<svg
		viewBox="0 0 501.551 501.551" >
		<polygon style={{ 'fill': '#FFD15C' }} points="288.392,371.984 198.531,461.845 78.367,485.878 0,501.551 15.673,423.184 39.706,303.02 
   129.567,213.159 "/>
		<polygon style={{ 'fill': '#FF7058' }} points="213.159,367.804 119.118,382.433 133.747,288.392 333.322,88.816 412.735,168.229 " />
		<g>
			<polygon style={{ 'fill': '#F1543F' }} points="452.441,207.935 198.531,461.845 213.159,367.804 412.735,168.229 	" />
			<polygon style={{ 'fill': '#F1543F' }} points="333.322,88.816 133.747,288.392 39.706,303.02 293.616,49.11 	" />
		</g>
		<polygon style={{ 'fill': '#334A5E' }} points="78.367,485.878 0,501.551 15.673,423.184 " />
		<path style={{ 'fill': '#40596B' }} d="M495.282,135.837L365.714,6.269c-8.359-8.359-20.898-8.359-29.257,0L293.616,49.11l158.824,158.824
   l42.841-42.841C503.641,156.735,503.641,144.196,495.282,135.837z"/>
		<rect x="369.694" y="8.561" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 735.3167 -62.8052)" style={{ 'fill': '#F2F2F2' }} width="21.943" height="224.651" />


	</svg>
)