import React, { Component } from "react";

// API
import { basic, tournamentsDaberna, liveBingo } from "../../Api";

// Components
import Slider from "./slider";
import Deberna from "../home/deberna";
import Header from "../../components/layout/header-main";
// import Game from "../tornoment-new/index-without-layout";
import { NavLink } from "react-router-dom";
import Footer from "../../components/layout/footer";
import moment from "moment-jalaali";
import ImgBtn1 from "../home/daberna-button.png";
import ImgBtn2 from "../home/daberna-button-2.png";
import SweetAlert from "../../components/sweetalert/v";
import { getDrawTimeStart, getLongDate } from "../../utills";

class DebernaGame extends Component {
  state = {
    Slider: "",
    webSite: "",
    LatestDaberna: "",
    showCountDown: false,
    bingoLive: true,
    isBingoLive1: true,
  };
  Latest;
  componentDidMount() {
    this.getLatestDeberna();
    const webSite = JSON.parse(localStorage.getItem("webSite"));
    if (webSite && webSite.activeDabernaAnnouncement) {
      this.getAnnouncements();
    }
  }

  requestDetails = async (isLoop) => {
    try {
      let { data } = await liveBingo.bingoDetails();

      if (!data.success) {
        this.setState({
          bingoLive: false,
        });
      } else {
        sessionStorage.setItem(
          "liveStreamStatus",
          data.tournament.liveStreamStatus
        );
        sessionStorage.setItem(
          "live_url_video",
          data.tournament.liveStreamDabernaUrl
        );
        sessionStorage.setItem(
          "piceCard",
          data.tournament.Price
        );

        if (data.tournament.IsStarted) {
          this.setState({
            isBingoLive1: false,
          });
        }
      }

    } catch (error) {
      console.error(error);
    }
  };

  getLatestDeberna = async () => {
    try {
      const { data } = await tournamentsDaberna.tournamentLatest();
      this.requestDetails();

      if (data.success) {
        let drawTimeStart = getDrawTimeStart(data.tournament.StartsAt_gregorian)

        this.setState({
          LatestDaberna: data.tournament,
          showCountDown: drawTimeStart > 0,
        });

        if (drawTimeStart < 500000) {
          drawTimeStart = drawTimeStart < 1000 ? 1500 : drawTimeStart
          this.Latest = setTimeout(() => {
            this.getLatestDeberna();
          }, drawTimeStart);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  getAnnouncements = async () => {
    const { data } = await basic.announcements();
    if (data.success) {
      this.SiteNotification(data.announcements);
    }
  };

  SiteNotification = (data) => {
    const isDaberna = data.find((x) => x.isDaberna);
    SweetAlert({
      allowOutsideClick: data ? true : false,
      red: true,
      ismainPage: false,
      title: isDaberna.announcementTitle_Fa,
      width: "60vw",
      html: <>{isDaberna.announcementDescription_Fa}</>,

    });
  };

  componentWillUnmount() {
    clearTimeout(this.Latest);
  }
  render() {
    const { LatestDaberna, showCountDown, bingoLive, isBingoLive1 } = this.state;

    return (
      <>
        <Header color="red" />
        <Slider />

        <div className="page_components" >
          <br />
          <div className="row columns display_flex align_items_center justify_content_center"
            style={{ marginBottom: 35 }}>
            <NavLink to={"/bingoList"}>
              <img src={ImgBtn1} alt="" />
            </NavLink>

            {!bingoLive ? (
              <div className="tornomentZende">
                <img src={ImgBtn2} alt="" />
              </div>
            ) : (
              <NavLink to={isBingoLive1 ? "/bingoLive" : "/bingoLiveStarted"}>
                <img src={ImgBtn2} alt="" />
              </NavLink>
            )}
          </div>
          <Deberna
            LatestDaberna={LatestDaberna}
            {...this.props}
            showCountDown={showCountDown}
          />

          {/*  */}

        </div>

        <Footer red />
      </>
    );
  }
}
export default DebernaGame;
