import React from 'react'
import PropTypes from "prop-types";

const BingoItem=({ticketNumbers,winnedRows})=>{

    return (
        <div className="" style={{ direction: "ltr" }}>
        <div className="medium-8 float-center">
          <div className="bingo_user_card_sweetAlert">
            <div className="deberna_card_table">
              {ticketNumbers && ticketNumbers.length
                ? ticketNumbers.map((ArrayNumber, i) => (
                    <div className="display_flex" key={i}>
                      {ArrayNumber.map((number) => (
                        <span
                          className={
                            winnedRows &&
                            winnedRows.length &&
                            winnedRows.indexOf(i) > -1
                              ? "color_red"
                              : ""
                          }
                        >
                          {number ? number : ""}
                        </span>
                      ))}
                    </div>
                  ))
                : ""}
            </div>
          </div>
        </div>
      </div>
    
    )
}
BingoItem.propTypes = {
    ticketNumbers: PropTypes.array.isRequired,  
    winnedRows: PropTypes.array.isRequired, 
  };
export default BingoItem