 
import React from 'react';
import AppStart from "./App/App"; 
 
const  App=()=> { 

  return (
     <AppStart/>
  );
}

export default App;
