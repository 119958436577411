import React, { Component } from "react";

// API

// Components

import Layout from "../../components/layout/main";
import Menu from "./menu.js";
import ProfileCard from "./profile_card.js";
import Title from "./title";
import { user } from "../../Api";
import {
  interpreter,
  localStorgeSubscriber,
  numberWithCommas,
  toJalaliHour,
  toMiladiHour,
} from "../../utills";
import { Pagination } from "rsuite";

class Dashboard extends Component {
  state = { List: [], pages: [], page: 1 };

  componentDidMount() {
    this.getWinnedList();
  }

  getWinnedList = async (page) => {
    const { data } = await user.winnedDebernaTournaments(page);
    if (data.success) {
      let pages = [];
      for (let i = 1; i <= data.totalPages; i++) {
        pages.push(i);
      }
      this.setState({ List: data.wins || [], pages });
    }
  };
  handleChangePage = (page) => {
    this.setState({ page: page });
    this.getWinnedList(page);
  };

  render() {
    const { List, pages, page } = this.state;
    let { userLang } = localStorgeSubscriber("data");
    return (
      <div>
        {/* breadcrumb */}
        <div className="row">
          <div className="large-3 medium-4 columns">
            <div className="dashboard_sidebar">
              <ProfileCard />
              <Menu />
            </div>
          </div>

          {/* start content */}
          <div className="large-9 medium-8 columns">
            <div className="dashboard_panel">
              <Title title="تاریخچه راندهای بینگو" icon="note2" />

              <table className="ripo_table2">
                <thead>
                  <tr>
                    <td>{interpreter("row")}</td>
                    <td>{interpreter("roundID")}</td>
                    <td>{interpreter("date")}</td>
                    <td>{interpreter("winPrize")}</td>
                  </tr>
                </thead>
                <tbody>
                  {List.map((x, i) => (
                    <tr key={Math.random()}>
                      <td data-title={interpreter("row")}>{x.rowId}</td>
                      <td data-title={interpreter("roundID")}>
                        {x.tournamentId}
                      </td>
                      <td data-title={interpreter("date")}>
                        {!userLang || userLang === "fa"
                          ? toJalaliHour(x.Started_Raw * 1000)
                          : toMiladiHour(x.Started_Raw * 1000)}
                      </td>
                      <td data-title={interpreter("winPrize")}>
                        {`${numberWithCommas(x.amount)} ${interpreter("Toman")}`}ّ
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <br />
              <div className="PaginationTransactions">
                <Pagination
                  pages={pages.length}
                  maxButtons={5}
                  activePage={page}
                  onSelect={this.handleChangePage}
                  prev={true}
                  next={true}
                  first={true}
                  last={true}
                  ellipsis={true}
                  boundaryLinks={true}
                />
              </div>
            </div>
          </div>
          {/* // end content */}
        </div>
      </div>
    );
  }
}
export default Layout(Dashboard);
