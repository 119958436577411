import React, { Component } from "react";

// API
import { user, basic } from "../../../Api";

// Components
import { NavLink } from "react-router-dom";
import {
  interpreter,
  pictureUrlMaker,
  userDataGetter,
  numberWithCommas,
  localStorgeSubscriber,
} from "../../../utills";

// assets
import "./style.css";
import ImgWingo from "./wingo.png";
import ImgBingo from "./bingo.png";
import MenuIcon from "../../../assets/svg/menu";
import ProfileIcon from "../../../assets/svg/profile";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
class HomeAll extends Component {
  constructor() {
    super();
    this.state = {
      redirect: false,
      Me: "",
      siteIsOff: false,
      sidebarMenu: false,
      notification: false,
      openDropdownNotifi: false,
      messages: [],
    };
  }

  componentDidMount = async () => {
    await this.getMe();
    this.checkWebSite();
  };

  signOut = async () => {
    const { data } = await user.logOut();
    if (data) {
      await localStorage.removeItem("data");
      await localStorage.removeItem("userAuth");
      this.setState({ Me: "" });
      this.setState({ redirect: true });
    }
  };
  getMe = async () => {
    const { data } = await user.Current();
    if (data.loginStatus) {
      await this.setState({ Me: data });
      localStorage.setItem("data", JSON.stringify(data));
    } else {
      await localStorage.removeItem("data");
      await localStorage.removeItem("userAuth");
      this.setState({ Me: "" });
    }
  };

  checkWebSite = async () => {
    try {
      const { data } = await basic.basic();
      if (data.websiteStatus === 0) {
        this.setState({ siteIsOff: true });
      } else {
        this.timeOut = setTimeout(() => {
          this.checkWebSite();
        }, 300000);
      }
    } catch (error) {
      this.setState({ siteIsOff: true });
      console.error(error);
    }
  };
  componentWillUnmount = () => {
    clearTimeout(this.timeOut);
  };

  openMenuSide = () => {
    this.setState({
      sidebarMenu: !this.state.sidebarMenu,
    });
  };

  render() {
    let webSite = localStorgeSubscriber("webSite");
    let userName = userDataGetter("userName", "data");
    const { Me } = this.state;
    return (
      <>

        <div
          className={`header_all ${this.state.show ? "active" : "de-active"} ${this.props.color}`}
        >
          <div className="row columns" style={{ maxWidth: "85rem" }}>
            <div className="display_flex align_items_center justify_content_between">
              <div className="menuIcon_wrapper show-for-small-only">
                <button
                  className="mobile_sidebar_menuicon"
                  onClick={() => this.openMenuSide()}
                >
                  <MenuIcon />
                </button>
              </div>

              <ul className="all_menu hide-for-small-only">
                {Me ? (
                  <>
                    <li className="user-logged-in">
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <NavLink
                          to="/dashboard-home"
                          activeClassName="actived"
                          exact
                        >
                          <PermIdentityIcon />   {interpreter("hello")}&nbsp;{userName || Me.userName}

                        </NavLink>
                        <NavLink
                          to="/dashboard-add-blance"
                          activeClassName="actived"
                          exact
                          style={{ padding: 0 }}
                        >
                          <div className="small buyBackbround">
                            <AddCircleOutlineIcon />
                            <span className="lineHor" style={{ textShadow: "unset" }}></span>
                            {interpreter("inventory")}&nbsp; : &nbsp;
                            {numberWithCommas(Me.userBalance)}&nbsp;
                            <span style={{ textShadow: "unset !important" }}>{interpreter("Toman")}</span>

                          </div>

                        </NavLink>

                      </div>



                    </li>

                  </>
                ) : (
                  <>
                    {/* اگر کاربر لاگین نبود */}
                    <li>
                      <NavLink to="/login" activeClassName="actived" exact>
                        {interpreter("loginButton")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/register" activeClassName="actived" exact>
                        {interpreter("registerButton")}
                      </NavLink>
                    </li>
                  </>
                )}
                <li>
                  <NavLink to="/WebsiteRules">
                    {interpreter("gameRules")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/archive"   >{interpreter("Archive")}</NavLink>
                </li>
                {/* اگه منوی وینگو بود لینک آرشیو اضافه شود */}
                {/* {this.props.color === "blue" ? (
                  <li>
                    <NavLink to="/archive">{interpreter("Archive")}</NavLink>
                  </li>
                ) : null} */}
                {this.props.color === "blue" && <li>
                  <NavLink to="/LiveStream">
                    {interpreter("LiveStream")}
                  </NavLink>
                </li>}
              </ul>
              {/* // end menu */}
              <div className="display_flex align_items_center wingo_wingo_links">
                <NavLink to="/wingo">
                  <img src={ImgWingo} alt="wingo" />
                </NavLink>
                &nbsp;
                <NavLink to="/bingo">
                  <img src={ImgBingo} alt="bingo" />
                </NavLink>
              </div>
              {/* // end game links */}
              <a href="/" className="site-logo">
                <img
                  src={pictureUrlMaker(webSite && webSite.logoImageUrl)}
                  alt=""
                />
              </a>
              {/* // end logo */}
            </div>
          </div>
        </div>

        {/* // end header */}

        {/* sidebar */}
        <div className={`sidebar ${this.state.sidebarMenu ? "opened" : ""}`}>
          <button className="close-button" onClick={() => this.openMenuSide()}>
            <span className="pe-close"></span>
          </button>
          <ul className="menu vertical sidebar_menu">
            {Me ? (
              <>
                {/* اگه کاربر لاگین کرده بود */}
                <li className="user-logged-in"  >

                  <NavLink to="/dashboard-home" activeClassName="actived" exact>
                    <ProfileIcon />
                    <div>
                      {interpreter("hello")}&nbsp;{userName || Me.userName}
                      <div className="small">
                        {numberWithCommas(Me.userBalance)}&nbsp;
                        <span>{interpreter("Toman")}</span>

                      </div>
                    </div>
                  </NavLink>

                </li>
                <li>
                  <NavLink
                    to="/dashboard-add-blance"
                    activeClassName="actived"
                    exact
                  >
                    {interpreter("deposit")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/dashboard-Withdrawal"
                    activeClassName="actived"
                    exact
                  >
                    {interpreter("withdrawa")}
                  </NavLink>
                </li>
              </>
            ) : (
              <>
                {/* اگر کاربر لاگین نبود */}
                <li>
                  <NavLink to="/login" activeClassName="actived" exact>
                    {interpreter("loginButton")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/register" activeClassName="actived" exact>
                    {interpreter("registerButton")}
                  </NavLink>
                </li>
              </>
            )}
            {Me ? (
              <>
                <li>
                  <NavLink
                    to="/dashboard-transactions"
                    activeClassName="actived"
                    exact
                  >
                    {interpreter("transactionHistory")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/dashboard-archive-bingo"
                    activeClassName="actived"
                    exact
                  >
                    راند ها
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/archive" activeClassName="actived" exact>
                    تورنومنت ها
                  </NavLink>
                </li>
              </>
            ) : (
              ""
            )}


            {(window.location.href.includes("wingo") || window.location.href.includes("bingo")) &&
              <li><NavLink to={window.location.href.includes("wingo") ? "/About-wingo" : "/About-bingo"} activeClassName="actived" exact>
                {interpreter("About")}
              </NavLink>
              </li>}

            <li>
              <NavLink to="/WebsiteRules" activeClassName="actived" exact>
                {interpreter("gameRules")}
              </NavLink>
            </li>
            {this.props.color === "blue" && <li>
              <NavLink to="/LiveStream" activeClassName="actived" exact>
                {interpreter("LiveStream")}
              </NavLink>
            </li>}


            <li>
              <NavLink to="/archive" activeClassName="actived" exact>
                {interpreter("Archive")}
              </NavLink>
            </li>
            {Me ? (
              <li>
                <button onClick={this.signOut}>{interpreter("exit")}</button>
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
      </>
    );
  }
}
export default HomeAll;
