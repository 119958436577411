import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Dialog, IconButton } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
  
import AddIcon from "@material-ui/icons/Add";
import { isMobile } from "react-device-detect";
import OwlCarousel from "react-owl-carousel2";
import "../../bingoLive2/index.css";
import "../../deberna-game/style.css";
import {
  interpreter, 
} from "../../../utills";
 
let options = {
  center: false,
  items: 3,
  dots: false,
  nav: true,
  rewind: false,
  rtl: true,
  loop: false,
  margin: 5,
  startPosition: 5,
  autoplay: false,
  smartSpeed: 600,
  responsiveClass: true,
  responsive: {
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    640: {
      items: 2,
    },
    1025: {
      items: 2,
    },
  },
};

 

const DialogRejectPay = (props) => { 
  const [listTicket,setListTicket]=React.useState([]) 
  const { 
    tickets,
  } = props;

  const ticketSelector = (id) => {};
  
  useEffect(()=>{
 
    if(tickets){  
       sortList()
    }
  },[tickets])

  const sortList= async()=>{
    let this3 = [];
    const newArray = [];
    
    await tickets.forEach((ticket, i) => {
      if ((i + 1) % 3 !== 0) {
        this3.push(ticket);
        if (i + 1 === tickets.length) {
          newArray.push(this3);
          this3 = [];
        }
      }
      if ((i + 1) % 3 === 0) {
        this3.push(ticket);
        newArray.push(this3);
        this3 = [];
      }
    });  
  setListTicket(newArray)
  }
  return (
    
    <Grid
    xs={12}
    container
    direction="row"
    justify="center"
    alignContent="center" 
  >
 
 
  

    <div className="row columns callout_panel card_user_liveStream" style={{ direction: "rtl" }}>
     <div className="title_card" style={{color:"#2d307f"}}>{interpreter('your_ticket')}</div>
      <div
        className="  deberna_cards_panel deberna_groupbox_slider pos-r p-t80 "
        style={{ background: "none" }}
      >
        {  (

listTicket.length > 0 && (
  <>


          <OwlCarousel options={options}>
            {listTicket.map((rowTicket) => (
              <div className="groupBox_user_cards " key={Math.random()}>
                {rowTicket && rowTicket.length
                  ? rowTicket.map((ticket) => {
                      return (
                        <div
          className={ 'item itemLive2'}
          style={{ direction: "ltr"  }} 
        >
          
          <Grid container direction="row" justify="center"  >
            { ticket &&  ticket.length
              ? ticket.map((row,i) => (
                  <div className={`phone_numbers_new click_false  ${i<6? "redball" :"livestream"} `}>

                   <button 
                        >
                          <span  >{ row}</span>
                        </button>
                   
                 
                  </div>
                ))
              : ""}
          </Grid>
        </div>
                      );
                    })
                  : ""}
              </div>
            ))}
          </OwlCarousel>
        </>
        )
        )  }
      </div>
    </div>
    
  </Grid>

  );
};
DialogRejectPay.propTypes = {
  isResult: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  withdrawal_Id: PropTypes.number.isRequired,
  is_User_Banned: PropTypes.bool.isRequired,
};

export default DialogRejectPay;
//export default connect(null, { updateUserDetails })(DialogRejectPay);
  {/* <button
                        
                        >
                          <span className="num">{ ticket || ""}</span>
                        </button>
                      */}