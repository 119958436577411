import { APIClientDaberna } from "./_api";
const base = "/tournament";

export function tournamentLatest() {
  return APIClientDaberna.get(`${base}/latest/`);
}

export function tournamentDetails(values) {
  let bodyFormData = new FormData();

  const userAuth = localStorage.getItem("userAuth");
  for (let key in values) {
    bodyFormData.append(key, values[key]);
  }
  bodyFormData.append("userAuth", userAuth || "");

  return APIClientDaberna.post(`${base}/details/`, bodyFormData);
}

export function tournamentList(values) {
  let bodyFormData = new FormData();
  const userAuth = localStorage.getItem("userAuth");

  for (let key in values) {
    bodyFormData.append(key, values[key]);
  }
  bodyFormData.append("userAuth", userAuth || "");
  return APIClientDaberna.post(`${base}/list/`, bodyFormData);
}

export function tournamentDrawnumbers(values) {
  let bodyFormData = new FormData();
  const userAuth = localStorage.getItem("userAuth");

  for (let key in values) {
    bodyFormData.append(key, values[key]);
  }
  bodyFormData.append("userAuth", userAuth || "");
  return APIClientDaberna.post(`${base}/drawnumbers/`, bodyFormData);
}
