import React, { Component } from "react";

import { interpreter, numberWithCommas, toJalaliHour } from "../../../../utills";
import ImgTornoment from "../../../../components/layout/header-main/bingo.png";
import { Link } from "react-router-dom";
import "./index.css";

class TornomentItemSingle extends Component {
  render() {
    const { tournament } = this.props;
    return (
      <>
        <Link
          to={`/archiveBingo-${tournament.tournamentId}`}
          onClick={()=>{
            localStorage.setItem("tournamentId",tournament.tournamentId)
          }}
          className={`item  `}
        >
          <div className="display_flex align_items_center justify_content_between mb" >
            <div className="content_tornoment" >
              <div className="name">{tournament.tournamentName}</div>
              <div className="total_prize" style={{color:"#d6454c"}}>{interpreter("sumOfPrizes")}</div>
              <div className="prize" style={{background:"#d6454c"}}>
                {`${numberWithCommas(
                  tournament.tournamentTotalPrize
                )} ${interpreter("Toman")}`}
              </div>
            </div>
            <div className="image">
              <img src={ImgTornoment} alt="" />
            </div>
          </div>
          <div className="info">
            <div className="lottory_time">
              <span>{interpreter("drawTime")}:</span>
              <span>{toJalaliHour(tournament.drawDateTime_gregorian)}</span>
            </div>
          </div>
          {/* اگه قبلا شرکت کرده بود: */}
          {this.props.userHasParticipatedInTournament === true ? (
            <div className="help-text">
              <span className="pe-check"></span>&nbsp;
              {interpreter("userHasParticipatedInTournament")}
            </div>
          ) : (
            ""
          )}

          <div className="text">{interpreter("seeResult")}</div>
        </Link>
      </>
    );
  }
}
export default TornomentItemSingle;
