import React, { Component } from "react";
class LuckyNumber extends Component {
  state = {
    Numbers: [],
  };

  componentDidMount() {
    const Numbers = [];
    for (let i = 1; i <= 90; i++) {
      Numbers.push(i);
    }
    this.setState({ Numbers });
  }

  render() {
    const { DrawNumbers } = this.props;

    return (
      <>
        <div className="deberna_luckyNumbers">
          {this.state.Numbers.map((item) => {
            const selectedNumber = DrawNumbers.findIndex(
              (x) => Number(x) === Number(item)
            );
            return (
              <> 
                <div
                  className={`item ${selectedNumber > -1 ? "selected" : ""}` }
                  key={item}
                 
                >
                  {item}
                </div>
              </>
            );
          })}
        </div>
      </>
    );
  }
}
export default LuckyNumber;
