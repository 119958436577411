import React, { Component } from "react";

// API
import { basic, user, tournaments } from "../../Api";

// Components
import Slider from "./slider";
import WeekVideo from "./week-video";
import WeekWinner from "./week-wineer";
import { NavLink } from "react-router-dom";
import Layout from "../../components/layout";
import WeeklyTornoment from "./tornoment-Weekly";
import SpecialTornoment from "./tornoment-special";
import MonthlyTornoment from "./tornoment-monthly";
import SweetAlert from "../../components/sweetalert/v";
import Game from "../tornoment-new/index-without-layout";

import { interpreter } from "../../utills";

// Style
import "./index.css";
import { scroller } from "react-scroll";
import { Container, Grid } from "@material-ui/core";

class Wingoo extends Component {
  state = {
    Me: "",
    webSite: "",
    tournaments: "",
    extraIndexContent: "",
    notife: false,
  };
  componentDidUpdate(prev) {
    const { hash } = this.props.location;
    if (hash && prev.location.hash !== hash) {
      const [, hashData] = hash.split("#");
      scroller.scrollTo(hashData, { spy: true, smooth: true, duration: 500 });
    }
  }
  async componentDidMount() {
    const { hash } = this.props.location;
    if (hash) {
      const [, hashData] = hash.split("#");
      scroller.scrollTo(hashData, { spy: true, smooth: true, duration: 500 });
    }
    await this.getMe();
    this.getTournamentsList();
    await this.getBasic();
    if (this.state.webSite && this.state.webSite.activeLottoAnnouncement) {
      this.getAnnouncements();
    }
    this.getExtraIndexContent();
  }

  getMe = async () => {
    const { data } = await user.Current();
    if (data.loginStatus) {
      await this.setState({ Me: data });
      localStorage.setItem("data", JSON.stringify(data));
    } else {
      await localStorage.removeItem("data");
      await localStorage.removeItem("userAuth");
      this.setState({ Me: "" });
    }
  };

  getBasic = async () => {
    const { data } = await basic.basic();
    this.setState({ webSite: data });
  };

  getTournamentsList = async () => {
    const { data } = await tournaments.tournamentsList();
    this.setState({ tournaments: data });
  };

  getExtraIndexContent = async () => {
    const { data } = await basic.GetExtraIndexContent();
    this.setState({
      extraIndexContent: data,
    });
  };

  getAnnouncements = async () => {
    const { data } = await basic.announcements();
    if (data.success) {
      this.SiteNotification(data.announcements);
    }
  };
  // نوتیفیکیشن سایت
  SiteNotification = (data) => {
    const isLotto = data.find((x) => x.isLotto);
    SweetAlert({
      allowOutsideClick: data ? true : false,
      title: isLotto.announcementTitle_Fa,
      width: "60vw",
      html: <>{isLotto.announcementDescription_Fa}</>,
    });
  };

  render() {
    const { webSite, tournaments } = this.state;
    const gameId = webSite.LottoPageTournamentId
      ? { params: { id: webSite.LottoPageTournamentId } }
      : "";
    const { Me } = this.state;

    return (
      <>
        <Slider webSite={webSite} />

        {/*  */}
        <div className="row show-for-small-only index_small_menu mb">
          {Me ? (
            <>
              <div className="small-6 columns">
                <NavLink
                  to="/dashboard-add-blance"
                  className="blue_button expanded small mb"
                >
                  {interpreter("deposit")}
                </NavLink>
              </div>
              <div className="small-6 columns">
                <NavLink
                  to="/dashboard/Withdrawal"
                  className="blue_button expanded small mb"
                >
                  {interpreter("withdrawa")}
                </NavLink>
              </div>
            </>
          ) : (
            <>
              <div className="small-6 columns">
                <NavLink to="/login" className="blue_button expanded small mb">
                  {interpreter("loginButton")}
                </NavLink>
              </div>
              <div className="small-6 columns">
                <NavLink
                  to="/register"
                  className="blue_button expanded small mb"
                >
                  {interpreter("registerButton")}
                </NavLink>
              </div>
            </>
          )}
        </div>

        {/* // end */}

        <Game match={gameId} />

        <Container maxWidth="lg"  >
          <Grid container spacing={2} style={{ paddingLeft: 20, paddingRight: 20 }}>

            {tournaments.specialTournaments &&
              tournaments.specialTournaments.length ? (
              <Grid item xs={12} md={4}>
                <SpecialTornoment
                  webSite={webSite}
                  tournaments={tournaments.specialTournaments}
                />
              </Grid>
            ) : (
              ""
            )}
            {tournaments.weeklyTournaments &&
              tournaments.weeklyTournaments.length ? (
              <Grid item xs={12} md={4}>
                <WeeklyTornoment
                  webSite={webSite}
                  tournaments={tournaments.weeklyTournaments}
                />
              </Grid>
            ) : (
              <></>
            )}

            {tournaments.monthlyTournaments &&
              tournaments.monthlyTournaments.length ? (
              <Grid item xs={12} md={4}>
                <MonthlyTornoment
                  webSite={webSite}
                  tournaments={tournaments.monthlyTournaments}
                />
              </Grid>

            ) : (
              ""
            )}
          </Grid>
        </Container>
        {/* <div className="row tornoments_listClass" id="tornoments_list" >
         

          
        </div> */}

        <div className="row columns">
          <div className="callout_shadow">
            <div className="row">
              <div className="large-6 medium-6 columns">
                <div className="text-center wingo_pagehome_boxText">{interpreter('weekly_winner')} </div>
                <WeekWinner
                  img={
                    this.state.extraIndexContent
                      ? this.state.extraIndexContent.weeklyWinnerImageUrl
                      : 0
                  }
                />
              </div>
              <div className="large-6 medium-6 columns">
                <div className="text-center wingo_pagehome_boxText">{interpreter('video_last_week')}</div>
                <WeekVideo
                  id={
                    this.state.extraIndexContent
                      ? this.state.extraIndexContent.lastActiveTournamentArchiveId
                      : 0
                  }
                />
              </div>
            </div>

          </div>

        </div>
      </>
    );
  }
}
export default Layout(Wingoo);
