 

export const getBingoLive= store => {
    
    
    return store.BingoLiveReducer;
 
}


export const getBingoOffline= store => {
    
    
    return store.BingoOfflineReducer;
 
}