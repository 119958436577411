import React, { Component } from "react";

import { interpreter, numberWithCommas, toJalaliHour } from "../../utills";

import { Link } from "react-router-dom";
import "./index.css";

class TornomentItemSingle extends Component {
  render() {
    const { tournament } = this.props;
    return (
      <>
        <Link
          to={`/archive-${tournament.tournamentId}`}
          className={`item  ${this.props.type}`}
          onClick={()=>{
            localStorage.setItem("idArchive",tournament.tournamentId)
          }}
        >
          <div className="display_flex align_items_center justify_content_between mb">
            <div className="content_tornoment">
              <div className="name">{tournament.tournamentName}</div>
              <div className="total_prize">{interpreter("sumOfPrizes")}</div>
              <div className="prize">
                {`${numberWithCommas(
                  tournament.tournamentTotalPrize
                )} ${interpreter("Toman")}`}
              </div>
            </div>
            <div className="image">
              <img src={this.props.image} alt="" />
            </div>
          </div>
          <div className="info">
            <div className="lottory_time">
              <span>{interpreter("drawTime")}:</span>
              <span>{toJalaliHour(tournament.drawDateTime_gregorian)}</span>
            </div>
          </div>
          {/* اگه قبلا شرکت کرده بود: */}
          {this.props.userHasParticipatedInTournament === true ? (
            <div className="help-text">
              <span className="pe-check"></span>&nbsp;
              {interpreter("userHasParticipatedInTournament")}
            </div>
          ) : (
            ""
          )}

          <div className="text">{interpreter("seeResult")}</div>
        </Link>
      </>
    );
  }
}
export default TornomentItemSingle;
