import React, { Component } from "react";

// API
import { tournamentsDaberna, ticketDaberna } from "../../Api";

// Components
import Header from "../../components/layout/header-main";
import Footer from "../../components/layout/footer";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import OwlCarousel from "react-owl-carousel2";
import CardItem from "./card";
import LuckyNumbers from "./lucky-numbers";
import SweetAlert from "../../components/sweetalert/v";
import AllCard from './allCard'
import BingoOffline from './bingo_offline_anim'

import { withRouter } from 'react-router-dom';

// assets

import "./style.css";
import Card1 from "./card-1.png";
import Card2 from "./card-2.png";
import Card3 from "./card-3.png";

import Imgbuyticket from "./buy-ticket.png";
import "../../assets/css/flickity.css";
import {
  getDrawTimeStart,
  getLongDate,
  interpreter,
  localStorgeSubscriber,
  numberWithCommas,
  toJalaliHour,
} from "../../utills";
import { Redirect } from "react-router-dom";

class DebernaGame extends Component {
  state = {
    tournament: "",
    Tickets: [],
    selectedTicket: [],
    totalPrice: 0,
    DrawNumbers: [],
    LastNumber: 20,
    boughtTickets: [],
    boughtTicketsWithoutSplit: [],
    SliderName: "buy",
    showLoop: false,
    winners: {
      oneLine: { tickets: [] },
      twoLine: { tickets: [] },
      daberna: { tickets: [] },
    },
    showAlert: false,
    title: "",
    redirct: false,
  };

  options = {
    center: false,
    items: 3,
    dots: false,
    nav: true,
    rewind: false,
    rtl: true,
    loop: false,
    margin: 15,
    startPosition: 5,
    autoplay: false,
    smartSpeed: 600,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      640: {
        items: 3,
      },
      1025: {
        items: 3,
      },
    },
  };

  DrawTimeStart;

  NextDrawTime;

  async componentDidMount() {
    const { id } = this.props.match.params;

    this.getBoughtTicket(id);
    await this.getDetails(id);

    // localStorage.setItem("idGameOffline", id);
    const localTicket = JSON.parse(localStorage.getItem("Tickets"));
    const selectedTicket = JSON.parse(localStorage.getItem("selectedTicket"));
    if (!localTicket) {
      // if (this.state.tournament && !this.state.tournament.IsStarted) {
      //   this.getTicket(id);
      // }
    } else {
      let this3 = [];
      const newArray = [];

      await localTicket.forEach((ticket, i) => {
        if ((i + 1) % 3 !== 0) {
          this3.push(ticket);
          if (i + 1 === localTicket.length) {
            newArray.push(this3);
            this3 = [];
          }
        }
        if ((i + 1) % 3 === 0) {
          this3.push(ticket);
          newArray.push(this3);
          this3 = [];
        }
      });
      const totalPrice = this.calculator(selectedTicket);
      this.setState({
        Tickets: newArray,
        selectedTicket: selectedTicket,
        totalPrice,
      });
      localStorage.removeItem("selectedTicket");
      localStorage.removeItem("Tickets");
    }

    if (this.state.tournament.IsStarted) {
      this.drawNumbers();
    }
  }

  //گرفتن اطلاعات تورنومنت
  getDetails = async (id) => {
    try {
      const { data } = await tournamentsDaberna.tournamentDetails({
        tournamentId: id,
      });
      if (data.success) {
        this.setState({
          tournament: data.tournament,
          SliderName: data.tournament.IsStarted ? "bought" : "buy",
        });
        if (!data.tournament.IsStarted) {
          let drawTimeStart = getDrawTimeStart(data.tournament.StartsAt_gregorian)
          //  getLongDate() - Date.now();

          if (drawTimeStart < 500000) {
            drawTimeStart = drawTimeStart < 5000 ? 3000 : drawTimeStart + 1500
            this.DrawTimeStart = setTimeout(() => {
              this.getDetails(id);
              this.drawNumbers();
            }, drawTimeStart);
          }



        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  //! انجام بازی

  drawNumbers = async () => {
    try {
      const { id } = this.props.match.params;
      const { data } = await tournamentsDaberna.tournamentDrawnumbers({
        tournamentId: id,
      });

      if (data.success) {
        if (
          data.winners.daberna.tickets.length &&
          !this.state.winners.daberna.tickets.length
        ) {
          let findedTicket = "";
          data.winners.daberna.tickets.forEach((x) => {
            const findTicket =
              this.state.boughtTicketsWithoutSplit &&
                this.state.boughtTicketsWithoutSplit.length
                ? this.state.boughtTicketsWithoutSplit.find((boughtTicket) => {
                  return String(x.id) === String(boughtTicket.id);
                })
                : "";
            if (findTicket) {
              findedTicket = true;
            }
          });

          this.setState({ showAlert: true, title: "dabernaListWinners" });

          sessionStorage.setItem("lastDaberna", true)
          this.showDetail(
            data.winners.daberna,
            interpreter("Daberna"),
            findedTicket,
            data.DrawNumbers
          );
        } else if (
          data.winners.twoLine.tickets.length &&
          !this.state.winners.twoLine.tickets.length &&
          !this.state.winners.daberna.tickets.length
        ) {
          let findedTicket = "";
          data.winners.twoLine.tickets.forEach((x) => {
            const findTicket =
              this.state.boughtTicketsWithoutSplit &&
                this.state.boughtTicketsWithoutSplit.length
                ? this.state.boughtTicketsWithoutSplit.find((boughtTicket) => {
                  return String(x.id) === String(boughtTicket.id);
                })
                : "";
            if (findTicket) {
              findedTicket = true;
            }
          });
          this.setState({ showAlert: true, title: "twoListWinners" });
          this.showDetail(
            data.winners.twoLine,
            "دو خط",
            findedTicket,
            data.DrawNumbers
          );



        } else if (
          data.winners.oneLine.tickets.length &&
          !this.state.winners.oneLine.tickets.length &&
          !this.state.winners.twoLine.tickets.length &&
          !this.state.winners.daberna.tickets.length
        ) {
          let findedTicket = "";
          data.winners.oneLine.tickets.forEach((x) => {
            const findTicket =
              this.state.boughtTicketsWithoutSplit &&
                this.state.boughtTicketsWithoutSplit.length
                ? this.state.boughtTicketsWithoutSplit.find((boughtTicket) => {
                  return String(x.id) === String(boughtTicket.id);
                })
                : "";
            if (findTicket) {
              findedTicket = true;
            }
          });
          //   sessionStorage.setItem("dabernaWin", true)
          this.setState({ showAlert: true, title: "oneListWinners" });
          this.showDetail(
            data.winners.oneLine,
            "یک خط",
            findedTicket,
            data.DrawNumbers
          );

        }



        this.setState({
          winners: data.winners,
          LastNumber: data.DrawNumbers[data.DrawNumbers.length - 1],
          showLoop: data.DrawNumbers <= this.state.DrawNumbers,
        });
        if (this.state.DrawNumbers.length == 0 && data.DrawNumbers.length > 2) {
          let newA = [...data.DrawNumbers]
          newA.pop()

          this.setState({
            DrawNumbers: newA,
          });
        }

        if (data.Next > 0) {
          // setTimeout(() => {
          //   this.setState({ showLoop: true });
          // }, (data.Next / 2) * 1000);
          //  this.NextDrawTime = setTimeout(async () => {
          //   await this.drawNumbers();
          // }, data.Next * 1000);
          //  setTimeout(() => {
          //   this.setState({ showLoop: true });
          // }, 10000);
          this.NextDrawTime = setTimeout(async () => {
            this.setState({
              DrawNumbers: data.DrawNumbers,
            });
            await this.drawNumbers();
          }, 10000);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  showDetail = (data, winnerLine, findedTicket, drawNumbers) => {
    SweetAlert({
      allowOutsideClick: true,
      title: interpreter("winners"),
      width: "60vw",
      red: true,
      closeDialog: () => {
        if (JSON.parse(sessionStorage.getItem("lastDaberna"))) {
          sessionStorage.setItem("lastDaberna", false)
          const { history } = this.props;

          if (history)
            history.push('/bingoList');
        }

      },
      html: (
        <>
          {data && data.tickets && data.tickets.length
            ? data.tickets.map((ticket) => (
              <div className="" style={{ direction: "ltr" }}>
                <div className="medium-8 float-center">
                  <div className="bingo_user_card_sweetAlert">
                    <div className="deberna_card_table">
                      {ticket.ticket.map((ArrayNumber, i) => (
                        <div key={i} className="display_flex">
                          {ArrayNumber.map((number) => {
                            return (
                              <span
                                style={{
                                  color:
                                    ticket &&
                                      ticket.winnedRows &&
                                      ticket.winnedRows.length &&
                                      ticket.winnedRows.indexOf(i) > -1
                                      ? "red"
                                      : "",
                                }}
                              >
                                {number ? number : ""}
                              </span>
                            );
                          })}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))
            : ""}

          {findedTicket ? (
            <>
              <div className="medium-8 float-center daberna_reportPopup">
                <div className="text-center">
                  <div className="youWin large">{interpreter("YouWon")}</div>
                </div>
                <div className="display_flex align_items_center justify_content_between">
                  <div className="youWin">
                    {interpreter("wonCard")}&nbsp;{winnerLine}
                  </div>
                  <div className="youWin">
                    {interpreter("winPrize")}&nbsp;
                    {numberWithCommas(data.Amount)}
                  </div>
                </div>
                <div className="display_flex align_items_center justify_content_between">
                  <span className="youWin">{interpreter("cardID")}</span>
                  <span className="youWin">
                    {data.tickets.map((x, i) => {
                      return <span>{`${i > 0 ? "," : ""} ${x.id}`}</span>;
                    })}
                  </span>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="medium-8 float-center daberna_reportPopup">
                <div className="display_flex align_items_center justify_content_between">
                  <div className="youLost">
                    {interpreter("wonCard")}&nbsp;{winnerLine}
                  </div>
                  <div className="youLost">
                    {interpreter("winPrize")}&nbsp;
                    {numberWithCommas(data.Amount)}
                  </div>
                </div>
                <div className="display_flex align_items_center justify_content_between">
                  <span className="youLost">{interpreter("cardID")}</span>
                  <span className="youLost">
                    {data.tickets.map((x, i) => {
                      return <span>{`${i > 0 ? "," : ""} ${x.id}`}</span>;
                    })}
                  </span>
                </div>
              </div>
            </>
          )}
        </>
      ),
    });
  };

  //! گرفتن بلیط ها برای خرید
  getTicket = async (id) => {
    try {
      if (id) {
        const { data } = await ticketDaberna.TicketList({ tournamentId: id });
        if (data.success) {
          let this3 = [];
          const newArray = [];
          await data.tickets.forEach((ticket, i) => {
            if ((i + 1) % 3 !== 0) {
              this3.push(ticket);
              if (i + 1 === data.tickets.length) {
                newArray.push(this3);
                this3 = [];
              }
            }
            if ((i + 1) % 3 === 0) {
              this3.push(ticket);
              newArray.push(this3);
              this3 = [];
            }
          });


          localStorage.setItem("tickets", JSON.stringify(newArray))
          this.setState({
            Tickets: newArray,
            selectedTicket: [],
            totalPrice: 0,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  //! گرفتن بلیط های خریداری شده
  getBoughtTicket = async (id) => {
    try {
      if (id) {
        const { data } = await ticketDaberna.boughtTicket({ tournamentId: id });

        if (data.success) {
          let this3 = [];
          const newArray = [];
          await data.tickets.forEach((ticket, i) => {
            if ((i + 1) % 3 !== 0) {
              this3.push(ticket);
              if (i + 1 === data.tickets.length) {
                newArray.push(this3);
                this3 = [];
              }
            }
            if ((i + 1) % 3 === 0) {
              this3.push(ticket);
              newArray.push(this3);

              this3 = [];
            }
          });

          this.setState({
            boughtTickets: newArray,
            boughtTicketsWithoutSplit: data.tickets,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  //* انتخاب بلیط
  ticketSelector = (id) => {
    let [...selectedTicket] = this.state.selectedTicket;
    const exsit = selectedTicket.findIndex((x) => String(x) === String(id));

    if (exsit === -1 && selectedTicket.length < 15) {
      selectedTicket.push(id);
    }
    if (exsit > -1) {
      selectedTicket.splice(exsit, 1);
    }
    const totalPrice = this.calculator(selectedTicket);
    this.setState({ selectedTicket: selectedTicket, totalPrice });
  };

  //* محاسبه قیمت بلیط ها

  calculator = (selectedTicket) => {
    const { tournament } = this.state;
    return tournament.Price * selectedTicket.length;
  };

  //* خرید بلیط

  handleSubmit = async () => {
    try {
      const userAuth = JSON.parse(localStorage.getItem("data"));
      const { id } = this.props.match.params;
      const { selectedTicket } = this.state;
      if (!userAuth) {
        localStorage.setItem("returnPath", this.props.location.pathname);
        this.setState({ redirct: true });
      }
      const { data } = await ticketDaberna.buyTicket({
        tournamentId: id,
        selectionArray: JSON.stringify(selectedTicket),
      });

      if (data.success) {
        this.setState({ selectedTicket: [], totalPrice: 0 });
        this.getBoughtTicket(id);
        this.getTicket(id);
      }
    } catch ({ error }) {
      console.error(error);
    }
  };

  //! تغییر اسلایدر

  boughtAndBuySlider = (SliderName) => {
    if (SliderName !== "buy") {
      const { id } = this.props.match.params;
      this.getBoughtTicket(id);
    }

    this.setState({ SliderName: SliderName });
  };

  close = () => {
    this.setState({ showAlert: false });
  };

  componentWillUnmount() {
    clearTimeout(this.DrawTimeStart);
    clearTimeout(this.NextDrawTime);
  }
  render() {
    let { userLang } = localStorgeSubscriber("data");
    const { id } = this.props.match.params;
    const {
      tournament,
      Tickets,
      DrawNumbers,
      selectedTicket,
      totalPrice,
      LastNumber,
      boughtTickets,
      SliderName,
      showLoop,
      winners,
      boughtTicketsWithoutSplit,
      redirct,
      // title,
      // showAlert,
    } = this.state;

    return (
      <>
        <Header color="red" />
        {redirct ? <Redirect to="/login" /> : ""}
        {/* === page content */}
        <div className="row columns mb-30">
          <div className="display_flex align_items_center justify_content_between Deberna_title_large">
            <h1>{interpreter("Daberna")}</h1>
            <h1>{interpreter("offline")}</h1>
          </div>
        </div>



        <div className="row">
          <div className="medium-4 column">
            <div className="total_countering red">
              <div className="title">
                <span>{interpreter("drawTime")}</span>
              </div>
              <div className="value">
                {!userLang || userLang === "fa"
                  ? toJalaliHour(tournament.StartsAt_gregorian)
                  : tournament.StartsAt_gregorian}
              </div>
            </div>
          </div>
          <div className="medium-4 column">
            <div className="total_countering red">
              <div className="title">
                <span>{interpreter("totalPrize")}</span>
              </div>
              <div className="value">{`${numberWithCommas(
                tournament.Prize
              )} ${interpreter("Toman")}`}</div>
            </div>
          </div>
          <div className="medium-4 column">
            <div className="total_countering red">
              <div className="title">
                <span>{interpreter("roundID")}</span>
              </div>
              <div className="value">{id}</div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="row">
          {/* کارت های نمایشی */}
          <div className="large-3 medium-12 columns float-left">
            <div className="row collapse">
              <div className="large-12 medium-4 small-4 columns mb">
                <div
                  className={`deberna_img_tagline ${winners.oneLine &&
                    winners.oneLine.tickets &&
                    winners.oneLine.tickets.length
                    ? "checked"
                    : ""
                    } `}
                >
                  <img src={Card3} alt="" />
                </div>
              </div>
              <div className="large-12 medium-4 small-4 columns mb">
                <div
                  className={`deberna_img_tagline 
                ${winners.twoLine &&
                      winners.twoLine.tickets &&
                      winners.twoLine.tickets.length
                      ? "checked"
                      : ""
                    }
                `}
                >
                  <img src={Card2} alt="" />
                </div>
              </div>
              <div className="large-12 medium-4 small-4 columns mb">
                <div
                  className={`deberna_img_tagline 
                ${winners.daberna &&
                      winners.daberna.tickets &&
                      winners.daberna.tickets.length
                      ? "checked"
                      : ""
                    }
                `}
                >
                  <img src={Card1} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* پنل گردونه */}
          <div className="large-9 medium-12 columns float-right">
            <div className={`${!tournament.IsStarted && "deberna_gradient_pane"} mb`}  >


              {tournament.IsStarted && LastNumber != undefined ? (

                <BingoOffline number={LastNumber} />
              ) : (
                <Step1 tournament={tournament} />
              )}
            </div>
          </div>
        </div>
        {/* // پایان باکس بازی */}

        {/* اعداد شانس */}
        {tournament.IsStarted ? (
          <div className="row columns">
            <LuckyNumbers DrawNumbers={DrawNumbers} />
          </div>
        ) : (
          ""
        )}
        {/* // پایان اعداد شانس */}
        {/* بخش کارت ها */}
        {(tournament && !tournament.IsStarted) ||
          (boughtTicketsWithoutSplit && boughtTicketsWithoutSplit.length) ? (

          <div className="row columns">
            <div className="deberna_cards_panel deberna_groupbox_slider pos-r">
              <div className="display_flex align_items_center justify_content_center">
                <div className="tab_sample">
                  {!tournament.IsStarted ? (
                    <>
                      <div
                        className={`tab_item ${SliderName === "buy" ? "confrim" : "cancel"
                          }`}
                        onClick={() => this.boughtAndBuySlider("buy")}
                      >
                        {interpreter("Cards")}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div
                    className={`tab_item ${SliderName === "bought" ? "confrim" : "cancel"
                      }`}
                    onClick={() => this.boughtAndBuySlider("bought")}
                  >
                    {interpreter("boughtTickets")}
                  </div>
                </div>
              </div>



              {
                (
                  !tournament.IsStarted && SliderName === "buy" ?
                    <AllCard piceCard={tournament.Price} />
                    :
                    boughtTickets.length > 0 &&
                    <OwlCarousel ref="ddd" options={this.options}>
                      {boughtTickets.map((rowTicket) => (
                        <div className="groupBox_user_cards" key={Math.random()}>
                          {rowTicket && rowTicket.length
                            ? rowTicket.map((ticket) => {
                              return (
                                <CardItem
                                  data={ticket}
                                  ticketSelector={this.ticketSelector}
                                  key={ticket.TicketID}
                                  DrawNumbers={DrawNumbers}
                                />
                              );
                            })
                            : ""}
                        </div>
                      ))}
                    </OwlCarousel>
                )

              }

              {/*                      
 
              <OwlCarousel ref="ddd" options={this.options}>
                {SliderName === "buy" &&
                !tournament.IsStarted &&
                Tickets &&
                Tickets.length
                  ? Tickets.map((rowTicket) => (
                      <div className="groupBox_user_cards" key={Math.random()}>
                        {rowTicket && rowTicket.length
                          ? rowTicket.map((ticket) => {
                              const selected = selectedTicket.findIndex(
                                (x) => String(x) === String(ticket.TicketID)
                              );

                              return (
                                <CardItem
                                  data={ticket}
                                  selected={selected > -1}
                                  ticketSelector={this.ticketSelector}
                                  key={ticket.TicketID}
                                  DrawNumbers={DrawNumbers}
                                />
                              );
                            })
                          : ""}
                      </div>
                    ))
                  : 
                  boughtTickets.map((rowTicket) => (
                      <div className="groupBox_user_cards" key={Math.random()}>
                        {rowTicket && rowTicket.length
                          ? rowTicket.map((ticket) => {
                              return (
                                <CardItem
                                  data={ticket}
                                  ticketSelector={this.ticketSelector}
                                  key={ticket.TicketID}
                                  DrawNumbers={DrawNumbers}
                                />
                              );
                            })
                          : ""}
                      </div>
                    ))
                    
                    }
              </OwlCarousel> */}

              {/* {SliderName === "buy" ? (
                <div className="deberna_prices">
                  <div className="price_item">
                    <span className="text">{interpreter("cardPrice")}</span>
                    <span className="value">
                      {numberWithCommas(tournament.Price)}&nbsp;
                      {interpreter("Toman")}
                    </span>
                  </div>
                  <div className="price_item">
                    <span className="text">{interpreter("selectedCards")}</span>
                    <span className="value">{selectedTicket.length}</span>
                  </div>
                  <div className="price_item">
                    <span className="text">{interpreter("totalPrice")}</span>
                    <span className="value">
                      {numberWithCommas(totalPrice)}&nbsp;{interpreter("Toman")}
                    </span>
                  </div>
                  <button onClick={this.handleSubmit}>
                    <img src={Imgbuyticket} alt="" />
                  </button>
                </div>
              ) : (
                ""
              )} */}
            </div>
          </div>


        ) : (
          <></>
        )}
        {/* // پایان بخش کارت ها */}
        {/* === / end page content */}

        <Footer red />
      </>
    );
  }
}
export default withRouter(DebernaGame);
