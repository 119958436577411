import React from "react";

import Header from "./header-main";
import Footer from "./footer";
import Spinner from "../../App/spinner";

function Layout(WrapperComponent) {
  return class index extends React.Component {
    state = {
      loading: true,
    };
    componentDidMount() {
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1000);
    }

    render() {
      const { loading } = this.state;
      if (loading) {
        return (
          <div className="loading_apge">
            <Spinner />
          </div>
        );
      }
      return (
        <>
          <Header color="blue" />
          <WrapperComponent {...this.props} />
          <Footer />
        </>
      );
    }
  };
}

export default Layout;
