import React, { Component } from "react";

import { NavLink, Redirect } from "react-router-dom";
import { interpreter } from "../../utills";
import { user } from "../../Api";

class menu extends Component {
  state = {
    redirect: false,
  };

  signOut = async () => {
    const { data } = await user.logOut();
    if (data) {
      await localStorage.removeItem("data");
      await localStorage.removeItem("userAuth");
      this.setState({ Me: "" });
      this.setState({ redirect: true });
    }
  };

  render() {
    const { redirect } = this.state;
    return (
      <>
        {redirect && <Redirect to="/" />}

        <ul className="dashboard_menu menu vertical">
          <li>
            <NavLink to="/dashboard-home" activeClassName="actived">
              <span className="pe-home"></span>&nbsp;
              {interpreter("Profile")}
            </NavLink>
          </li>
          <li>
            <NavLink to="/dashboard-add-blance" activeClassName="actived">
              <span className="pe-wallet"></span>&nbsp;
              {interpreter("chargingAccount")}
            </NavLink>
          </li>
          <li>
            <NavLink to="/dashboard-Withdrawal" activeClassName="actived">
              <span className="pe-cash"></span>&nbsp;
              {interpreter("withdrawalRequest")}
            </NavLink>
          </li>


          <li>
            <NavLink to="/dashboard-authentication" activeClassName="actived">
              <span className="pe-door-lock"></span>&nbsp;
              {interpreter("Authentication")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/dashboard-authentication-archive"
              activeClassName="actived"
            >
              <span className="pe-door-lock"></span>&nbsp;
              {interpreter("cardBankArchive")}
            </NavLink>
          </li>
          <li>
            <NavLink to="/dashboard-transactions" activeClassName="actived">
              <span className="pe-repeat"></span>&nbsp;
              {interpreter("transactionHistory")}
            </NavLink>
          </li>
          {/* <li>
            <NavLink to="/dashboard/archive-bingo" activeClassName="actived">
              <span className="pe-repeat"></span>&nbsp;{" "}
              {interpreter("bingoRoundsHistory")}
            </NavLink>
          </li> */}
          <li>
            <NavLink to="/dashboard-archive-online-bingo" activeClassName="actived">
              <span className="pe-repeat"></span>&nbsp;{" "}
              {interpreter("bingoOnlineRoundsHistory")}
            </NavLink>
          </li>
          <li>
            <NavLink to="/dashboard-archive-offline-bingo" activeClassName="actived">
              <span className="pe-repeat"></span>&nbsp;{" "}
              {interpreter("bingoofflineRoundsHistory")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/dashboard-archive-tornoments"
              activeClassName="actived"
            >
              <span className="pe-note2"></span>&nbsp;
              {interpreter("tournamentHistory")}
            </NavLink>
          </li>
          <li>
            <NavLink to="/dashboard-change-pass" activeClassName="actived">
              <span className="pe-lock"></span>&nbsp;
              {interpreter("changePassword")}
            </NavLink>
          </li>
          <li onClick={this.signOut}>
            <button>
              <span className="pe-power"></span>&nbsp;
              {interpreter("exit")}
            </button>
          </li>
        </ul>
      </>
    );
  }
}
export default menu;
