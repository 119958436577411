import React, { Component } from "react";

import { basic } from "../../Api";

// Components
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";

import "./style.css";
import { pictureUrlMaker } from "../../utills";

const Slider=()=>{
const options= {
      items: 1,
      dots: true,
      nav: false,
      rewind: true,
      rtl: true,
      loop: true,
      margin: 0,
      autoplay: true,
      smartSpeed: 600,
      animateIn: "fadeIn",
      animateOut: "fadeOut",
    }
  const [listInfo,setListInfo]=React.useState([])
  
  React.useEffect(()=>{

    getImages()
  },[])
  const getImages = async () => {
    try {
      const { data } = await basic.slidersMain();
      if(data.success){
         setListInfo( data.sliders) 
      }
    
       
    } catch (error) {
      console.error(error);
    }
  };
 
    return (
      <>
        {/* slider */}
        <div className="row columns">
          <div className="main_slider">
            {listInfo.length >0 ? (
              <OwlCarousel  options={options}>
                {listInfo.map((x) => { 
                  return(
                      <div className="item" key={Math.random()}>
                    <img
                      className="imgItem"
                     src={pictureUrlMaker(x.imageUrl)}
                      alt=""
                    />
                  </div>
                  )
                })}
              </OwlCarousel>
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    );
  
}
export default Slider;
