import React, { Component } from "react";

// Components
import { interpreter } from "../../utills";

class LuckyNumber extends Component {
  buttonArray = [{ num: 1 }, { num: 2 }, { num: 3 }, { num: 4 }, { num: 5 }, { num: 6 }];

  render() {
    const { handleChangeLucky, lucky, luckyNumberDisable } = this.props;

    return (
      <>
        <div
          className="lucky_number mb"
          title={luckyNumberDisable ? interpreter("atFirstChooseAllSixNumbers") : ""}
        >
          <div className="lucky_number_text text-center mb">
            {interpreter("yourLuckyNumber")}
          </div>
          <div
            className={`phone_numbers_new ${luckyNumberDisable ? "disabledSpan" : ""} `}
          >
            {this.buttonArray.map((x) => (
              <button
                key={x.num}
                onClick={() => handleChangeLucky(x.num)}
                className={` ${lucky === x.num ? "clicked" : ""}`}
              >
                <span className="num">{x.num}</span>
              </button>
            ))}
          </div>
        </div>
      </>
    );
  }
}
export default LuckyNumber;
