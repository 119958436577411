import React, { Component } from "react";

import Img from "../../assets/images/404.jpg";
import { Link } from "react-router-dom";
import { interpreter } from "../../utills";

class Notfound extends Component {
  render() {
    return (
      <>
        <div className="row text-center columns">
          <div className="large-6 medium-8 float-center">
            <div className="icon-notFound">
              <img src={Img} alt="" />
            </div>
          </div>

          <div className="large-6 medium-8 float-center text-center">
            <h1 className="404_title">{interpreter("error404")}</h1>
            <p>{interpreter("error404Message")}</p>
            <Link to="/">{interpreter("backToHome")}</Link>
          </div>
        </div>
      </>
    );
  }
}
export default Notfound;
