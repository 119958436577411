import React from 'react'
import PropTypes from "prop-types";

const buttonArray = [
    { num: 1 },
    { num: 2 },
    { num: 3 },
    { num: 4 },
    { num: 5 },
    { num: 6 },
    { num: 7 },
  ];
const WingoItem=({ticketNumbers})=>{

    return(
        
        <div className={`phone_numbers_new click_false livestream`}>
          { buttonArray.map((x, i) => (
            <button
             // key={x.num}
              className={` ${
                 ticketNumbers &&
                 ticketNumbers.length &&
                 ticketNumbers[i]
                  ? ""
                  : ""
              }`}
            >
              <span className="num">{ ticketNumbers[i] || ""}</span>
            </button>
          ))}
        </div>
      
    )
}
WingoItem.propTypes = {
    ticketNumbers: PropTypes.array.isRequired,  
  };

export default WingoItem