import React, { Component } from "react";

// API
import { tournamentsDaberna } from "../../Api";

// Components
import Header from "../../components/layout/header-main";
import Footer from "../../components/layout/footer";
import { NavLink } from "react-router-dom";

// assets
import Img1 from "./img-1.png";
import Img2 from "./img-2.png";
import Img3 from "./img-3.png";
import Buy from "./buy.png";
import View from "./view.png";
import Card1 from "./card-1.png";
import Card2 from "./card-2.png";
import Card3 from "./card-3.png";
import "./style.css";
import {
  localStorgeSubscriber,
  toJalaliHour,
  toMiladiHour,
  numberWithCommas,
  interpreter,
} from "../../utills";

class DebernaList extends Component {
  state = { tournamentList: [] };
  componentDidMount() {
    this.getTornoment();
  }

  getTornoment = async () => {
    try {
      const { data } = await tournamentsDaberna.tournamentList();
      if (data.success) {
        this.setState({ tournamentList: data.tournaments });
      }
    } catch (error) {
      console.error(error);
    }
  };
  render() {
    const { tournamentList } = this.state;
    let { userLang } = localStorgeSubscriber("data");
    return (
      <>
        <Header color="red" />
        <div className="row columns mb-30">
          <div className="display_flex align_items_center justify_content_between Deberna_title_large mb-30">
            <h1>{interpreter("Daberna")}</h1>
            <h1>{interpreter("offline")}</h1>
          </div>
          <table className="deberna_table">
            <thead>
              <tr>
                <td className="headerDa"><span>{interpreter("gameNow")}</span></td>
                <td>
                  <img src={Img1} alt="" />
                </td>
                <td>
                  <img src={Img2} alt="" />
                </td>
                <td>
                  <img src={Img3} alt="" />
                </td>
              </tr>
            </thead>
            <tbody>
              {tournamentList && tournamentList.length
                ? tournamentList.filter(item => item.IsStarted).map((tournament) => (
                  <tr>
                    <td>
                      <NavLink to={`/bingoGame-${tournament.id}`} onClick={() => {
                        localStorage.setItem("idGameOffline", tournament.id);
                      }}>
                        <img src={View} alt="" />
                      </NavLink>
                    </td>
                    <td>
                      {numberWithCommas(tournament.Price)}&nbsp;
                      {interpreter("Toman")}
                    </td>
                    <td>
                      {numberWithCommas(tournament.Prize)}&nbsp;
                      {interpreter("Toman")}
                    </td>
                    <td dir="ltr">
                      {!userLang || userLang === "fa"
                        ? tournament.StartsAt_jalali
                        : tournament.StartsAt_gregorian}
                    </td>
                  </tr>
                ))
                : ""}

            </tbody>
          </table>
          <table className="deberna_table" style={{ marginTop: 70 }}>
            <thead>
              <tr>
                <td className="headerDa"><span>{interpreter("gameAfter")}</span></td>
                <td>
                  <img src={Img1} alt="" />
                </td>
                <td>
                  <img src={Img2} alt="" />
                </td>
                <td>
                  <img src={Img3} alt="" />
                </td>
              </tr>
            </thead>
            <tbody>

              {tournamentList && tournamentList.length
                ? tournamentList.filter(item => !item.IsStarted).map((tournament) => (
                  <tr>
                    <td>
                      <NavLink to={`/bingoGame-${tournament.id}`} onClick={() => {
                        localStorage.setItem("idGameOffline", tournament.id);
                      }}>
                        <img src={Buy} alt="" />
                      </NavLink>
                    </td>
                    <td>
                      {numberWithCommas(tournament.Price)}&nbsp;
                      {interpreter("Toman")}
                    </td>
                    <td>
                      {numberWithCommas(tournament.Prize)}&nbsp;
                      {interpreter("Toman")}
                    </td>
                    <td dir="ltr">
                      {!userLang || userLang === "fa"
                        ? tournament.StartsAt_jalali
                        : tournament.StartsAt_gregorian}
                    </td>
                  </tr>
                ))
                : ""}
            </tbody>
          </table>

        </div>

        {/*  */}


        <div className="row columns mb-30">
          <div className="deberna_panel card_wrappers_guide">
            <div className="row">
              <div className="large-4 medium-4 columns text-center">
                <img src={Card1} alt="/" />
              </div>
              <div className="large-4 medium-4 columns text-center">
                <img src={Card2} alt="/" />
              </div>
              <div className="large-4 medium-4 columns text-center">
                <img src={Card3} alt="/" />
              </div>
            </div>
          </div>
        </div>

        <Footer red />
      </>
    );
  }
}
export default DebernaList;
