import React, { Component } from "react";

// Api
import { user } from "../../Api";

// Components
import Layout from "../../components/layout/main";
import { Link, Redirect } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { interpreter } from "../../utills";
import { Helmet } from "react-helmet";
import { baseURL } from "../../Api/_api";

class Register extends Component {
  state = { Redirect: false, imageRepeat: "", returnAddress: "" };
  initialValues = {
    name: "",
    mobileNumber: "",
    userName: "",
    email: "",
    password1: "",
    password2: "",
    captcha: "",
  };
  componentDidMount() {
    this.imageRepeater();
    const returnAddress = localStorage.getItem("returnPath");
    this.setState({ returnAddress: returnAddress ? returnAddress : "" });
  }

  handleSubmit = async (values, action) => {
    try {
      const { data } = await user.register(values);

      if (data.success) {
        localStorage.setItem("userAuth", data.userAuth);

        await this.setState({ Redirect: true });
      }

      this.imageRepeater();
    } catch (error) {
      console.error(error);
    }
  };
  imageRepeater = async () => {
    await this.setState({ imageRepeat: "" });

    this.setState({
      imageRepeat: `${baseURL}/captcha/?_${Math.floor(
        Math.random() * 10000000
      )}`,
    });
  };
  render() {
    const { returnAddress } = this.state;
    return (
      <div>
        <Helmet>
          <title> {interpreter("registerButton")}</title>
        </Helmet>
        {this.state.Redirect && (
          <Redirect to={returnAddress ? returnAddress : "/"} />
        )}
        <div className="row columns">
          <div className="large-8 medium-12 small-12 float-center ">
            <div className="authentication_wrapper">
              <div className="image_box">
                {/* <div className="text">{interpreter("registerButton")}</div> */}
              </div>
              <div className="content_box">
                <Formik
                  initialValues={this.initialValues}
                  onSubmit={this.handleSubmit}
                >
                  {(props) => (
                    <Form onSubmitCapture={props.handleSubmit}>
                      <div className="form_control">
                        <Field
                          name="name"
                          placeholder={interpreter("nameAndFamilyName")}
                          type="text"
                        />
                      </div>
                      <div className="form_control">
                        <Field
                          name="mobileNumber"
                          placeholder={interpreter("mobileNumer")}
                          type="text"
                        />
                      </div>
                      <div className="form_control">
                        <Field
                          name="userName"
                          placeholder={interpreter("username")}
                          type="text"
                        />
                      </div>
                      <div className="form_control">
                        <Field
                          name="email"
                          placeholder={interpreter("email")}
                          type="Email"
                        />
                      </div>
                      <div className="form_control">
                        <Field
                          name="password1"
                          placeholder={interpreter("password")}
                          type="password"
                        />
                      </div>
                      <div className="form_control">
                        <Field
                          name="password2"
                          placeholder={interpreter("repeatPassword")}
                          type="password"
                        />
                      </div>
                      <div className="form_control captcha">
                        <Field
                          name="captcha"
                          placeholder={interpreter("captcha")}
                          type="text"
                        />
                        <img src={this.state.imageRepeat} alt="" />
                      </div>
                      <div
                        className="display_flex align_items_center pointer"
                        onClick={this.imageRepeater}
                      >
                        <span className="pe-refresh"></span>&nbsp;
                        {interpreter("refreshCode")}
                      </div>
                      <div className="text-left">
                        <button className="button" type="submit">
                          {interpreter("registerButton")}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>

                <div>
                  <Link to="/login" className="forget_pass_link">
                    {interpreter("doYouHaveAccount")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Layout(Register);
