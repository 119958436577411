import React, { Component } from "react";

// API

// Components
import CountDown from "react-countdown";
import CountDownView from "../../components/CountDown";
import { getLongDate, getLongDateNowSystem, interpreter, numberWithCommas } from "../../utills";

class Step1 extends Component {
  state = { tournament: "" };

  render() {
    const { tournament } = this.props;
    return (
      <>

        <div className="deberna_game_step1 display_flex align_items_center justify_content_center flex_direction_column">
          <div className="white_text small mb">{interpreter("Countdown")}</div>
          <div className="mb">
            {tournament ? (
              <CountDown
                //  date={tournament.StartsAt_Raw * 1000}
                //  precision={3}
                //  overtime={true}
                // now={() => {
                //   console.log("now")
                //   return getLongDateNowSystem()
                // }}

                date={getLongDate(tournament.StartsAt_gregorian)}

                //   intervalDelay={0}
                precision={3}
                renderer={CountDownView}
              />
            ) : (
              ""
            )}
          </div>
          <div className="white_text large mb">{interpreter("winPrize")}</div>
          <div className="price_jackpot">
            {numberWithCommas(tournament.Prize)}&nbsp;
            {interpreter("Toman")}
          </div>
        </div>
      </>
    );
  }
}
export default Step1;
