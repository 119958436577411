import React, { Component } from "react";
// api
import { video } from '../../Api/archive';

import IMG from "../../assets/images/wingo-live.jpg";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

const Weekwinner=()=> {
 
  const [liveStreamUrl,setLiveStreamUrl]=React.useState(null)
  React.useEffect(() => {
   setTimeout(() => {
      getVideo()
   }, 200);
  }, [])
 const  getVideo = async () => {
    try {
      let { data } = await video();
    setLiveStreamUrl(  data.lastActiveTournamentVideoUrl)
 
    } catch (error) {
      console.error(error);
    }
  }

 
    return (
      <>
        <div className="week_winner"
        style={{height:"470px"}}>
           
           {
             liveStreamUrl &&
              <ReactPlayer url={liveStreamUrl} width="100%"   controls={true} height="100%" /> 
           } 
           
          

        </div>
      </>
    );
  
}
export default Weekwinner;


 {/* <NavLink to={`/archive/${this.props.id}`}>
              <img src={IMG} alt="برنده هفته" />
            </NavLink> */}
