import React, { useState, useEffect } from "react";

const LuckyNumber = ({ numbers }) => {
  return (
    <>
      <div className="row  mb-30    "  >
        <div className="deberna_luckyNumbers deberna_luckyNumbers_not_background  "   >
          {numbers.map((number) => {
            // const selectedNumber = DrawNumbers.findIndex(
            //   (x) => Number(x) === Number(item)
            // );
            return (
              <div
                className={`item selected`}
                key={number}
                //  style={{margin:8}}
              >
                {number}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default LuckyNumber;
