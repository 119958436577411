import React, { useEffect } from "react";
import { liveBingo } from "../../../Api";
import ReactPlayer from "react-player";
import { isMobile } from "react-device-detect";

import NearestCard from "./nearestCards";

let loop=true;
const Line3Card = () => {

  
  const [show,setShow]=React.useState(false)
  React.useEffect(()=>{
    

    loop=true;
    requestDetails( )

    return(()=>loop=false)
  },[])
  const requestDetails = async (  ) => {
    if(loop){
      try {
      
        let { data } = await liveBingo.bingoDetails();
       
        if (data.success &&   data.tournament.liveStreamStatus=="1") {
          loop=false
          sessionStorage.setItem("live_url_video",data.tournament.liveStreamDabernaUrl)
          setShow(true)
         
  
          }else{
            setTimeout(() => {
              requestDetails()
            }, 10000);
          }
        
      } catch (error) {
  
      }
    }
    
  };


  return (
    <>
{show&& <div className="row  mb-30 bingo_live  ">
      <br />
      <br />

      <div style={{ margin: "auto" }}>
        <ReactPlayer
          url={sessionStorage.getItem("live_url_video")}
          width="100%"
          height={isMobile ? 220 : 500}
          controls={true}
        />
      </div>
      <div className="row">
        <div></div>
        <br />
       

        <NearestCard />
      </div>
    </div>}
    </>
   
  );
};

export default Line3Card;
