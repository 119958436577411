import React, { Component } from "react";

import { interpreter, numberWithCommas, toJalaliHour } from "../../utills";

import { Link } from "react-router-dom";
import "./index.css";

class TornomentItemSingle extends Component {
  render() {
    const { tournament } = this.props;

    return (
      <>
        {tournament.remainedTime > 0 ? (
          <>
            <Link
              to={`/tornoment-${tournament.tournamentId}`}
              className={`item  ${this.props.type}`}
            >
              <div className="display_flex align_items_center justify_content_between mb">
                <div className="content_tornoment">
                  <div className="name">{tournament.tournamentName}</div>
                  <div className="total_prize">
                    {interpreter("sumOfPrizes")}
                  </div>
                  <div className="prize">
                    {`${numberWithCommas(tournament.totalPrize)} ${interpreter(
                      "Toman"
                    )}`}
                  </div>
                </div>
                <div className="image">
                  <img src={this.props.image} alt="" />
                </div>
              </div>
              <div className="info">
                <div className="lottory_time">
                  <span>{interpreter("drawTime")}:</span>
                  <span>{toJalaliHour(tournament.drawDateTime_gregorian)}</span>
                </div>
              </div>
              {tournament.remainedTime > 0 ? (
                <button className="expanded button">
                  {interpreter("buyTicket")}
                </button>
              ) : (
                <>
                  <div className="text">{interpreter("timeisUp")}</div>
                </>
              )}
            </Link>
          </>
        ) : (
          <div className={`item  ${this.props.type}`}>
            <div className="display_flex align_items_center justify_content_between mb">
              <div className="content_tornoment">
                <div className="name">{tournament.tournamentName}</div>
                <div className="total_prize">{interpreter("sumOfPrizes")}</div>
                <div className="prize">
                  {`${numberWithCommas(tournament.totalPrize)} ${interpreter(
                    "Toman"
                  )}`}
                </div>
              </div>
              <div className="image">
                <img src={this.props.image} alt="" />
              </div>
            </div>
            <div className="info">
              <div className="lottory_time">
                <span>{interpreter("drawTime")}:</span>
                <span>{toJalaliHour(tournament.drawDateTime_gregorian)}</span>
              </div>
            </div>
            {tournament.remainedTime > 0 ? (
              <button className="expanded button">
                {interpreter("buyTicket")}
              </button>
            ) : (
              <>
                {this.props.home === true ? (
                  <Link to={`/LiveStream`} className="text">
                    {interpreter("timeisUp")}
                  </Link>
                ) : (
                  <div className="text">{interpreter("timeisUp")}</div>
                )}
              </>
            )}

          </div>
        )}
      </>
    );
  }
}
export default TornomentItemSingle;
