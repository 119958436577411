import React from "react";
import SweetAlert from "../../../../components/sweetalert/v";

import { interpreter, numberWithCommas } from "../../../../utills";
import WingoItem from "./WingoItem";
import WingoListItem from "./WingoListItem";
import BingLiveItem from "../dialogSubCard";
import BingoItem from "./BingoItem";

const Dialog = (data) => {
  SweetAlert({
    allowOutsideClick: true,
    title: interpreter("TransactionDescription"),
    width: "60vw",
    html: (
      <>
        <div>{data.description}</div>

        {data.isLotto ? 
        
        (typeof data.ticketNumbers[0] == "number" ? (
          <WingoItem ticketNumbers={data.ticketNumbers} />
        ) : (
          <WingoListItem
            tickets={data.ticketNumbers}
            winnedRows={data.winnedRows}
          />
        )):
        ""
        }
 
        {data.isDaberna ?
         (
          <BingoItem
            ticketNumbers={data.ticketNumbers}
            winnedRows={data.winnedRows}
          />
        )
        :
        ""
        }
        {data.isLiveDaberna ?
          (typeof data.ticketNumbers[0][0] == "number" ? (
            <BingoItem
            ticketNumbers={data.ticketNumbers}
            winnedRows={data.winnedRows}
          />
          ) : (
            <BingLiveItem
              tickets={data.ticketNumbers}
              winnedRows={data.winnedRows}
            />
          )):
        ""
           
        }
      </>
    ),
  });
};
export default Dialog;
