import { APIClientRippo,APIClientRippoNotError } from "./_api";
let base = "/tournaments";

export function tournamentsList() {
  return APIClientRippo.get(`${base}/tournamentsList/`);
}
export function tournamentDetails(values) {
  let bodyFormData = new FormData();

  let userAuth = localStorage.getItem("userAuth");
  for (let key in values) {
    bodyFormData.append(key, values[key]);
  }
  bodyFormData.append("userAuth", userAuth || "");
  return APIClientRippoNotError.post(`${base}/tournamentDetails/`, bodyFormData);
}
export function buyTicket(values) {
  let bodyFormData = new FormData();
  let userAuth = localStorage.getItem("userAuth");

  for (let key in values) {
    bodyFormData.append(key, values[key]);
  }
  bodyFormData.append("userAuth", userAuth || "");
  return APIClientRippo.post(`${base}/buyTicket/`, bodyFormData);
}
