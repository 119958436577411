
import img_0 from './images/img_0.png'
import img_1 from './images/img_1.png'
import img_2 from './images/img_2.png'
import img_3 from './images/img_3.png'
import img_4 from './images/img_4.png'
import img_5 from './images/img_5.png'
import img_6 from './images/img_6.png'
import img_7 from './images/img_7.png'
import img_8 from './images/img_8.png'
import img_9 from './images/img_9.png'
import img_10 from './images/img_10.png'
import img_11 from './images/img_11.png'
import img_12 from './images/img_12.png'
import img_13 from './images/img_13.png'
import img_14 from './images/img_14.png'
import img_15 from './images/img_15.png'
import img_16 from './images/img_16.png'
import img_17 from './images/img_17.png'
import img_18 from './images/img_18.png'
import img_19 from './images/img_19.png'
import img_20 from './images/img_20.png'
import img_21 from './images/img_21.png'
import img_22 from './images/img_22.png'
import img_23 from './images/img_23.png'
import img_24 from './images/img_24.png' 
import numbers from './Numbers'

const Data=(number,number2)=>{
 
  return({
  v: "5.7.8",
  fr: 29.9700012207031,
  ip: 0,
  op: 300.00001221925,
  w: 1920,
  h: 1080,
  nm: "wheel",
  ddd: 0,
  assets: [
    { id: "image_0", w: 2354, h: 1153, u: '', p: img_0, e: 0 },
    { id: "image_1", w: 1134, h: 771, u:""   , p:img_1, e: 0 },
    { id: "image_2", w: 1930, h: 735, u: ""  , p: img_2, e: 0 },
    { id: "image_3", w: 634, h: 634, u:"" , p: img_3, e: 0 },
    { id: "image_4", w: 786, h: 786, u:"" , p: img_4, e: 0 },
    { id: "image_5", w: 786, h: 786, u:"" , p: img_5, e: 0 },
    { id: "image_6", w: 786, h: 786, u:"" , p: img_6, e: 0 },
    { id: "image_7", w: 786, h: 786, u:"" , p: img_7, e: 0 },
    { id: "image_8", w: 786, h: 786, u:"" , p: img_8, e: 0 },
    { id: "image_9", w: 846, h: 669, u:"" , p: img_9, e: 0 },
    { id: "image_10", w: 846, h: 669, u: "", p: img_10, e: 0 },
    { id: "image_11", w: 846, h: 669, u: "", p:img_11, e: 0 },
    { id: "image_12", w: 846, h: 669, u: "", p:  img_12, e: 0 },
    { id: "image_13", w: 846, h: 669, u: "", p: img_13 , e: 0 },
    { id: "image_14", w: 598, h: 791, u: "", p:img_14  , e: 0 },
    { id: "image_15", w: 598, h: 791, u: "", p:  img_15, e: 0 },
    { id: "image_16", w: 598, h: 791, u: "", p: img_16 , e: 0 },
    { id: "image_17", w: 598, h: 791, u: "", p: img_17 , e: 0 },
    { id: "image_18", w: 598, h: 791, u: "", p:  img_18, e: 0 },
    { id: "image_19", w: 598, h: 593, u: "", p:  img_19, e: 0 },
    { id: "image_20", w: 581, h: 81, u:"" , p: img_20 , e: 0 },
    { id: "image_21", w: 693, h: 748, u:"", p:  img_21, e: 0 },
    { id: "image_22", w: 81, h: 107, u: "", p: img_22 , e: 0 },
    { id: "image_23", w: 760, h: 497, u: "", p:  img_23, e: 0 },
    { id: "image_24", w: 2042, h: 1580, u:"", p: img_24,  e: 0 },
    {
      id: "comp_0",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 5,
          nm: `${number}${number2}`,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [226, 400, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 240,
                    f: "Aller-Bold",
                    t: `${number}${number2}`,
                    ca: 1,
                    j: 2,
                    tr: 0,
                    lh: 240,
                    ls: 0,
                    fc: [0, 0, 0],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 300.00001221925,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: "15/wheel.psd",
          cl: "psd",
          refId: "image_0",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1177, 576.5, 0], ix: 2, l: 2 },
            a: { a: 0, k: [1177, 576.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 0,
          op: 300.00001221925,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_1",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: "15",
          refId: "image_4",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 6,
                  s: [597.976, 97.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 43.234,
                  s: [1005.927, 621.927, 0],
                  to: [0, 273.906, 0],
                  ti: [273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 72.55,
                  s: [725.976, 1025.879, 0],
                  to: [-441.906, -76, 0],
                  ti: [64, 269.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 107.966,
                  s: [270.024, 593.927, 0],
                  to: [0, -273.906, 0],
                  ti: [-273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 142,
                  s: [597.976, 97.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 178.381,
                  s: [1005.927, 621.927, 0],
                  to: [0, 273.906, 0],
                  ti: [273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 207.028,
                  s: [725.976, 1025.879, 0],
                  to: [-441.906, -76, 0],
                  ti: [64, 269.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 246.211,
                  s: [214.024, 509.927, 0],
                  to: [0, -273.906, 0],
                  ti: [-273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 278,
                  s: [597.976, 97.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 316.646,
                  s: [1005.927, 621.927, 0],
                  to: [0, 273.906, 0],
                  ti: [273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 347.076,
                  s: [725.976, 1025.879, 0],
                  to: [-441.906, -76, 0],
                  ti: [64, 269.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 383.835,
                  s: [270.024, 593.927, 0],
                  to: [0, -273.906, 0],
                  ti: [-193.906, 80, 0],
                },
                { t: 414.000016862566, s: [477.976, 97.976, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [393, 393, 0], ix: 1, l: 2 },
            s: { a: 0, k: [11.92, 11.92, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ef: [
            {
              ty: 5,
              nm: "Rotate Over Time",
              np: 3,
              mn: "ADBE CM Spin",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 0,
                  nm: "Rotation (degrees/second)",
                  mn: "ADBE CM Spin-0001",
                  ix: 1,
                  v: { a: 0, k: 80, ix: 1 },
                },
              ],
            },
            {
              ty: 5,
              nm: "(Transform)",
              np: 14,
              mn: "ADBE Geometry2",
              ix: 2,
              en: 1,
              ef: [
                {
                  ty: 3,
                  nm: "Anchor Point",
                  mn: "ADBE Geometry2-0001",
                  ix: 1,
                  v: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x:
                      "var $bm_rt;\n$bm_rt = [\n    thisLayer.width / 2,\n    thisLayer.height / 2\n];",
                  },
                },
                {
                  ty: 3,
                  nm: "Position",
                  mn: "ADBE Geometry2-0002",
                  ix: 2,
                  v: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                    x:
                      "var $bm_rt;\n$bm_rt = [\n    thisLayer.width / 2,\n    thisLayer.height / 2\n];",
                  },
                },
                {
                  ty: 7,
                  nm: "Uniform Scale",
                  mn: "ADBE Geometry2-0011",
                  ix: 3,
                  v: { a: 0, k: 0, ix: 3 },
                },
                {
                  ty: 0,
                  nm: "Scale Height",
                  mn: "ADBE Geometry2-0003",
                  ix: 4,
                  v: { a: 0, k: 100, ix: 4 },
                },
                {
                  ty: 0,
                  nm: "Scale Width",
                  mn: "ADBE Geometry2-0004",
                  ix: 5,
                  v: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: 0,
                  nm: "Skew",
                  mn: "ADBE Geometry2-0005",
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 },
                },
                {
                  ty: 0,
                  nm: "Skew Axis",
                  mn: "ADBE Geometry2-0006",
                  ix: 7,
                  v: { a: 0, k: 0, ix: 7 },
                },
                {
                  ty: 0,
                  nm: "Rotation",
                  mn: "ADBE Geometry2-0007",
                  ix: 8,
                  v: {
                    a: 0,
                    k: 0,
                    ix: 8,
                    x:
                      "var $bm_rt;\n$bm_rt = $bm_mul($bm_sub(time, inPoint), effect('Rotate Over Time')('Rotation (degrees/second)'));",
                  },
                },
                {
                  ty: 0,
                  nm: "Opacity",
                  mn: "ADBE Geometry2-0008",
                  ix: 9,
                  v: { a: 0, k: 100, ix: 9 },
                },
                {
                  ty: 7,
                  nm: "Use Composition’s Shutter Angle",
                  mn: "ADBE Geometry2-0009",
                  ix: 10,
                  v: { a: 0, k: 1, ix: 10 },
                },
                {
                  ty: 0,
                  nm: "Shutter Angle",
                  mn: "ADBE Geometry2-0010",
                  ix: 11,
                  v: { a: 0, k: 0, ix: 11 },
                },
                {
                  ty: 7,
                  nm: "Sampling",
                  mn: "ADBE Geometry2-0012",
                  ix: 12,
                  v: { a: 0, k: 1, ix: 12 },
                },
              ],
            },
          ],
          ip: 0,
          op: 904.000036820675,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: "8",
          refId: "image_5",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 1,
                  s: [597.976, 97.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 42.805,
                  s: [1021.927, 753.927, 0],
                  to: [-192, 161.906, 0],
                  ti: [273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 68.496,
                  s: [577.976, 989.879, 0],
                  to: [-273.906, 0, 0],
                  ti: [188, 225.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 94.675,
                  s: [226.024, 645.927, 0],
                  to: [0, -273.906, 0],
                  ti: [-273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 131,
                  s: [597.976, 97.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 172.805,
                  s: [1021.927, 753.927, 0],
                  to: [-192, 161.906, 0],
                  ti: [273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 198.496,
                  s: [577.976, 989.879, 0],
                  to: [-273.906, 0, 0],
                  ti: [188, 225.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 224.675,
                  s: [226.024, 645.927, 0],
                  to: [0, -273.906, 0],
                  ti: [-273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 261,
                  s: [597.976, 97.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 306.298,
                  s: [1021.927, 753.927, 0],
                  to: [-192, 161.906, 0],
                  ti: [273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 334.134,
                  s: [577.976, 989.879, 0],
                  to: [-273.906, 0, 0],
                  ti: [188, 225.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 362.5,
                  s: [226.024, 645.927, 0],
                  to: [0, -273.906, 0],
                  ti: [-201.906, 60, 0],
                },
                { t: 391.000015925756, s: [401.976, 193.976, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [393, 393, 0], ix: 1, l: 2 },
            s: { a: 0, k: [10.89, 10.89, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ef: [
            {
              ty: 5,
              nm: "Rotate Over Time",
              np: 3,
              mn: "ADBE CM Spin",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 0,
                  nm: "Rotation (degrees/second)",
                  mn: "ADBE CM Spin-0001",
                  ix: 1,
                  v: { a: 0, k: 110, ix: 1 },
                },
              ],
            },
            {
              ty: 5,
              nm: "(Transform)",
              np: 14,
              mn: "ADBE Geometry2",
              ix: 2,
              en: 1,
              ef: [
                {
                  ty: 3,
                  nm: "Anchor Point",
                  mn: "ADBE Geometry2-0001",
                  ix: 1,
                  v: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x:
                      "var $bm_rt;\n$bm_rt = [\n    thisLayer.width / 2,\n    thisLayer.height / 2\n];",
                  },
                },
                {
                  ty: 3,
                  nm: "Position",
                  mn: "ADBE Geometry2-0002",
                  ix: 2,
                  v: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                    x:
                      "var $bm_rt;\n$bm_rt = [\n    thisLayer.width / 2,\n    thisLayer.height / 2\n];",
                  },
                },
                {
                  ty: 7,
                  nm: "Uniform Scale",
                  mn: "ADBE Geometry2-0011",
                  ix: 3,
                  v: { a: 0, k: 0, ix: 3 },
                },
                {
                  ty: 0,
                  nm: "Scale Height",
                  mn: "ADBE Geometry2-0003",
                  ix: 4,
                  v: { a: 0, k: 100, ix: 4 },
                },
                {
                  ty: 0,
                  nm: "Scale Width",
                  mn: "ADBE Geometry2-0004",
                  ix: 5,
                  v: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: 0,
                  nm: "Skew",
                  mn: "ADBE Geometry2-0005",
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 },
                },
                {
                  ty: 0,
                  nm: "Skew Axis",
                  mn: "ADBE Geometry2-0006",
                  ix: 7,
                  v: { a: 0, k: 0, ix: 7 },
                },
                {
                  ty: 0,
                  nm: "Rotation",
                  mn: "ADBE Geometry2-0007",
                  ix: 8,
                  v: {
                    a: 0,
                    k: 0,
                    ix: 8,
                    x:
                      "var $bm_rt;\n$bm_rt = $bm_mul($bm_sub(time, inPoint), effect('Rotate Over Time')('Rotation (degrees/second)'));",
                  },
                },
                {
                  ty: 0,
                  nm: "Opacity",
                  mn: "ADBE Geometry2-0008",
                  ix: 9,
                  v: { a: 0, k: 100, ix: 9 },
                },
                {
                  ty: 7,
                  nm: "Use Composition’s Shutter Angle",
                  mn: "ADBE Geometry2-0009",
                  ix: 10,
                  v: { a: 0, k: 1, ix: 10 },
                },
                {
                  ty: 0,
                  nm: "Shutter Angle",
                  mn: "ADBE Geometry2-0010",
                  ix: 11,
                  v: { a: 0, k: 0, ix: 11 },
                },
                {
                  ty: 7,
                  nm: "Sampling",
                  mn: "ADBE Geometry2-0012",
                  ix: 12,
                  v: { a: 0, k: 1, ix: 12 },
                },
              ],
            },
          ],
          ip: 0,
          op: 904.000036820675,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 2,
          nm: "57",
          refId: "image_6",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 4,
                  s: [597.976, 213.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 46.685,
                  s: [981.927, 621.927, 0],
                  to: [-132, 209.906, 0],
                  ti: [205.906, -76, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 78.123,
                  s: [609.976, 893.879, 0],
                  to: [-249.906, -56, 0],
                  ti: [96, 229.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 110.104,
                  s: [266.024, 605.927, 0],
                  to: [0, -273.906, 0],
                  ti: [-273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 150,
                  s: [597.976, 213.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 192.685,
                  s: [981.927, 621.927, 0],
                  to: [-132, 209.906, 0],
                  ti: [205.906, -76, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 224.123,
                  s: [609.976, 893.879, 0],
                  to: [-249.906, -56, 0],
                  ti: [96, 229.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 256.104,
                  s: [266.024, 605.927, 0],
                  to: [0, -273.906, 0],
                  ti: [-273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 296,
                  s: [597.976, 213.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 335.931,
                  s: [981.927, 621.927, 0],
                  to: [-132, 209.906, 0],
                  ti: [205.906, -76, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 365.34,
                  s: [609.976, 893.879, 0],
                  to: [-249.906, -56, 0],
                  ti: [96, 229.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 395.258,
                  s: [266.024, 605.927, 0],
                  to: [0, -273.906, 0],
                  ti: [-273.906, 0, 0],
                },
                { t: 442.000018003029, s: [761.976, 141.976, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [393, 393, 0], ix: 1, l: 2 },
            s: { a: 0, k: [12.435, 12.435, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ef: [
            {
              ty: 5,
              nm: "Rotate Over Time",
              np: 3,
              mn: "ADBE CM Spin",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 0,
                  nm: "Rotation (degrees/second)",
                  mn: "ADBE CM Spin-0001",
                  ix: 1,
                  v: { a: 0, k: 100, ix: 1 },
                },
              ],
            },
            {
              ty: 5,
              nm: "(Transform)",
              np: 14,
              mn: "ADBE Geometry2",
              ix: 2,
              en: 1,
              ef: [
                {
                  ty: 3,
                  nm: "Anchor Point",
                  mn: "ADBE Geometry2-0001",
                  ix: 1,
                  v: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x:
                      "var $bm_rt;\n$bm_rt = [\n    thisLayer.width / 2,\n    thisLayer.height / 2\n];",
                  },
                },
                {
                  ty: 3,
                  nm: "Position",
                  mn: "ADBE Geometry2-0002",
                  ix: 2,
                  v: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                    x:
                      "var $bm_rt;\n$bm_rt = [\n    thisLayer.width / 2,\n    thisLayer.height / 2\n];",
                  },
                },
                {
                  ty: 7,
                  nm: "Uniform Scale",
                  mn: "ADBE Geometry2-0011",
                  ix: 3,
                  v: { a: 0, k: 0, ix: 3 },
                },
                {
                  ty: 0,
                  nm: "Scale Height",
                  mn: "ADBE Geometry2-0003",
                  ix: 4,
                  v: { a: 0, k: 100, ix: 4 },
                },
                {
                  ty: 0,
                  nm: "Scale Width",
                  mn: "ADBE Geometry2-0004",
                  ix: 5,
                  v: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: 0,
                  nm: "Skew",
                  mn: "ADBE Geometry2-0005",
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 },
                },
                {
                  ty: 0,
                  nm: "Skew Axis",
                  mn: "ADBE Geometry2-0006",
                  ix: 7,
                  v: { a: 0, k: 0, ix: 7 },
                },
                {
                  ty: 0,
                  nm: "Rotation",
                  mn: "ADBE Geometry2-0007",
                  ix: 8,
                  v: {
                    a: 0,
                    k: 0,
                    ix: 8,
                    x:
                      "var $bm_rt;\n$bm_rt = $bm_mul($bm_sub(time, inPoint), effect('Rotate Over Time')('Rotation (degrees/second)'));",
                  },
                },
                {
                  ty: 0,
                  nm: "Opacity",
                  mn: "ADBE Geometry2-0008",
                  ix: 9,
                  v: { a: 0, k: 100, ix: 9 },
                },
                {
                  ty: 7,
                  nm: "Use Composition’s Shutter Angle",
                  mn: "ADBE Geometry2-0009",
                  ix: 10,
                  v: { a: 0, k: 1, ix: 10 },
                },
                {
                  ty: 0,
                  nm: "Shutter Angle",
                  mn: "ADBE Geometry2-0010",
                  ix: 11,
                  v: { a: 0, k: 0, ix: 11 },
                },
                {
                  ty: 7,
                  nm: "Sampling",
                  mn: "ADBE Geometry2-0012",
                  ix: 12,
                  v: { a: 0, k: 1, ix: 12 },
                },
              ],
            },
          ],
          ip: 0,
          op: 904.000036820675,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 2,
          nm: "75",
          refId: "image_7",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 6,
                  s: [597.976, 97.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 43.004,
                  s: [937.927, 617.927, 0],
                  to: [-92, 213.906, 0],
                  ti: [245.906, -92, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 67.436,
                  s: [605.976, 905.879, 0],
                  to: [-261.906, -100, 0],
                  ti: [88, 221.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 92.328,
                  s: [298.024, 585.927, 0],
                  to: [0, -273.906, 0],
                  ti: [-273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 127,
                  s: [597.976, 97.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 164.004,
                  s: [937.927, 617.927, 0],
                  to: [-92, 213.906, 0],
                  ti: [245.906, -92, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 188.436,
                  s: [605.976, 905.879, 0],
                  to: [-261.906, -100, 0],
                  ti: [88, 221.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 213.328,
                  s: [298.024, 585.927, 0],
                  to: [0, -273.906, 0],
                  ti: [-273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 248,
                  s: [597.976, 97.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 283.495,
                  s: [937.927, 617.927, 0],
                  to: [-92, 213.906, 0],
                  ti: [245.906, -92, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 306.933,
                  s: [605.976, 905.879, 0],
                  to: [-261.906, -100, 0],
                  ti: [88, 221.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 330.809,
                  s: [298.024, 585.927, 0],
                  to: [0, -273.906, 0],
                  ti: [-273.906, 0, 0],
                },
                { t: 369.000015029678, s: [753.976, 81.976, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [393, 393, 0], ix: 1, l: 2 },
            s: { a: 0, k: [11.405, 11.405, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ef: [
            {
              ty: 5,
              nm: "Rotate Over Time",
              np: 3,
              mn: "ADBE CM Spin",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 0,
                  nm: "Rotation (degrees/second)",
                  mn: "ADBE CM Spin-0001",
                  ix: 1,
                  v: { a: 0, k: 263, ix: 1 },
                },
              ],
            },
            {
              ty: 5,
              nm: "(Transform)",
              np: 14,
              mn: "ADBE Geometry2",
              ix: 2,
              en: 1,
              ef: [
                {
                  ty: 3,
                  nm: "Anchor Point",
                  mn: "ADBE Geometry2-0001",
                  ix: 1,
                  v: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x:
                      "var $bm_rt;\n$bm_rt = [\n    thisLayer.width / 2,\n    thisLayer.height / 2\n];",
                  },
                },
                {
                  ty: 3,
                  nm: "Position",
                  mn: "ADBE Geometry2-0002",
                  ix: 2,
                  v: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                    x:
                      "var $bm_rt;\n$bm_rt = [\n    thisLayer.width / 2,\n    thisLayer.height / 2\n];",
                  },
                },
                {
                  ty: 7,
                  nm: "Uniform Scale",
                  mn: "ADBE Geometry2-0011",
                  ix: 3,
                  v: { a: 0, k: 0, ix: 3 },
                },
                {
                  ty: 0,
                  nm: "Scale Height",
                  mn: "ADBE Geometry2-0003",
                  ix: 4,
                  v: { a: 0, k: 100, ix: 4 },
                },
                {
                  ty: 0,
                  nm: "Scale Width",
                  mn: "ADBE Geometry2-0004",
                  ix: 5,
                  v: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: 0,
                  nm: "Skew",
                  mn: "ADBE Geometry2-0005",
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 },
                },
                {
                  ty: 0,
                  nm: "Skew Axis",
                  mn: "ADBE Geometry2-0006",
                  ix: 7,
                  v: { a: 0, k: 0, ix: 7 },
                },
                {
                  ty: 0,
                  nm: "Rotation",
                  mn: "ADBE Geometry2-0007",
                  ix: 8,
                  v: {
                    a: 0,
                    k: 0,
                    ix: 8,
                    x:
                      "var $bm_rt;\n$bm_rt = $bm_mul($bm_sub(time, inPoint), effect('Rotate Over Time')('Rotation (degrees/second)'));",
                  },
                },
                {
                  ty: 0,
                  nm: "Opacity",
                  mn: "ADBE Geometry2-0008",
                  ix: 9,
                  v: { a: 0, k: 100, ix: 9 },
                },
                {
                  ty: 7,
                  nm: "Use Composition’s Shutter Angle",
                  mn: "ADBE Geometry2-0009",
                  ix: 10,
                  v: { a: 0, k: 1, ix: 10 },
                },
                {
                  ty: 0,
                  nm: "Shutter Angle",
                  mn: "ADBE Geometry2-0010",
                  ix: 11,
                  v: { a: 0, k: 0, ix: 11 },
                },
                {
                  ty: 7,
                  nm: "Sampling",
                  mn: "ADBE Geometry2-0012",
                  ix: 12,
                  v: { a: 0, k: 1, ix: 12 },
                },
              ],
            },
          ],
          ip: 0,
          op: 904.000036820675,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 2,
          nm: "39",
          refId: "image_8",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 15,
                  s: [593.976, 169.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 50.506,
                  s: [981.927, 645.927, 0],
                  to: [-80, 205.906, 0],
                  ti: [273.906, -72, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 79.124,
                  s: [597.976, 1009.879, 0],
                  to: [-273.906, 0, 0],
                  ti: [0, 273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 119.19,
                  s: [234.024, 401.927, 0],
                  to: [60, -265.906, 0],
                  ti: [-273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 145,
                  s: [593.976, 169.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 180.506,
                  s: [981.927, 645.927, 0],
                  to: [-80, 205.906, 0],
                  ti: [273.906, -72, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 209.124,
                  s: [597.976, 1009.879, 0],
                  to: [-273.906, 0, 0],
                  ti: [0, 273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 249.19,
                  s: [234.024, 401.927, 0],
                  to: [60, -265.906, 0],
                  ti: [-273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 275,
                  s: [593.976, 169.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 308.015,
                  s: [981.927, 645.927, 0],
                  to: [-80, 205.906, 0],
                  ti: [273.906, -72, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 334.625,
                  s: [597.976, 1009.879, 0],
                  to: [-273.906, 0, 0],
                  ti: [0, 273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 371.88,
                  s: [234.024, 401.927, 0],
                  to: [60, -265.906, 0],
                  ti: [-273.906, 0, 0],
                },
                { t: 405.000016495988, s: [821.976, 165.976, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [393, 393, 0], ix: 1, l: 2 },
            s: { a: 0, k: [11.436, 11.436, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ef: [
            {
              ty: 5,
              nm: "Rotate Over Time",
              np: 3,
              mn: "ADBE CM Spin",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 0,
                  nm: "Rotation (degrees/second)",
                  mn: "ADBE CM Spin-0001",
                  ix: 1,
                  v: { a: 0, k: -78, ix: 1 },
                },
              ],
            },
            {
              ty: 5,
              nm: "(Transform)",
              np: 14,
              mn: "ADBE Geometry2",
              ix: 2,
              en: 1,
              ef: [
                {
                  ty: 3,
                  nm: "Anchor Point",
                  mn: "ADBE Geometry2-0001",
                  ix: 1,
                  v: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x:
                      "var $bm_rt;\n$bm_rt = [\n    thisLayer.width / 2,\n    thisLayer.height / 2\n];",
                  },
                },
                {
                  ty: 3,
                  nm: "Position",
                  mn: "ADBE Geometry2-0002",
                  ix: 2,
                  v: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                    x:
                      "var $bm_rt;\n$bm_rt = [\n    thisLayer.width / 2,\n    thisLayer.height / 2\n];",
                  },
                },
                {
                  ty: 7,
                  nm: "Uniform Scale",
                  mn: "ADBE Geometry2-0011",
                  ix: 3,
                  v: { a: 0, k: 0, ix: 3 },
                },
                {
                  ty: 0,
                  nm: "Scale Height",
                  mn: "ADBE Geometry2-0003",
                  ix: 4,
                  v: { a: 0, k: 100, ix: 4 },
                },
                {
                  ty: 0,
                  nm: "Scale Width",
                  mn: "ADBE Geometry2-0004",
                  ix: 5,
                  v: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: 0,
                  nm: "Skew",
                  mn: "ADBE Geometry2-0005",
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 },
                },
                {
                  ty: 0,
                  nm: "Skew Axis",
                  mn: "ADBE Geometry2-0006",
                  ix: 7,
                  v: { a: 0, k: 0, ix: 7 },
                },
                {
                  ty: 0,
                  nm: "Rotation",
                  mn: "ADBE Geometry2-0007",
                  ix: 8,
                  v: {
                    a: 0,
                    k: 0,
                    ix: 8,
                    x:
                      "var $bm_rt;\n$bm_rt = $bm_mul($bm_sub(time, inPoint), effect('Rotate Over Time')('Rotation (degrees/second)'));",
                  },
                },
                {
                  ty: 0,
                  nm: "Opacity",
                  mn: "ADBE Geometry2-0008",
                  ix: 9,
                  v: { a: 0, k: 100, ix: 9 },
                },
                {
                  ty: 7,
                  nm: "Use Composition’s Shutter Angle",
                  mn: "ADBE Geometry2-0009",
                  ix: 10,
                  v: { a: 0, k: 1, ix: 10 },
                },
                {
                  ty: 0,
                  nm: "Shutter Angle",
                  mn: "ADBE Geometry2-0010",
                  ix: 11,
                  v: { a: 0, k: 0, ix: 11 },
                },
                {
                  ty: 7,
                  nm: "Sampling",
                  mn: "ADBE Geometry2-0012",
                  ix: 12,
                  v: { a: 0, k: 1, ix: 12 },
                },
              ],
            },
          ],
          ip: 0,
          op: 904.000036820675,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 2,
          nm: "2",
          refId: "image_9",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 1,
                  s: [597.976, 97.976, 0],
                  to: [251.775, 0, 0],
                  ti: [-40, -257.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 31.788,
                  s: [973.927, 653.927, 0],
                  to: [-44, 469.906, 0],
                  ti: [273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 61.085,
                  s: [597.976, 1089.879, 0],
                  to: [-273.906, 0, 0],
                  ti: [0, 273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 90.795,
                  s: [230.024, 585.927, 0],
                  to: [0, -273.906, 0],
                  ti: [-273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 120,
                  s: [597.976, 97.976, 0],
                  to: [251.775, 0, 0],
                  ti: [-40, -257.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 159.843,
                  s: [973.927, 653.927, 0],
                  to: [-44, 469.906, 0],
                  ti: [273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 197.756,
                  s: [597.976, 1089.879, 0],
                  to: [-273.906, 0, 0],
                  ti: [0, 273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 236.205,
                  s: [230.024, 585.927, 0],
                  to: [0, -273.906, 0],
                  ti: [-273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 274,
                  s: [597.976, 97.976, 0],
                  to: [251.775, 0, 0],
                  ti: [-40, -257.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 304.365,
                  s: [973.927, 653.927, 0],
                  to: [-44, 469.906, 0],
                  ti: [273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 333.26,
                  s: [597.976, 1089.879, 0],
                  to: [-273.906, 0, 0],
                  ti: [0, 273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 362.563,
                  s: [230.024, 585.927, 0],
                  to: [84, -581.906, 0],
                  ti: [-22.131, 0, 0],
                },
                { t: 393.000016007218, s: [697.976, 185.976, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [423, 334.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [14.224, 14.224, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ef: [
            {
              ty: 5,
              nm: "Rotate Over Time",
              np: 3,
              mn: "ADBE CM Spin",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 0,
                  nm: "Rotation (degrees/second)",
                  mn: "ADBE CM Spin-0001",
                  ix: 1,
                  v: { a: 0, k: -167, ix: 1 },
                },
              ],
            },
            {
              ty: 5,
              nm: "(Transform)",
              np: 14,
              mn: "ADBE Geometry2",
              ix: 2,
              en: 1,
              ef: [
                {
                  ty: 3,
                  nm: "Anchor Point",
                  mn: "ADBE Geometry2-0001",
                  ix: 1,
                  v: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x:
                      "var $bm_rt;\n$bm_rt = [\n    thisLayer.width / 2,\n    thisLayer.height / 2\n];",
                  },
                },
                {
                  ty: 3,
                  nm: "Position",
                  mn: "ADBE Geometry2-0002",
                  ix: 2,
                  v: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                    x:
                      "var $bm_rt;\n$bm_rt = [\n    thisLayer.width / 2,\n    thisLayer.height / 2\n];",
                  },
                },
                {
                  ty: 7,
                  nm: "Uniform Scale",
                  mn: "ADBE Geometry2-0011",
                  ix: 3,
                  v: { a: 0, k: 0, ix: 3 },
                },
                {
                  ty: 0,
                  nm: "Scale Height",
                  mn: "ADBE Geometry2-0003",
                  ix: 4,
                  v: { a: 0, k: 100, ix: 4 },
                },
                {
                  ty: 0,
                  nm: "Scale Width",
                  mn: "ADBE Geometry2-0004",
                  ix: 5,
                  v: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: 0,
                  nm: "Skew",
                  mn: "ADBE Geometry2-0005",
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 },
                },
                {
                  ty: 0,
                  nm: "Skew Axis",
                  mn: "ADBE Geometry2-0006",
                  ix: 7,
                  v: { a: 0, k: 0, ix: 7 },
                },
                {
                  ty: 0,
                  nm: "Rotation",
                  mn: "ADBE Geometry2-0007",
                  ix: 8,
                  v: {
                    a: 0,
                    k: 0,
                    ix: 8,
                    x:
                      "var $bm_rt;\n$bm_rt = $bm_mul($bm_sub(time, inPoint), effect('Rotate Over Time')('Rotation (degrees/second)'));",
                  },
                },
                {
                  ty: 0,
                  nm: "Opacity",
                  mn: "ADBE Geometry2-0008",
                  ix: 9,
                  v: { a: 0, k: 100, ix: 9 },
                },
                {
                  ty: 7,
                  nm: "Use Composition’s Shutter Angle",
                  mn: "ADBE Geometry2-0009",
                  ix: 10,
                  v: { a: 0, k: 1, ix: 10 },
                },
                {
                  ty: 0,
                  nm: "Shutter Angle",
                  mn: "ADBE Geometry2-0010",
                  ix: 11,
                  v: { a: 0, k: 0, ix: 11 },
                },
                {
                  ty: 7,
                  nm: "Sampling",
                  mn: "ADBE Geometry2-0012",
                  ix: 12,
                  v: { a: 0, k: 1, ix: 12 },
                },
              ],
            },
          ],
          ip: 0,
          op: 904.000036820675,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 2,
          nm: "16",
          refId: "image_10",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 11,
                  s: [617.976, 149.976, 0],
                  to: [273.906, 36, 0],
                  ti: [-32, -245.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 43.924,
                  s: [997.927, 569.927, 0],
                  to: [-64, 273.906, 0],
                  ti: [253.906, -64, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 77.675,
                  s: [605.976, 1021.879, 0],
                  to: [-185.906, -52, 0],
                  ti: [0, 273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 109.75,
                  s: [226.024, 613.927, 0],
                  to: [-4, -261.906, 0],
                  ti: [-273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 146,
                  s: [617.976, 149.976, 0],
                  to: [273.906, 36, 0],
                  ti: [-32, -245.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 178.924,
                  s: [997.927, 569.927, 0],
                  to: [-64, 273.906, 0],
                  ti: [253.906, -64, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 212.675,
                  s: [605.976, 1021.879, 0],
                  to: [-185.906, -52, 0],
                  ti: [0, 273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 244.75,
                  s: [226.024, 613.927, 0],
                  to: [-4, -261.906, 0],
                  ti: [-273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 281,
                  s: [617.976, 149.976, 0],
                  to: [273.906, 36, 0],
                  ti: [-32, -245.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 313.133,
                  s: [997.927, 569.927, 0],
                  to: [-64, 273.906, 0],
                  ti: [253.906, -64, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 346.074,
                  s: [605.976, 1021.879, 0],
                  to: [-185.906, -52, 0],
                  ti: [0, 273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 377.378,
                  s: [226.024, 613.927, 0],
                  to: [-4, -261.906, 0],
                  ti: [-285.906, -96, 0],
                },
                { t: 416.000016944027, s: [757.976, 261.976, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [423, 334.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [13.717, 13.717, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ef: [
            {
              ty: 5,
              nm: "Rotate Over Time",
              np: 3,
              mn: "ADBE CM Spin",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 0,
                  nm: "Rotation (degrees/second)",
                  mn: "ADBE CM Spin-0001",
                  ix: 1,
                  v: { a: 0, k: 100, ix: 1 },
                },
              ],
            },
            {
              ty: 5,
              nm: "(Transform)",
              np: 14,
              mn: "ADBE Geometry2",
              ix: 2,
              en: 1,
              ef: [
                {
                  ty: 3,
                  nm: "Anchor Point",
                  mn: "ADBE Geometry2-0001",
                  ix: 1,
                  v: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x:
                      "var $bm_rt;\n$bm_rt = [\n    thisLayer.width / 2,\n    thisLayer.height / 2\n];",
                  },
                },
                {
                  ty: 3,
                  nm: "Position",
                  mn: "ADBE Geometry2-0002",
                  ix: 2,
                  v: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                    x:
                      "var $bm_rt;\n$bm_rt = [\n    thisLayer.width / 2,\n    thisLayer.height / 2\n];",
                  },
                },
                {
                  ty: 7,
                  nm: "Uniform Scale",
                  mn: "ADBE Geometry2-0011",
                  ix: 3,
                  v: { a: 0, k: 0, ix: 3 },
                },
                {
                  ty: 0,
                  nm: "Scale Height",
                  mn: "ADBE Geometry2-0003",
                  ix: 4,
                  v: { a: 0, k: 100, ix: 4 },
                },
                {
                  ty: 0,
                  nm: "Scale Width",
                  mn: "ADBE Geometry2-0004",
                  ix: 5,
                  v: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: 0,
                  nm: "Skew",
                  mn: "ADBE Geometry2-0005",
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 },
                },
                {
                  ty: 0,
                  nm: "Skew Axis",
                  mn: "ADBE Geometry2-0006",
                  ix: 7,
                  v: { a: 0, k: 0, ix: 7 },
                },
                {
                  ty: 0,
                  nm: "Rotation",
                  mn: "ADBE Geometry2-0007",
                  ix: 8,
                  v: {
                    a: 0,
                    k: 0,
                    ix: 8,
                    x:
                      "var $bm_rt;\n$bm_rt = $bm_mul($bm_sub(time, inPoint), effect('Rotate Over Time')('Rotation (degrees/second)'));",
                  },
                },
                {
                  ty: 0,
                  nm: "Opacity",
                  mn: "ADBE Geometry2-0008",
                  ix: 9,
                  v: { a: 0, k: 100, ix: 9 },
                },
                {
                  ty: 7,
                  nm: "Use Composition’s Shutter Angle",
                  mn: "ADBE Geometry2-0009",
                  ix: 10,
                  v: { a: 0, k: 1, ix: 10 },
                },
                {
                  ty: 0,
                  nm: "Shutter Angle",
                  mn: "ADBE Geometry2-0010",
                  ix: 11,
                  v: { a: 0, k: 0, ix: 11 },
                },
                {
                  ty: 7,
                  nm: "Sampling",
                  mn: "ADBE Geometry2-0012",
                  ix: 12,
                  v: { a: 0, k: 1, ix: 12 },
                },
              ],
            },
          ],
          ip: 0,
          op: 904.000036820675,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 2,
          nm: "41",
          refId: "image_11",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 2,
                  s: [597.976, 97.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 37.594,
                  s: [913.927, 613.927, 0],
                  to: [0, 273.906, 0],
                  ti: [273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 66.668,
                  s: [605.976, 965.879, 0],
                  to: [-273.906, 0, 0],
                  ti: [0, 273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 98.183,
                  s: [242.024, 593.927, 0],
                  to: [0, -273.906, 0],
                  ti: [-273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 134,
                  s: [597.976, 97.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 169.594,
                  s: [913.927, 613.927, 0],
                  to: [0, 273.906, 0],
                  ti: [273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 198.668,
                  s: [605.976, 965.879, 0],
                  to: [-273.906, 0, 0],
                  ti: [0, 273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 230.183,
                  s: [242.024, 593.927, 0],
                  to: [0, -273.906, 0],
                  ti: [-273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 266,
                  s: [597.976, 97.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 301.594,
                  s: [913.927, 613.927, 0],
                  to: [0, 273.906, 0],
                  ti: [273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 330.668,
                  s: [605.976, 965.879, 0],
                  to: [-273.906, 0, 0],
                  ti: [0, 273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 362.183,
                  s: [242.024, 593.927, 0],
                  to: [0, -273.906, 0],
                  ti: [-273.906, 0, 0],
                },
                { t: 398.000016210872, s: [597.976, 97.976, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [423, 334.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [14.675, 14.675, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ef: [
            {
              ty: 5,
              nm: "Rotate Over Time",
              np: 3,
              mn: "ADBE CM Spin",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 0,
                  nm: "Rotation (degrees/second)",
                  mn: "ADBE CM Spin-0001",
                  ix: 1,
                  v: { a: 0, k: 266, ix: 1 },
                },
              ],
            },
            {
              ty: 5,
              nm: "(Transform)",
              np: 14,
              mn: "ADBE Geometry2",
              ix: 2,
              en: 1,
              ef: [
                {
                  ty: 3,
                  nm: "Anchor Point",
                  mn: "ADBE Geometry2-0001",
                  ix: 1,
                  v: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x:
                      "var $bm_rt;\n$bm_rt = [\n    thisLayer.width / 2,\n    thisLayer.height / 2\n];",
                  },
                },
                {
                  ty: 3,
                  nm: "Position",
                  mn: "ADBE Geometry2-0002",
                  ix: 2,
                  v: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                    x:
                      "var $bm_rt;\n$bm_rt = [\n    thisLayer.width / 2,\n    thisLayer.height / 2\n];",
                  },
                },
                {
                  ty: 7,
                  nm: "Uniform Scale",
                  mn: "ADBE Geometry2-0011",
                  ix: 3,
                  v: { a: 0, k: 0, ix: 3 },
                },
                {
                  ty: 0,
                  nm: "Scale Height",
                  mn: "ADBE Geometry2-0003",
                  ix: 4,
                  v: { a: 0, k: 100, ix: 4 },
                },
                {
                  ty: 0,
                  nm: "Scale Width",
                  mn: "ADBE Geometry2-0004",
                  ix: 5,
                  v: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: 0,
                  nm: "Skew",
                  mn: "ADBE Geometry2-0005",
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 },
                },
                {
                  ty: 0,
                  nm: "Skew Axis",
                  mn: "ADBE Geometry2-0006",
                  ix: 7,
                  v: { a: 0, k: 0, ix: 7 },
                },
                {
                  ty: 0,
                  nm: "Rotation",
                  mn: "ADBE Geometry2-0007",
                  ix: 8,
                  v: {
                    a: 0,
                    k: 0,
                    ix: 8,
                    x:
                      "var $bm_rt;\n$bm_rt = $bm_mul($bm_sub(time, inPoint), effect('Rotate Over Time')('Rotation (degrees/second)'));",
                  },
                },
                {
                  ty: 0,
                  nm: "Opacity",
                  mn: "ADBE Geometry2-0008",
                  ix: 9,
                  v: { a: 0, k: 100, ix: 9 },
                },
                {
                  ty: 7,
                  nm: "Use Composition’s Shutter Angle",
                  mn: "ADBE Geometry2-0009",
                  ix: 10,
                  v: { a: 0, k: 1, ix: 10 },
                },
                {
                  ty: 0,
                  nm: "Shutter Angle",
                  mn: "ADBE Geometry2-0010",
                  ix: 11,
                  v: { a: 0, k: 0, ix: 11 },
                },
                {
                  ty: 7,
                  nm: "Sampling",
                  mn: "ADBE Geometry2-0012",
                  ix: 12,
                  v: { a: 0, k: 1, ix: 12 },
                },
              ],
            },
          ],
          ip: 0,
          op: 904.000036820675,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 2,
          nm: "73",
          refId: "image_12",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 8,
                  s: [601.976, 193.976, 0],
                  to: [241.906, 36, 0],
                  ti: [-40, -265.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 41.676,
                  s: [1057.927, 593.927, 0],
                  to: [-64, 213.906, 0],
                  ti: [277.906, -72, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 75.355,
                  s: [597.976, 1017.879, 0],
                  to: [-193.906, -72, 0],
                  ti: [32, 265.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 107.776,
                  s: [178.024, 593.927, 0],
                  to: [48, -261.906, 0],
                  ti: [-273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 141,
                  s: [601.976, 193.976, 0],
                  to: [241.906, 36, 0],
                  ti: [-40, -265.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 174.676,
                  s: [1057.927, 593.927, 0],
                  to: [-64, 213.906, 0],
                  ti: [277.906, -72, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 208.355,
                  s: [597.976, 1017.879, 0],
                  to: [-193.906, -72, 0],
                  ti: [32, 265.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 240.776,
                  s: [178.024, 593.927, 0],
                  to: [48, -261.906, 0],
                  ti: [-273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 274,
                  s: [601.976, 193.976, 0],
                  to: [241.906, 36, 0],
                  ti: [-40, -265.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 309.434,
                  s: [1057.927, 593.927, 0],
                  to: [-64, 213.906, 0],
                  ti: [277.906, -72, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 344.87,
                  s: [597.976, 1017.879, 0],
                  to: [-193.906, -72, 0],
                  ti: [32, 265.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 378.983,
                  s: [178.024, 593.927, 0],
                  to: [48, -261.906, 0],
                  ti: [-265.906, 88, 0],
                },
                { t: 407.00001657745, s: [473.976, 225.976, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [423, 334.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [14.323, 14.323, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ef: [
            {
              ty: 5,
              nm: "Rotate Over Time",
              np: 3,
              mn: "ADBE CM Spin",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 0,
                  nm: "Rotation (degrees/second)",
                  mn: "ADBE CM Spin-0001",
                  ix: 1,
                  v: { a: 0, k: 112, ix: 1 },
                },
              ],
            },
            {
              ty: 5,
              nm: "(Transform)",
              np: 14,
              mn: "ADBE Geometry2",
              ix: 2,
              en: 1,
              ef: [
                {
                  ty: 3,
                  nm: "Anchor Point",
                  mn: "ADBE Geometry2-0001",
                  ix: 1,
                  v: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x:
                      "var $bm_rt;\n$bm_rt = [\n    thisLayer.width / 2,\n    thisLayer.height / 2\n];",
                  },
                },
                {
                  ty: 3,
                  nm: "Position",
                  mn: "ADBE Geometry2-0002",
                  ix: 2,
                  v: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                    x:
                      "var $bm_rt;\n$bm_rt = [\n    thisLayer.width / 2,\n    thisLayer.height / 2\n];",
                  },
                },
                {
                  ty: 7,
                  nm: "Uniform Scale",
                  mn: "ADBE Geometry2-0011",
                  ix: 3,
                  v: { a: 0, k: 0, ix: 3 },
                },
                {
                  ty: 0,
                  nm: "Scale Height",
                  mn: "ADBE Geometry2-0003",
                  ix: 4,
                  v: { a: 0, k: 100, ix: 4 },
                },
                {
                  ty: 0,
                  nm: "Scale Width",
                  mn: "ADBE Geometry2-0004",
                  ix: 5,
                  v: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: 0,
                  nm: "Skew",
                  mn: "ADBE Geometry2-0005",
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 },
                },
                {
                  ty: 0,
                  nm: "Skew Axis",
                  mn: "ADBE Geometry2-0006",
                  ix: 7,
                  v: { a: 0, k: 0, ix: 7 },
                },
                {
                  ty: 0,
                  nm: "Rotation",
                  mn: "ADBE Geometry2-0007",
                  ix: 8,
                  v: {
                    a: 0,
                    k: 0,
                    ix: 8,
                    x:
                      "var $bm_rt;\n$bm_rt = $bm_mul($bm_sub(time, inPoint), effect('Rotate Over Time')('Rotation (degrees/second)'));",
                  },
                },
                {
                  ty: 0,
                  nm: "Opacity",
                  mn: "ADBE Geometry2-0008",
                  ix: 9,
                  v: { a: 0, k: 100, ix: 9 },
                },
                {
                  ty: 7,
                  nm: "Use Composition’s Shutter Angle",
                  mn: "ADBE Geometry2-0009",
                  ix: 10,
                  v: { a: 0, k: 1, ix: 10 },
                },
                {
                  ty: 0,
                  nm: "Shutter Angle",
                  mn: "ADBE Geometry2-0010",
                  ix: 11,
                  v: { a: 0, k: 0, ix: 11 },
                },
                {
                  ty: 7,
                  nm: "Sampling",
                  mn: "ADBE Geometry2-0012",
                  ix: 12,
                  v: { a: 0, k: 1, ix: 12 },
                },
              ],
            },
          ],
          ip: 0,
          op: 904.000036820675,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 2,
          nm: "12",
          refId: "image_13",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 3,
                  s: [601.976, 153.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 39.72,
                  s: [1037.927, 589.927, 0],
                  to: [0, 273.906, 0],
                  ti: [273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 75.621,
                  s: [605.976, 1005.879, 0],
                  to: [-273.906, 0, 0],
                  ti: [0, 273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 110.99,
                  s: [174.024, 605.927, 0],
                  to: [0, -273.906, 0],
                  ti: [-273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 148,
                  s: [601.976, 153.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 184.72,
                  s: [1037.927, 589.927, 0],
                  to: [0, 273.906, 0],
                  ti: [273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 220.621,
                  s: [605.976, 1005.879, 0],
                  to: [-273.906, 0, 0],
                  ti: [0, 273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 255.99,
                  s: [174.024, 605.927, 0],
                  to: [0, -273.906, 0],
                  ti: [-273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 293,
                  s: [601.976, 153.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 331.306,
                  s: [1037.927, 589.927, 0],
                  to: [0, 273.906, 0],
                  ti: [273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 368.758,
                  s: [605.976, 1005.879, 0],
                  to: [-273.906, 0, 0],
                  ti: [0, 273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 405.654,
                  s: [174.024, 605.927, 0],
                  to: [0, -273.906, 0],
                  ti: [-105.906, 96, 0],
                },
                { t: 438.000017840106, s: [457.976, 113.976, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [423, 334.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [14.675, 14.675, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ef: [
            {
              ty: 5,
              nm: "Rotate Over Time",
              np: 3,
              mn: "ADBE CM Spin",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 0,
                  nm: "Rotation (degrees/second)",
                  mn: "ADBE CM Spin-0001",
                  ix: 1,
                  v: { a: 0, k: 100, ix: 1 },
                },
              ],
            },
            {
              ty: 5,
              nm: "(Transform)",
              np: 14,
              mn: "ADBE Geometry2",
              ix: 2,
              en: 1,
              ef: [
                {
                  ty: 3,
                  nm: "Anchor Point",
                  mn: "ADBE Geometry2-0001",
                  ix: 1,
                  v: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x:
                      "var $bm_rt;\n$bm_rt = [\n    thisLayer.width / 2,\n    thisLayer.height / 2\n];",
                  },
                },
                {
                  ty: 3,
                  nm: "Position",
                  mn: "ADBE Geometry2-0002",
                  ix: 2,
                  v: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                    x:
                      "var $bm_rt;\n$bm_rt = [\n    thisLayer.width / 2,\n    thisLayer.height / 2\n];",
                  },
                },
                {
                  ty: 7,
                  nm: "Uniform Scale",
                  mn: "ADBE Geometry2-0011",
                  ix: 3,
                  v: { a: 0, k: 0, ix: 3 },
                },
                {
                  ty: 0,
                  nm: "Scale Height",
                  mn: "ADBE Geometry2-0003",
                  ix: 4,
                  v: { a: 0, k: 100, ix: 4 },
                },
                {
                  ty: 0,
                  nm: "Scale Width",
                  mn: "ADBE Geometry2-0004",
                  ix: 5,
                  v: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: 0,
                  nm: "Skew",
                  mn: "ADBE Geometry2-0005",
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 },
                },
                {
                  ty: 0,
                  nm: "Skew Axis",
                  mn: "ADBE Geometry2-0006",
                  ix: 7,
                  v: { a: 0, k: 0, ix: 7 },
                },
                {
                  ty: 0,
                  nm: "Rotation",
                  mn: "ADBE Geometry2-0007",
                  ix: 8,
                  v: {
                    a: 0,
                    k: 0,
                    ix: 8,
                    x:
                      "var $bm_rt;\n$bm_rt = $bm_mul($bm_sub(time, inPoint), effect('Rotate Over Time')('Rotation (degrees/second)'));",
                  },
                },
                {
                  ty: 0,
                  nm: "Opacity",
                  mn: "ADBE Geometry2-0008",
                  ix: 9,
                  v: { a: 0, k: 100, ix: 9 },
                },
                {
                  ty: 7,
                  nm: "Use Composition’s Shutter Angle",
                  mn: "ADBE Geometry2-0009",
                  ix: 10,
                  v: { a: 0, k: 1, ix: 10 },
                },
                {
                  ty: 0,
                  nm: "Shutter Angle",
                  mn: "ADBE Geometry2-0010",
                  ix: 11,
                  v: { a: 0, k: 0, ix: 11 },
                },
                {
                  ty: 7,
                  nm: "Sampling",
                  mn: "ADBE Geometry2-0012",
                  ix: 12,
                  v: { a: 0, k: 1, ix: 12 },
                },
              ],
            },
          ],
          ip: 0,
          op: 904.000036820675,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 2,
          nm: "21",
          refId: "image_14",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 12,
                  s: [597.976, 133.976, 0],
                  to: [269.906, 36, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 46.271,
                  s: [1025.927, 569.927, 0],
                  to: [-44, 269.906, 0],
                  ti: [273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 80.434,
                  s: [589.976, 997.879, 0],
                  to: [-273.906, 0, 0],
                  ti: [40, 269.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 113.191,
                  s: [178.024, 589.927, 0],
                  to: [20, -257.906, 0],
                  ti: [-273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 148,
                  s: [597.976, 133.976, 0],
                  to: [269.906, 36, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 182.271,
                  s: [1025.927, 569.927, 0],
                  to: [-44, 269.906, 0],
                  ti: [273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 216.434,
                  s: [589.976, 997.879, 0],
                  to: [-273.906, 0, 0],
                  ti: [40, 269.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 249.191,
                  s: [178.024, 589.927, 0],
                  to: [20, -257.906, 0],
                  ti: [-273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 284,
                  s: [597.976, 133.976, 0],
                  to: [269.906, 36, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 319.111,
                  s: [1025.927, 569.927, 0],
                  to: [-44, 269.906, 0],
                  ti: [273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 354.113,
                  s: [589.976, 997.879, 0],
                  to: [-273.906, 0, 0],
                  ti: [40, 269.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 387.674,
                  s: [178.024, 589.927, 0],
                  to: [20, -257.906, 0],
                  ti: [-297.906, 24, 0],
                },
                { t: 420.000017106951, s: [601.976, 233.976, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [299, 395.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [12.935, 12.935, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ef: [
            {
              ty: 5,
              nm: "Rotate Over Time",
              np: 3,
              mn: "ADBE CM Spin",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 0,
                  nm: "Rotation (degrees/second)",
                  mn: "ADBE CM Spin-0001",
                  ix: 1,
                  v: { a: 0, k: 204, ix: 1 },
                },
              ],
            },
            {
              ty: 5,
              nm: "(Transform)",
              np: 14,
              mn: "ADBE Geometry2",
              ix: 2,
              en: 1,
              ef: [
                {
                  ty: 3,
                  nm: "Anchor Point",
                  mn: "ADBE Geometry2-0001",
                  ix: 1,
                  v: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x:
                      "var $bm_rt;\n$bm_rt = [\n    thisLayer.width / 2,\n    thisLayer.height / 2\n];",
                  },
                },
                {
                  ty: 3,
                  nm: "Position",
                  mn: "ADBE Geometry2-0002",
                  ix: 2,
                  v: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                    x:
                      "var $bm_rt;\n$bm_rt = [\n    thisLayer.width / 2,\n    thisLayer.height / 2\n];",
                  },
                },
                {
                  ty: 7,
                  nm: "Uniform Scale",
                  mn: "ADBE Geometry2-0011",
                  ix: 3,
                  v: { a: 0, k: 0, ix: 3 },
                },
                {
                  ty: 0,
                  nm: "Scale Height",
                  mn: "ADBE Geometry2-0003",
                  ix: 4,
                  v: { a: 0, k: 100, ix: 4 },
                },
                {
                  ty: 0,
                  nm: "Scale Width",
                  mn: "ADBE Geometry2-0004",
                  ix: 5,
                  v: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: 0,
                  nm: "Skew",
                  mn: "ADBE Geometry2-0005",
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 },
                },
                {
                  ty: 0,
                  nm: "Skew Axis",
                  mn: "ADBE Geometry2-0006",
                  ix: 7,
                  v: { a: 0, k: 0, ix: 7 },
                },
                {
                  ty: 0,
                  nm: "Rotation",
                  mn: "ADBE Geometry2-0007",
                  ix: 8,
                  v: {
                    a: 0,
                    k: 0,
                    ix: 8,
                    x:
                      "var $bm_rt;\n$bm_rt = $bm_mul($bm_sub(time, inPoint), effect('Rotate Over Time')('Rotation (degrees/second)'));",
                  },
                },
                {
                  ty: 0,
                  nm: "Opacity",
                  mn: "ADBE Geometry2-0008",
                  ix: 9,
                  v: { a: 0, k: 100, ix: 9 },
                },
                {
                  ty: 7,
                  nm: "Use Composition’s Shutter Angle",
                  mn: "ADBE Geometry2-0009",
                  ix: 10,
                  v: { a: 0, k: 1, ix: 10 },
                },
                {
                  ty: 0,
                  nm: "Shutter Angle",
                  mn: "ADBE Geometry2-0010",
                  ix: 11,
                  v: { a: 0, k: 0, ix: 11 },
                },
                {
                  ty: 7,
                  nm: "Sampling",
                  mn: "ADBE Geometry2-0012",
                  ix: 12,
                  v: { a: 0, k: 1, ix: 12 },
                },
              ],
            },
          ],
          ip: 0,
          op: 904.000036820675,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 2,
          nm: "54 copy",
          refId: "image_15",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 8,
                  s: [597.976, 97.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 42.75,
                  s: [1093.927, 593.927, 0],
                  to: [0, 273.906, 0],
                  ti: [273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 77.499,
                  s: [597.976, 1089.879, 0],
                  to: [-273.906, 0, 0],
                  ti: [0, 273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 112.249,
                  s: [102.024, 593.927, 0],
                  to: [0, -273.906, 0],
                  ti: [-273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 147,
                  s: [597.976, 97.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 181.75,
                  s: [1093.927, 593.927, 0],
                  to: [0, 273.906, 0],
                  ti: [273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 216.499,
                  s: [597.976, 1089.879, 0],
                  to: [-273.906, 0, 0],
                  ti: [0, 273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 251.249,
                  s: [102.024, 593.927, 0],
                  to: [0, -273.906, 0],
                  ti: [-273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 286,
                  s: [597.976, 97.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 319.161,
                  s: [1093.927, 593.927, 0],
                  to: [0, 273.906, 0],
                  ti: [273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 352.324,
                  s: [597.976, 1089.879, 0],
                  to: [-273.906, 0, 0],
                  ti: [0, 273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 385.486,
                  s: [102.024, 593.927, 0],
                  to: [0, -273.906, 0],
                  ti: [-389.906, -128, 0],
                },
                { t: 425.000017310605, s: [765.976, 161.976, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [299, 395.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [13.612, 13.612, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ef: [
            {
              ty: 5,
              nm: "Rotate Over Time",
              np: 3,
              mn: "ADBE CM Spin",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 0,
                  nm: "Rotation (degrees/second)",
                  mn: "ADBE CM Spin-0001",
                  ix: 1,
                  v: { a: 0, k: 101, ix: 1 },
                },
              ],
            },
            {
              ty: 5,
              nm: "(Transform)",
              np: 14,
              mn: "ADBE Geometry2",
              ix: 2,
              en: 1,
              ef: [
                {
                  ty: 3,
                  nm: "Anchor Point",
                  mn: "ADBE Geometry2-0001",
                  ix: 1,
                  v: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x:
                      "var $bm_rt;\n$bm_rt = [\n    thisLayer.width / 2,\n    thisLayer.height / 2\n];",
                  },
                },
                {
                  ty: 3,
                  nm: "Position",
                  mn: "ADBE Geometry2-0002",
                  ix: 2,
                  v: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                    x:
                      "var $bm_rt;\n$bm_rt = [\n    thisLayer.width / 2,\n    thisLayer.height / 2\n];",
                  },
                },
                {
                  ty: 7,
                  nm: "Uniform Scale",
                  mn: "ADBE Geometry2-0011",
                  ix: 3,
                  v: { a: 0, k: 0, ix: 3 },
                },
                {
                  ty: 0,
                  nm: "Scale Height",
                  mn: "ADBE Geometry2-0003",
                  ix: 4,
                  v: { a: 0, k: 100, ix: 4 },
                },
                {
                  ty: 0,
                  nm: "Scale Width",
                  mn: "ADBE Geometry2-0004",
                  ix: 5,
                  v: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: 0,
                  nm: "Skew",
                  mn: "ADBE Geometry2-0005",
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 },
                },
                {
                  ty: 0,
                  nm: "Skew Axis",
                  mn: "ADBE Geometry2-0006",
                  ix: 7,
                  v: { a: 0, k: 0, ix: 7 },
                },
                {
                  ty: 0,
                  nm: "Rotation",
                  mn: "ADBE Geometry2-0007",
                  ix: 8,
                  v: {
                    a: 0,
                    k: 0,
                    ix: 8,
                    x:
                      "var $bm_rt;\n$bm_rt = $bm_mul($bm_sub(time, inPoint), effect('Rotate Over Time')('Rotation (degrees/second)'));",
                  },
                },
                {
                  ty: 0,
                  nm: "Opacity",
                  mn: "ADBE Geometry2-0008",
                  ix: 9,
                  v: { a: 0, k: 100, ix: 9 },
                },
                {
                  ty: 7,
                  nm: "Use Composition’s Shutter Angle",
                  mn: "ADBE Geometry2-0009",
                  ix: 10,
                  v: { a: 0, k: 1, ix: 10 },
                },
                {
                  ty: 0,
                  nm: "Shutter Angle",
                  mn: "ADBE Geometry2-0010",
                  ix: 11,
                  v: { a: 0, k: 0, ix: 11 },
                },
                {
                  ty: 7,
                  nm: "Sampling",
                  mn: "ADBE Geometry2-0012",
                  ix: 12,
                  v: { a: 0, k: 1, ix: 12 },
                },
              ],
            },
          ],
          ip: 0,
          op: 904.000036820675,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 13,
          ty: 2,
          nm: "63 copy",
          refId: "image_16",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 4,
                  s: [597.976, 97.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 36.75,
                  s: [1093.927, 593.927, 0],
                  to: [0, 273.906, 0],
                  ti: [273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 69.499,
                  s: [597.976, 1089.879, 0],
                  to: [-273.906, 0, 0],
                  ti: [0, 273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 102.249,
                  s: [102.024, 593.927, 0],
                  to: [0, -273.906, 0],
                  ti: [-273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 135,
                  s: [597.976, 97.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 167.75,
                  s: [1093.927, 593.927, 0],
                  to: [0, 273.906, 0],
                  ti: [273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 200.499,
                  s: [597.976, 1089.879, 0],
                  to: [-273.906, 0, 0],
                  ti: [0, 273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 233.249,
                  s: [102.024, 593.927, 0],
                  to: [0, -273.906, 0],
                  ti: [-273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 266,
                  s: [597.976, 97.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 299.984,
                  s: [1093.927, 593.927, 0],
                  to: [0, 273.906, 0],
                  ti: [273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 333.969,
                  s: [597.976, 1089.879, 0],
                  to: [-273.906, 0, 0],
                  ti: [0, 273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 367.954,
                  s: [102.024, 593.927, 0],
                  to: [0, -273.906, 0],
                  ti: [-273.906, 0, 0],
                },
                { t: 397.000016170141, s: [521.976, 193.976, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [299, 395.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [13.612, 13.612, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ef: [
            {
              ty: 5,
              nm: "Rotate Over Time",
              np: 3,
              mn: "ADBE CM Spin",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 0,
                  nm: "Rotation (degrees/second)",
                  mn: "ADBE CM Spin-0001",
                  ix: 1,
                  v: { a: 0, k: -101, ix: 1 },
                },
              ],
            },
            {
              ty: 5,
              nm: "(Transform)",
              np: 14,
              mn: "ADBE Geometry2",
              ix: 2,
              en: 1,
              ef: [
                {
                  ty: 3,
                  nm: "Anchor Point",
                  mn: "ADBE Geometry2-0001",
                  ix: 1,
                  v: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x:
                      "var $bm_rt;\n$bm_rt = [\n    thisLayer.width / 2,\n    thisLayer.height / 2\n];",
                  },
                },
                {
                  ty: 3,
                  nm: "Position",
                  mn: "ADBE Geometry2-0002",
                  ix: 2,
                  v: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                    x:
                      "var $bm_rt;\n$bm_rt = [\n    thisLayer.width / 2,\n    thisLayer.height / 2\n];",
                  },
                },
                {
                  ty: 7,
                  nm: "Uniform Scale",
                  mn: "ADBE Geometry2-0011",
                  ix: 3,
                  v: { a: 0, k: 0, ix: 3 },
                },
                {
                  ty: 0,
                  nm: "Scale Height",
                  mn: "ADBE Geometry2-0003",
                  ix: 4,
                  v: { a: 0, k: 100, ix: 4 },
                },
                {
                  ty: 0,
                  nm: "Scale Width",
                  mn: "ADBE Geometry2-0004",
                  ix: 5,
                  v: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: 0,
                  nm: "Skew",
                  mn: "ADBE Geometry2-0005",
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 },
                },
                {
                  ty: 0,
                  nm: "Skew Axis",
                  mn: "ADBE Geometry2-0006",
                  ix: 7,
                  v: { a: 0, k: 0, ix: 7 },
                },
                {
                  ty: 0,
                  nm: "Rotation",
                  mn: "ADBE Geometry2-0007",
                  ix: 8,
                  v: {
                    a: 0,
                    k: 0,
                    ix: 8,
                    x:
                      "var $bm_rt;\n$bm_rt = $bm_mul($bm_sub(time, inPoint), effect('Rotate Over Time')('Rotation (degrees/second)'));",
                  },
                },
                {
                  ty: 0,
                  nm: "Opacity",
                  mn: "ADBE Geometry2-0008",
                  ix: 9,
                  v: { a: 0, k: 100, ix: 9 },
                },
                {
                  ty: 7,
                  nm: "Use Composition’s Shutter Angle",
                  mn: "ADBE Geometry2-0009",
                  ix: 10,
                  v: { a: 0, k: 1, ix: 10 },
                },
                {
                  ty: 0,
                  nm: "Shutter Angle",
                  mn: "ADBE Geometry2-0010",
                  ix: 11,
                  v: { a: 0, k: 0, ix: 11 },
                },
                {
                  ty: 7,
                  nm: "Sampling",
                  mn: "ADBE Geometry2-0012",
                  ix: 12,
                  v: { a: 0, k: 1, ix: 12 },
                },
              ],
            },
          ],
          ip: 0,
          op: 904.000036820675,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 14,
          ty: 2,
          nm: "90",
          refId: "image_17",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 2,
                  s: [597.976, 97.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 39,
                  s: [1093.927, 593.927, 0],
                  to: [0, 273.906, 0],
                  ti: [273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 75.999,
                  s: [597.976, 1089.879, 0],
                  to: [-273.906, 0, 0],
                  ti: [0, 273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 112.999,
                  s: [102.024, 593.927, 0],
                  to: [0, -273.906, 0],
                  ti: [-273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 150,
                  s: [597.976, 97.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 187,
                  s: [1093.927, 593.927, 0],
                  to: [0, 273.906, 0],
                  ti: [273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 223.999,
                  s: [597.976, 1089.879, 0],
                  to: [-273.906, 0, 0],
                  ti: [0, 273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 260.999,
                  s: [102.024, 593.927, 0],
                  to: [0, -273.906, 0],
                  ti: [-273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 298,
                  s: [597.976, 97.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 332.258,
                  s: [1093.927, 593.927, 0],
                  to: [0, 273.906, 0],
                  ti: [273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 366.515,
                  s: [597.976, 1089.879, 0],
                  to: [-273.906, 0, 0],
                  ti: [0, 273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 400.773,
                  s: [102.024, 593.927, 0],
                  to: [0, -273.906, 0],
                  ti: [-521.906, -108, 0],
                },
                { t: 446.000018165952, s: [841.976, 113.976, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [299, 395.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [14.554, 14.554, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ef: [
            {
              ty: 5,
              nm: "Rotate Over Time",
              np: 3,
              mn: "ADBE CM Spin",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 0,
                  nm: "Rotation (degrees/second)",
                  mn: "ADBE CM Spin-0001",
                  ix: 1,
                  v: { a: 0, k: 142, ix: 1 },
                },
              ],
            },
            {
              ty: 5,
              nm: "(Transform)",
              np: 14,
              mn: "ADBE Geometry2",
              ix: 2,
              en: 1,
              ef: [
                {
                  ty: 3,
                  nm: "Anchor Point",
                  mn: "ADBE Geometry2-0001",
                  ix: 1,
                  v: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x:
                      "var $bm_rt;\n$bm_rt = [\n    thisLayer.width / 2,\n    thisLayer.height / 2\n];",
                  },
                },
                {
                  ty: 3,
                  nm: "Position",
                  mn: "ADBE Geometry2-0002",
                  ix: 2,
                  v: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                    x:
                      "var $bm_rt;\n$bm_rt = [\n    thisLayer.width / 2,\n    thisLayer.height / 2\n];",
                  },
                },
                {
                  ty: 7,
                  nm: "Uniform Scale",
                  mn: "ADBE Geometry2-0011",
                  ix: 3,
                  v: { a: 0, k: 0, ix: 3 },
                },
                {
                  ty: 0,
                  nm: "Scale Height",
                  mn: "ADBE Geometry2-0003",
                  ix: 4,
                  v: { a: 0, k: 100, ix: 4 },
                },
                {
                  ty: 0,
                  nm: "Scale Width",
                  mn: "ADBE Geometry2-0004",
                  ix: 5,
                  v: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: 0,
                  nm: "Skew",
                  mn: "ADBE Geometry2-0005",
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 },
                },
                {
                  ty: 0,
                  nm: "Skew Axis",
                  mn: "ADBE Geometry2-0006",
                  ix: 7,
                  v: { a: 0, k: 0, ix: 7 },
                },
                {
                  ty: 0,
                  nm: "Rotation",
                  mn: "ADBE Geometry2-0007",
                  ix: 8,
                  v: {
                    a: 0,
                    k: 0,
                    ix: 8,
                    x:
                      "var $bm_rt;\n$bm_rt = $bm_mul($bm_sub(time, inPoint), effect('Rotate Over Time')('Rotation (degrees/second)'));",
                  },
                },
                {
                  ty: 0,
                  nm: "Opacity",
                  mn: "ADBE Geometry2-0008",
                  ix: 9,
                  v: { a: 0, k: 100, ix: 9 },
                },
                {
                  ty: 7,
                  nm: "Use Composition’s Shutter Angle",
                  mn: "ADBE Geometry2-0009",
                  ix: 10,
                  v: { a: 0, k: 1, ix: 10 },
                },
                {
                  ty: 0,
                  nm: "Shutter Angle",
                  mn: "ADBE Geometry2-0010",
                  ix: 11,
                  v: { a: 0, k: 0, ix: 11 },
                },
                {
                  ty: 7,
                  nm: "Sampling",
                  mn: "ADBE Geometry2-0012",
                  ix: 12,
                  v: { a: 0, k: 1, ix: 12 },
                },
              ],
            },
          ],
          ip: 0,
          op: 904.000036820675,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 15,
          ty: 2,
          nm: "3",
          refId: "image_18",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 21,
                  s: [597.976, 97.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 53.25,
                  s: [1093.927, 593.927, 0],
                  to: [0, 273.906, 0],
                  ti: [273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 85.499,
                  s: [597.976, 1089.879, 0],
                  to: [-273.906, 0, 0],
                  ti: [0, 273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 117.749,
                  s: [102.024, 593.927, 0],
                  to: [0, -273.906, 0],
                  ti: [-273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 150,
                  s: [597.976, 97.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 182.25,
                  s: [1093.927, 593.927, 0],
                  to: [0, 273.906, 0],
                  ti: [273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 214.499,
                  s: [597.976, 1089.879, 0],
                  to: [-273.906, 0, 0],
                  ti: [0, 273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 246.749,
                  s: [102.024, 593.927, 0],
                  to: [0, -273.906, 0],
                  ti: [-273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 279,
                  s: [597.976, 97.976, 0],
                  to: [273.906, 0, 0],
                  ti: [0, -273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 309.844,
                  s: [1093.927, 593.927, 0],
                  to: [0, 273.906, 0],
                  ti: [273.906, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 340.689,
                  s: [597.976, 1089.879, 0],
                  to: [-273.906, 0, 0],
                  ti: [0, 273.906, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 371.534,
                  s: [102.024, 593.927, 0],
                  to: [0, -273.906, 0],
                  ti: [-297.906, -200, 0],
                },
                { t: 408.000016618181, s: [777.976, 225.976, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [299, 395.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [13.612, 13.612, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ef: [
            {
              ty: 5,
              nm: "Rotate Over Time",
              np: 3,
              mn: "ADBE CM Spin",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 0,
                  nm: "Rotation (degrees/second)",
                  mn: "ADBE CM Spin-0001",
                  ix: 1,
                  v: { a: 0, k: 241, ix: 1 },
                },
              ],
            },
            {
              ty: 5,
              nm: "(Transform)",
              np: 14,
              mn: "ADBE Geometry2",
              ix: 2,
              en: 1,
              ef: [
                {
                  ty: 3,
                  nm: "Anchor Point",
                  mn: "ADBE Geometry2-0001",
                  ix: 1,
                  v: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                    x:
                      "var $bm_rt;\n$bm_rt = [\n    thisLayer.width / 2,\n    thisLayer.height / 2\n];",
                  },
                },
                {
                  ty: 3,
                  nm: "Position",
                  mn: "ADBE Geometry2-0002",
                  ix: 2,
                  v: {
                    a: 0,
                    k: [0, 0],
                    ix: 2,
                    x:
                      "var $bm_rt;\n$bm_rt = [\n    thisLayer.width / 2,\n    thisLayer.height / 2\n];",
                  },
                },
                {
                  ty: 7,
                  nm: "Uniform Scale",
                  mn: "ADBE Geometry2-0011",
                  ix: 3,
                  v: { a: 0, k: 0, ix: 3 },
                },
                {
                  ty: 0,
                  nm: "Scale Height",
                  mn: "ADBE Geometry2-0003",
                  ix: 4,
                  v: { a: 0, k: 100, ix: 4 },
                },
                {
                  ty: 0,
                  nm: "Scale Width",
                  mn: "ADBE Geometry2-0004",
                  ix: 5,
                  v: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: 0,
                  nm: "Skew",
                  mn: "ADBE Geometry2-0005",
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 },
                },
                {
                  ty: 0,
                  nm: "Skew Axis",
                  mn: "ADBE Geometry2-0006",
                  ix: 7,
                  v: { a: 0, k: 0, ix: 7 },
                },
                {
                  ty: 0,
                  nm: "Rotation",
                  mn: "ADBE Geometry2-0007",
                  ix: 8,
                  v: {
                    a: 0,
                    k: 0,
                    ix: 8,
                    x:
                      "var $bm_rt;\n$bm_rt = $bm_mul($bm_sub(time, inPoint), effect('Rotate Over Time')('Rotation (degrees/second)'));",
                  },
                },
                {
                  ty: 0,
                  nm: "Opacity",
                  mn: "ADBE Geometry2-0008",
                  ix: 9,
                  v: { a: 0, k: 100, ix: 9 },
                },
                {
                  ty: 7,
                  nm: "Use Composition’s Shutter Angle",
                  mn: "ADBE Geometry2-0009",
                  ix: 10,
                  v: { a: 0, k: 1, ix: 10 },
                },
                {
                  ty: 0,
                  nm: "Shutter Angle",
                  mn: "ADBE Geometry2-0010",
                  ix: 11,
                  v: { a: 0, k: 0, ix: 11 },
                },
                {
                  ty: 7,
                  nm: "Sampling",
                  mn: "ADBE Geometry2-0012",
                  ix: 12,
                  v: { a: 0, k: 1, ix: 12 },
                },
              ],
            },
          ],
          ip: 0,
          op: 904.000036820675,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  fonts: {
    list: [
      {
        fName: "Aller-Bold",
        fFamily: "Aller",
        fStyle: "Bold",
        ascent: 74.1836547851563,
      },
    ],
  },
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: "hand",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0, y: 0 },
              t: 188,
              s: [3132, 784, 0],
              to: [0, -9.333, 0],
              ti: [206, 9.333, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.167, y: 0.167 },
              t: 217,
              s: [3132, 728, 0],
              to: [-206, -9.333, 0],
              ti: [206, 0, 0],
            },
            { t: 239.00000973467, s: [1896, 728, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [1177, 576.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 2354,
      h: 1153,
      ip: 0,
      op: 300.00001221925,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: "Neon-tablo.png",
      cl: "png",
      refId: "image_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [952, 104, 0], ix: 2, l: 2 },
        a: { a: 0, k: [567, 385.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [36.508, 36.508, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 300.00001221925,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: "lights",
      refId: "image_2",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [960, 114.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [965, 367.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 300.00001221925,
      st: 0,
      bm: 2,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: "shishe roo",
      refId: "image_3",
      sr: 1,
      ks: {
        o: { a: 0, k: 47.843, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [958, 538, 0], ix: 2, l: 2 },
        a: { a: 0, k: [317, 317, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 300.00001221925,
      st: 0,
      bm: 5,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 0,
      nm: "Daberna-balls",
      refId: "comp_1",
      sr: 0.5,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 180, ix: 10 },
        p: { a: 0, k: [960.826, 543.393, 0], ix: 2, l: 2 },
        a: { a: 0, k: [600, 600, 0], ix: 1, l: 2 },
        s: { a: 0, k: [51.584, 51.584, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1200,
      h: 1200,
      ip: 0,
      op: 450.000018328876,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 2,
      nm: "glass shine",
      refId: "image_19",
      sr: 1,
      ks: {
        o: { a: 0, k: 61.961, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 3,
              s: [0],
            },
            { t: 213.000008675668, s: [1800] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [960.5, 536, 0], ix: 2, l: 2 },
        a: { a: 0, k: [299, 296.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [103.339, 103.339, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 300.00001221925,
      st: 0,
      bm: 8,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 2,
      nm: "standhandle",
      refId: "image_20",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            { t: 215.00000875713, s: [1800] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [959.5, 545.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [290.5, 40.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 300.00001221925,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 2,
      nm: "stand",
      refId: "image_21",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            { t: 211.000008594206, s: [1440] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [957, 536, 0], ix: 2, l: 2 },
        a: { a: 0, k: [344.497, 342, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 300.00001221925,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 2,
      nm: "winnerball",
      refId: "image_22",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 80, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 211,
              s: [957.5, 804, 0],
              to: [-2, 17.167, 0],
              ti: [2, -17.167, 0],
            },
            { t: 229.000009327361, s: [945.5, 907, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [40.5, 53.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [48.148, 48.148, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 210.000008553475,
      op: 300.00001221925,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 2,
      nm: "payeh.png",
      cl: "png",
      refId: "image_23",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [962, 802, 0], ix: 2, l: 2 },
        a: { a: 0, k: [380, 248.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 300.00001221925,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 2,
      nm: "bg",
      refId: "image_24",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [960, 390.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [1021, 790, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 300.00001221925,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
  chars:number2||number2==0?  [
    
    numbers[number],
    numbers[number2]
  ]:[
     numbers[number]
  ],
})
}
export default Data

