import React  from "react";
import Img from "../../../deberna-game/checked.png";
 


const Card=(props)=> {
 
    const { data, selected,  changeCountCardSelected} = props ;
    const [selectedItem,setSelectedItem]=React.useState(selected)

    return (
      <>
         <div
          className={selectedItem ? "item selected" : "item clickable"}
          style={{ direction: "ltr" }}
           
        >
          {selectedItem ? <img src={Img} className="checked" alt="" /> : null}
          {/* ==== */}
          <div className="deberna_card_table ">
            {data  && data.length
              ? data.map((row) => (
                  <div className="display_flex">
                    {row && row.length
                      ? row.map((number) => {
                          
                          return (
                            <span style={{ color : "#2f2f2f" }}>
                              {number ? number : ""}
                            </span>
                          );
                        })
                      : ""}
                  </div>
                ))
              : ""}
          </div>
        </div>
      </>
    );
  
}
 

 
export default  Card
 
