import React from "react";

export default () => (
  <svg
    viewBox="0 0 455.431 455.431">
    <path style={{ 'fill': '#8DC640' }} d="M405.493,412.764c-69.689,56.889-287.289,56.889-355.556,0c-69.689-56.889-62.578-300.089,0-364.089
	s292.978-64,355.556,0S475.182,355.876,405.493,412.764z"/>
    <g style={{ 'opacity': '0.2' }}>
      <path style={{ 'fill': '#FFFFFF' }} d="M229.138,313.209c-62.578,49.778-132.267,75.378-197.689,76.8
		c-48.356-82.489-38.4-283.022,18.489-341.333c51.2-52.622,211.911-62.578,304.356-29.867
		C377.049,112.676,330.116,232.142,229.138,313.209z"/>
    </g>
    <path style={{ 'fill': '#FFFFFF' }} d="M195.004,354.453c-9.956,0-19.911-4.267-25.6-12.8l-79.644-102.4
	c-11.378-14.222-8.533-34.133,5.689-45.511s34.133-8.533,45.511,5.689l54.044,69.689l119.467-155.022
	c11.378-14.222,31.289-17.067,45.511-5.689s17.067,31.289,5.689,45.511L220.604,341.653
	C213.493,348.764,204.96,354.453,195.004,354.453z"/>
  </svg>
);
