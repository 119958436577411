import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Dialog, IconButton } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
  
import AddIcon from "@material-ui/icons/Add";
import { isMobile } from "react-device-detect";
import OwlCarousel from "react-owl-carousel2";
import "../../../bingoLive2/index.css";
import "../../../deberna-game/style.css";

 
let options = {
  center: false,
  items: 3,
  dots: false,
  nav: true,
  rewind: false,
  rtl: true,
  loop: false,
  margin: 5,
  startPosition: 5,
  autoplay: false,
  smartSpeed: 600,
  responsiveClass: true,
  responsive: {
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    640: {
      items: 2,
    },
    1025: {
      items: 2,
    },
  },
};

 

const DialogRejectPay = (props) => { 
  const [listTicket,setListTicket]=React.useState([])
  const { 
    tickets,
  } = props;

  const ticketSelector = (id) => {};
  
  useEffect(()=>{
 
    if(tickets){  
      setListTicket(tickets)
    //   sortList()
    }
  },[tickets])

  const sortList= async()=>{
    let this3 = [];
    const newArray = [];
    
    await tickets.forEach((ticket, i) => {
      if ((i + 1) % 3 !== 0) {
        this3.push(ticket);
        if (i + 1 === tickets.length) {
          newArray.push(this3);
          this3 = [];
        }
      }
      if ((i + 1) % 3 === 0) {
        this3.push(ticket);
        newArray.push(this3);
        this3 = [];
      }
    });  
  setListTicket(newArray)
  }
  return (
    
    <Grid
    xs={12}
    container
    direction="row"
    justify="center"
    alignContent="center" 
  >
 
 <Grid item xs={12}>
      <div
        className="  deberna_cards_panel deberna_groupbox_slider pos-r dialog_sotry"
        style={{ background: "none",paddingBottom:"0px !important",marginBottom:0 }}
      > 
           
            {listTicket.length>0   && listTicket.map((rowTicket) => (
              <div  className={`phone_numbers_new click_false livestream`} key={Math.random()}
              
              style={{marginTop:10}}>
                {rowTicket && rowTicket.length
                  ? rowTicket.map((ticket,i) => {
                      return (
                        <button
                        
                       >
                         <span className="num">{ rowTicket[i] || ""}</span>
                       </button>
                      );
                    })
                  : ""}
              </div>
            ))}
         
      </div>
    </Grid>

    
  </Grid>

  );
};
DialogRejectPay.propTypes = {
  isResult: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  withdrawal_Id: PropTypes.number.isRequired,
  is_User_Banned: PropTypes.bool.isRequired,
};

export default DialogRejectPay;
//export default connect(null, { updateUserDetails })(DialogRejectPay);
