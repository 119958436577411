import React, { Component } from "react";

// API

// Components
import { NavLink } from "react-router-dom";

import Layout from "../../components/layout/main";
import Menu from "./menu.js";
import ProfileCard from "./profile_card.js";
import Title from "./title";
import { user } from "../../Api";
import { interpreter } from "../../utills";
import { Helmet } from "react-helmet";

class Dashboard extends Component {
  state = { Me: "" };
  componentDidMount() {
    this.getMe();
  }

  getMe = async () => {
    const { data } = await user.Current();
    await this.setState({ Me: data });
  };

  handleSubmit = async (name, value) => {
    const viewModel = {
      userMobile: this.state.Me.userMobile,
      currentPassword: "",
      newPassword1: "",
      newPassword2: "",
    };
    await user.editProfile(viewModel);
  };
  handleChange = (name, value) => {
    this.setState({ Me: { ...this.state.Me, [name]: value } });
  };
  render() {
    const { Me } = this.state;
    return (
      <div className="">
        <Helmet>
          <title> {interpreter("Profile")}</title>
        </Helmet>
        {/* breadcrumb */}
        <div className="row">
          <div className="large-3 medium-4 columns">
            <div className="dashboard_sidebar">
              <ProfileCard />
              <Menu />
            </div>
          </div>

          {/* start content */}
          <div className="large-9 medium-8 columns">
            <div className="dashboard_panel">
              <Title title={interpreter("Profile")} icon="home" />

              {/* اگر اهراز هویت انجام نشده بود این پیغام بهش نمایش داده شود */}
              {!Me.userMobileHasVerified && (
                <div className="callout warning ">
                  <div>{interpreter("Warning")}</div>
                  <p>{interpreter("warningAuthentication")}</p>
                  <NavLink to="/dashboard-authentication">
                    {interpreter("Authentication")}
                  </NavLink>
                </div>
              )}

              <br />
              <br />
              {/*  */}

              {/* ==== */}
              <label>{interpreter("nameAndFamilyName")}</label>
              <div className="form_control">
                <input
                  type="text"
                  placeholder={interpreter("nameAndFamilyName")}
                  value={Me.userName}
                  disabled
                />
              </div>
              <label>{interpreter("email")}</label>
              <div className="form_control">
                <input
                  type="text"
                  placeholder={interpreter("email")}
                  value={Me.userMail}
                  disabled
                />
              </div>
              <label>{interpreter("username")}</label>
              <div className="form_control">
                <input
                  type="text"
                  placeholder={interpreter("userName")}
                  value={Me.user_username}
                  disabled
                />
              </div>
              <label>{interpreter("mobileNumer")}</label>
              <div className="form_control">
                <input
                  type="text"
                  placeholder={interpreter("mobileNumer")}
                  value={Me.userMobile}
                  name="userMobile"
                  onChange={({ target }) => this.handleChange(target.name, target.value)}
                />
              </div>
              <div className="text-left">
                <button
                  type="button"
                  onClick={() => this.handleSubmit()}
                  className="button confrim"
                >
                  {interpreter("updateInfo")}
                </button>
              </div>
              {/* // end === */}
            </div>
          </div>
          {/* // end content */}
        </div>
      </div>
    );
  }
}
export default Layout(Dashboard);
