import React, { Component } from "react";
import { useHistory, Redirect } from "react-router-dom";

// API
import { liveBingo } from "../../Api";
import Header from "../../components/layout/header-main";
// Components
import { Helmet } from "react-helmet";
import ReactPlayer from "react-player";
import {
  getLongDate,
  interpreter,
  localStorgeSubscriber,
  numberWithCommas,
  toJalaliHour,
} from "../../utills";
import ReactHtmlParser from "react-html-parser";
import Layout from "../../components/layout/main";
import CountDownView from "../../components/CountDown";
import Footer from "../../components/layout/footer";
import { completeCountDownAction } from "../../redux/actions";
import { connect } from "react-redux";
// Style

import "./index.css";

import Countdown from "react-countdown";
import TabLayout from "./TabLayout";

const LiveBingo = ({ completeCountDownAction }) => {
  let History = useHistory();
  const [details, setDetails] = React.useState({});
  const [isStart, setIsStart] = React.useState(false);
  React.useEffect(() => {
    RequestDetails(false);

  }, []);

  const RequestDetails = async (isLoop) => {
    try {

      let { data } = await liveBingo.bingoDetails();

      if (data.success) {
        sessionStorage.setItem(
          "live_url_video",
          data.tournament.liveStreamDabernaUrl
        );
        sessionStorage.setItem(
          "liveStreamStatus",
          data.tournament.liveStreamStatus
        );
        sessionStorage.setItem(
          "piceCard",
          data.tournament.Price
        );

        if (data.tournament.IsStarted) {
          //     setIsStart(true)

          setTimeout(() => {
            if (History)
              History.push("/bingoLiveStarted");
          }, 100);
        } else {
          if (!isLoop) {
            completeCountDownAction(false)
            setDetails(data.tournament);
          } else {

            completeCountDownAction(true)
            setTimeout(() => {
              RequestDetails(true)
            }, 10000);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // getCurrentNumbers = async () => {
  //   try {
  //     this.interval = setInterval(async () => {
  //       const { data } = await basic.getcurrentDrawNumbers();
  //       this.setState({ Numbers: JSON.parse(data.currentDrawNumbers) });
  //     }, 3000);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const completeCountDown = () => {
    RequestDetails(true)
  };

  return (
    <>


      <Header color="red" />

      <div>
        <div className="row  mb-30 bingo_live max-height">
          <br />
          <br />
          <div className="row">
            <div className="medium-4 column">
              <div className="total_countering black">
                <div className="title">
                  <span>{interpreter("ticketPrices")}</span>
                </div>
                <div className="value">{`${numberWithCommas(
                  details.Price
                )} ${interpreter("Toman")}`}</div>
              </div>
            </div>
            <div className="medium-4 column">
              <div className="total_countering black">
                <div className="title">
                  <span>{interpreter("jackpotAmount")}</span>
                </div>
                <div className="value">{`${numberWithCommas(
                  details.Prize
                )} ${interpreter("Toman")}`}</div>
              </div>
            </div>
            <div className="medium-4 column">
              <div className="total_countering black">
                <div className="title">
                  <span>{interpreter("tournamentName")}</span>
                </div>
                <div className="value">{details.tournament_name}</div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="countDown">
              <div className="countdown-wrapper">
                {details.StartsAt_Raw && (
                  <Countdown
                    ///date={Date.now() + 5000}
                    //   date={details.StartsAt_Raw *1000}
                    date={getLongDate(details.StartsAt_gregorian)}
                    intervalDelay={0}
                    precision={3}
                    // renderer={this.renderer}
                    renderer={CountDownView}
                    onComplete={completeCountDown}
                  />
                )}
              </div>
            </div>
          </div>
        </div>


        {details.hasOwnProperty('IsStarted') && !details.IsStarted && <div className="row mb-50 center_tab">
          <TabLayout />
        </div>}
      </div>
      <Footer red />
    </>
  );
};
export default connect(null, { completeCountDownAction })(LiveBingo)
