import {
    COUNT_CARD_SELECTED,SHOW_DETIALS_CARD,
    COUNT_BOX_6_CARD_SELECTED,BOX_6_CARD_IS_SELECTED,
    COMPLETE_COUNT_DOWN_LIVE,
    DRAW_NUMBERS,
    DRAW_NUMBERS_CARDS
} from '../actionTypes'


const infoState = {
    countCardSelected: 0, 
    countBox6CardSelected: 0,
    box6CardIsSelected: -1,
    card:null,
    isComplete:false,
    drawNumber:[],
    cardsShow:-1,

};

const BingoLiveReducer = (state = infoState, action) => {

 
    switch (action.type) {

        case BOX_6_CARD_IS_SELECTED: {
            const { 
                isSelected
                
            } = action.payload;
            return { 
                box6CardIsSelected:isSelected,
                card:state.card,
                countBox6CardSelected:state.countBox6CardSelected,
                countCardSelected:state.countCardSelected,
                isComplete:state.isComplete,
              
            };
        }


        case COUNT_CARD_SELECTED: {
            const { 
                increaseCard
                
            } = action.payload;
            return { 
                countCardSelected:increaseCard===-1?0:(increaseCard?state.countCardSelected+1:state.countCardSelected-1 ), 
                card:state.card,
                countBox6CardSelected:state.countBox6CardSelected,
                box6CardIsSelected:state.box6CardIsSelected,
                isComplete:state.isComplete,
            };
        }
        case COUNT_BOX_6_CARD_SELECTED: {
            const { 
                increaseCard
                
            } = action.payload;
            return { 
                countBox6CardSelected:increaseCard===-1?0:increaseCard?state.countBox6CardSelected+1:state.countBox6CardSelected-1,  
                card:state.card,
                countCardSelected:state.countCardSelected,
                box6CardIsSelected:state.box6CardIsSelected,
                isComplete:state.isComplete,
            };
        }
        case SHOW_DETIALS_CARD: {
            const { 
                card
                
            } = action.payload;
            return { 
                countCardSelected: state.countCardSelected , 
                card: card,
                countBox6CardSelected:state.countBox6CardSelected,
                box6CardIsSelected:state.box6CardIsSelected,
                isComplete:state.isComplete,
            };
        }
        case COMPLETE_COUNT_DOWN_LIVE: {
            const { 
                isComplete
                
            } = action.payload;
            return { 
                countCardSelected: state.countCardSelected , 
                card:  state.card ,
                countBox6CardSelected:state.countBox6CardSelected,
                box6CardIsSelected:state.box6CardIsSelected,
                isComplete: isComplete,
            };
        }

        case DRAW_NUMBERS: {
            const { 
                numbers
                
            } = action.payload;
            return { 
               ...state,
               drawNumber: numbers,
            };
        }
        case DRAW_NUMBERS_CARDS: {
            const { 
                cardsShow
                
            } = action.payload;
            return { 
               ...state,
               cardsShow: cardsShow,
            };
        }
        
        default: {
            return state;
        }
    }

}

export default BingoLiveReducer;