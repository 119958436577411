import { APIClient } from "./_api";

const baseUrl = "/user";
export function register(values) {
  let bodyFormData = new FormData();
  for (let key in values) {
    bodyFormData.append(key, values[key]);
  }

  return APIClient.post(`${baseUrl}/register/`, bodyFormData);
}

export function forgetPass(values) {
  let bodyFormData = new FormData();
  for (let key in values) {
    bodyFormData.append(key, values[key]);
  }

  return APIClient.post(`${baseUrl}/forgetPassword/`, bodyFormData);
}

export function login(values) {
  let bodyFormData = new FormData();
  for (let key in values) {
    bodyFormData.append(key, values[key]);
  }

  return APIClient.post(`${baseUrl}/login/`, bodyFormData);
}

export function Current() {
  let bodyFormData = new FormData();
  let userAuth = localStorage.getItem("userAuth");

  bodyFormData.append("userAuth", userAuth || "");

  return APIClient.post(`${baseUrl}/current/`, bodyFormData);
}
export function logOut() {
  let bodyFormData = new FormData();
  let userAuth = localStorage.getItem("userAuth");

  bodyFormData.append("userAuth", userAuth || "");
  return APIClient.post(`${baseUrl}/logout/`, bodyFormData);
}

export function smsVerification(values) {
  let bodyFormData = new FormData();
  let userAuth = localStorage.getItem("userAuth");
  for (let key in values) {
    bodyFormData.append(key, values[key]);
  }
  bodyFormData.append("userAuth", userAuth || "");

  return APIClient.post(`${baseUrl}/smsVerification/`, bodyFormData);
}
export function editProfile(values) {
  let bodyFormData = new FormData();

  let userAuth = localStorage.getItem("userAuth");

  for (let key in values) {
    bodyFormData.append(key, values[key]);
  }
  bodyFormData.append("userAuth", userAuth || "");

  return APIClient.post(`${baseUrl}/updateInfo/`, bodyFormData);
}

export function cardAuthentication(values) {
  let bodyFormData = new FormData();
  let userAuth = localStorage.getItem("userAuth");
  for (let key in values) {
    bodyFormData.append(key, values[key]);
  }

  bodyFormData.append("userAuth", userAuth || "");
  return APIClient.post(
    `${baseUrl}/authentication/cardAuthentication/`,
    bodyFormData
  );
}
export function identityAuthentication(values) {
  let bodyFormData = new FormData();

  let userAuth = localStorage.getItem("userAuth");

  for (let key in values) {
    bodyFormData.append(key, values[key]);
  }

  bodyFormData.append("userAuth", userAuth || "");
  return APIClient.post(
    `${baseUrl}/authentication/identityAuthentication/`,
    bodyFormData
  );
}

export function getMessage(values) {
  let bodyFormData = new FormData();
  let userAuth = localStorage.getItem("userAuth");
  bodyFormData.append("userAuth", userAuth || "");
  return APIClient.post(`${baseUrl}/messages/`, bodyFormData);
}

export function getTransaction(page = 1) {
  let bodyFormData = new FormData();
  let userAuth = localStorage.getItem("userAuth");
  bodyFormData.append("userAuth", userAuth || "");
  return APIClient.post(`${baseUrl}/transactions/page/${page}`, bodyFormData);
}

export function winnedTournaments(page = 1) {
  let bodyFormData = new FormData();
  let userAuth = localStorage.getItem("userAuth");
  bodyFormData.append("userAuth", userAuth || "");
  return APIClient.post(
    `${baseUrl}/winnedLottoTournaments/page/${page}`,
    bodyFormData
  );
}

export function winnedDebernaTournaments(page = 1) {
  let bodyFormData = new FormData();
  let userAuth = localStorage.getItem("userAuth");
  bodyFormData.append("userAuth", userAuth || "");
  return APIClient.post(
    `${baseUrl}/winnedDabernaTournaments/page/${page}`,
    bodyFormData
  );
}

export function winnedDebernaOnlineTournaments(page = 1) {
  let bodyFormData = new FormData();
  let userAuth = localStorage.getItem("userAuth");
  bodyFormData.append("userAuth", userAuth || "");
  return APIClient.post(
    `${baseUrl}/winnedLiveDabernaTournaments/page/${page}`,
    bodyFormData
  );
}
