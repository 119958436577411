import React, { Component } from "react";

// API
import { user } from "../../Api";
import { baseURL } from "../../Api/_api";

// components
import { interpreter } from "../../utills";
import Layout from "../../components/layout/main";
import { Formik, Field, Form } from "formik";
import { Link, Redirect, } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useState } from "react";
import { useEffect } from "react";
const initialValues = {
  login: "",
  password: "",
  captcha: "",
  type: "",
}
const Login = () => {

  const [imageRepeat, setImageRepeat] = useState()
  const [navigate, setNavigate] = useState({
    Redirect: false,
    returnAddress: "/"
  })

  // state = { Redirect: false, imageRepeat: "", returnAddress: "" };
  // initialValues = {
  //   login: "",
  //   password: "",
  //   captcha: "",
  //   type: "",
  // };

  useEffect(() => {


    imageRepeater();
    // const returnAddress = localStorage.getItem("returnPath");
    // this.setState({ returnAddress: returnAddress ? returnAddress : "" });
  }, [])


  const handleSubmit = async (values, action) => {
    try {
      const viewModel = await { ...values, type: values.type ? 2 : 1 };

      const { data } = await user.login(viewModel);
      if (data.success) {
        localStorage.setItem("userAuth", data.userAuth);
        const returnAddress = localStorage.getItem("returnPath");
        setNavigate({ Redirect: true, returnAddress: returnAddress ? returnAddress : navigate.returnAddress })
      }
      imageRepeater();
    } catch (error) {
      console.error(error);
    }
  };
  const imageRepeater = () => {


    setImageRepeat(`${baseURL}/captcha/?_${Math.floor(
      Math.random() * 10000000
    )}`)

  };




  return (
    <div>
      <Helmet>
        <title> {interpreter("loginButton")}</title>
      </Helmet>
      {navigate.Redirect && (
        <Redirect to={navigate.returnAddress} />
      )}

      <div className="row columns">
        <div className="large-8 medium-12 small-12 float-center ">
          <div className="authentication_wrapper">
            <div className="image_box">
              {/* <div className="text">{interpreter("loginButton")}</div> */}
            </div>
            <div className="content_box formLogin">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}


              >

                {(props) => (
                  <Form onSubmitCapture={props.handleSubmit}>
                    <div className="form_control">
                      <Field
                        name="login"

                        placeholder={interpreter("mobileNumberOrEmail")}
                        type="text"
                      />
                    </div>
                    <div className="form_control">
                      <Field
                        name="password"
                        placeholder={interpreter("password")}
                        type="password"
                      />
                    </div>
                    <div className="form_control captcha">
                      <Field
                        name="captcha"
                        placeholder={interpreter("captcha")}
                        type="text"

                      />
                      <img src={imageRepeat} alt="" />
                    </div>
                    <div
                      className="display_flex align_items_center pointer"
                      onClick={imageRepeater}
                    >
                      <span className="pe-refresh"></span>&nbsp;
                      {interpreter("refreshCode")}
                    </div>
                    <br />
                    <label className="remember_pass">
                      <Field name="type" type="checkbox" />
                      &nbsp;{interpreter("rememberMe")}
                    </label>

                    <div className="text-left">
                      <button className="button" type="submit">
                        {interpreter("loginButton")}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
              <div>
                <Link to="/forget-pass" className="forget_pass_link">
                  {interpreter("forgetYourPassword")}
                </Link>
                <br />
                <label>
                  <Link to="/register">
                    {interpreter("doYouWantRegister")}
                  </Link>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layout(Login);
