import React from 'react'

import { getBingoLive } from "../../../../redux/selectors";
import { connect } from "react-redux";

const Card=(props)=>{

 const { eachTicket, selected, drawNumber } = props;
    return(
  <div
                          className="deberna_card_table "
                          style={{ direction: "ltr" }}
                          key={ eachTicket.key}
                        >
                          {eachTicket &&
                          eachTicket.value   
                            ? Object.values(eachTicket.value).map((ArrayNumber) => (
                                
                                <div className="display_flex">
                                  {Object.values(ArrayNumber).map((number) => {
                                const isExsit =drawNumber&& drawNumber.find((x) => Number(number) === Number(x))

                            return(
                                    <span style={{ color: isExsit ? "red" : "#2f2f2f" }}>
                                      {number ? number : ""}
                                      
                                    </span>
                                 )
                      
                                  })}
                                </div>
                              ))
                            : ""}
                        </div>
    )
} 


const mapStateToProps = (state) => {
  const todos = getBingoLive(state);
  let drawNumber = todos.drawNumber;
  return { drawNumber };
};

export default connect(mapStateToProps)(Card);