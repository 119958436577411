import React, { Component } from "react";
import Layout from "../../components/layout/main";

//
import ReactHtmlParser from "react-html-parser";
import { RulesAccordion } from "../../Api/basic";
import {
  interpreter,
  // localStorgeSubscriber,
  // pictureUrlMaker
} from "../../utills";
import { Helmet } from "react-helmet";

class Home extends Component {
  state = {
    data: "",
    openIndex: "",
  };
  componentDidMount = async () => {
    await this.getData();
  };
  toggle = (key) => {
    if (this.state.openIndex === key) {
      this.setState({ openIndex: "" });
    }
    this.setState({ openIndex: key });
  };
  getData = async () => {
    const { data } = await RulesAccordion();
    if (data.success) {
      this.setState({
        data: JSON.parse(data.rulesAcardeon),
      });
    }
  };
  render() {
    const { data, openIndex } = this.state;

    return (
      <>
        <Helmet>
          <title> {interpreter("gameRules")}</title>
        </Helmet>
        <div className="row columns">
          <div className="callout_panel">
            <div className="inner_panel_title">{interpreter("gameRules")}</div>
            <p>{ReactHtmlParser(interpreter("website_rules"))}</p>
            <br />
            <div className="dr_accordion">
              <div className="accordion">
                {data.length > 0
                  ? data.map((item, key) => (
                      <div class={`accordion__item ${openIndex === key ? "opened" : ""}`}>
                        <div
                          class={`accordion__title js-title ${
                            openIndex === key ? "is-active" : ""
                          }`}
                          onClick={() => this.toggle(key)}
                        >
                          <span className="pe-angle-left icon"></span>
                          &nbsp;{item.title}
                        </div>
                        <div class={`accordion__copy js-copy`}>
                          {ReactHtmlParser(item.description)}
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </>
    );
  }
}
export default Layout(Home);
