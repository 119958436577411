import React from 'react'
import { Player } from "@lottiefiles/react-lottie-player";
import anim from "../../../assets/anim_bingo_offline/data";


function numberToArray(number) {
  	let array = number.toString().split(""); 
  	return array.map(x => parseInt(x)); 
}
const  BingoOffline=({number})=> {
 
 
  let numbers=numberToArray(number)
   
  
  return (
 
       <Player

                autoplay
                loop
              src={number>9? anim(numbers[0],numbers[1]): anim(number)}

               style={{ height: "100%", width: "100%",borderRadius:12 }}


              />
   
  )
}

export default BingoOffline