import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

// اسکرول شدن صفحه به بالا وقتی که صفحه یا روتر عوض میشه
const ScrollToTop = ({ children, location: { pathname } }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  

  return children || null;
};

export default withRouter(ScrollToTop);