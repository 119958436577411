import { 

  COUNT_CARD_SELECTED,SHOW_DETIALS_CARD,
  COUNT_BOX_6_CARD_SELECTED,BOX_6_CARD_IS_SELECTED,
  COMPLETE_COUNT_DOWN_LIVE,
  DRAW_NUMBERS,
  DRAW_NUMBERS_CARDS,
  COUNT_CARD_SELECTED_OFFLINE,

} from "./actionTypes";
 

export const changeCountCardSelected = (increaseCard ) => ({
    type: COUNT_CARD_SELECTED,
    payload: {
      increaseCard: increaseCard 
      
    }
  })

  export const changeCountCardSelectedOffline = (increaseCard ) => ({
    type: COUNT_CARD_SELECTED_OFFLINE,
    payload: {
      increaseCard: increaseCard 
      
    }
  })

  export const changeCountBox6CardSelected = (increaseCard ) => ({
    type: COUNT_BOX_6_CARD_SELECTED,
    payload: {
      increaseCard: increaseCard 
      
    }
  })
  export const changeBox6CardIsSelected = (isSelected ) => ({
    type: BOX_6_CARD_IS_SELECTED,
    payload: {
      isSelected: isSelected 
      
    }
  })

  export const showDetilsCard = (card ) => ({
    type: SHOW_DETIALS_CARD,
    payload: {
      card: card 
      
    }
  })

  export const completeCountDownAction = (isComplete ) => ({
    type: COMPLETE_COUNT_DOWN_LIVE,
    payload: {
      isComplete: isComplete 
      
    }
  })

  export const setDrawNumbers = (numbers ) => ({
    type: DRAW_NUMBERS,
    payload: {
      numbers: numbers 
      
    }
  })


  export const setDrawNumbersCard = (cardsShow ) => ({
    type: DRAW_NUMBERS_CARDS,
    payload: {
      cardsShow: cardsShow 
      
    }
  })
 
 