import React from "react";

export default () => (
	<svg viewBox="0 0 497 497" ><g>
		<path d="m305.731 67.505c-1.284-18.139-16.452-32.505-34.912-32.505h-46.803c-18.46 0-33.628 14.366-34.912 32.505l-17.349 10-17.754-10c1.798-37.589 32.446-67.505 69.99-67.505h46.852c37.544 0 68.192 29.916 69.99 67.505l-17.419 10z" style={{ 'fill': "#a29aa5" }} />
		<path d="m157.85 47.5h39.38c-4.62 5.49-7.59 12.42-8.13 20l-17.34 10-17.76-9.99c.33-6.98 1.66-13.7 3.85-20.01z" style={{ 'fill': "#766d78" }} />
		<path d="m340.83 67.51-17.42 10-17.68-10.01c-.54-7.58-3.5-14.51-8.12-20h39.37c2.19 6.31 3.52 13.03 3.85 20.01z" style={{ 'fill': "#766d78" }} />
		<path d="m341.562 497h-186.124c-31.469 0-57.619-24.127-59.979-55.34l-23.459-296.299 175.418-23.776 177.582 23.776-23.458 296.299c-2.36 31.213-28.51 55.34-59.98 55.34z" style={{ 'fill': "#a29aa5" }} />
		<path d="m425 145.36-1.56 19.68h-349.88l-1.56-19.68 175.42-23.77z" style={{ 'fill': "#a29aa5" }} />
		<path d="m425 145.36-177.58-23.77-163.193 22.113-12.556-1.629 23.789 299.586c2.36 31.21 28.51 55.34 59.98 55.34h25c-31.47 0-57.62-24.13-59.98-55.34l-20.193-255.042c-.921-11.632 8.27-21.579 19.938-21.579h303.235z" style={{ 'fill': "#766d78" }} />
		<path d="m443.445 145.042h-389.89c-5.506 0-9.97-4.464-9.97-9.97v-.156c0-37.23 30.181-67.412 67.412-67.412h275.007c37.23 0 67.412 30.181 67.412 67.412v.156c-.001 5.507-4.465 9.97-9.971 9.97z" style={{ 'fill': "#a29aa5" }} />
		<g style={{ 'fill': "#766d78" }}><path d="m73.59 134.92v.15c0 5.51 4.46 9.97 9.96 9.97h-30c-5.5 0-9.96-4.46-9.96-9.97v-.15c0-37.23 30.18-67.41 67.41-67.41h30c-37.23 0-67.41 30.18-67.41 67.41z" />
			<path d="m248.5 442.986c-10.012 0-18.128-8.116-18.128-18.128v-207.354c0-10.012 8.116-18.128 18.128-18.128s18.128 8.116 18.128 18.128v207.354c0 10.012-8.116 18.128-18.128 18.128z" />
			<path d="m334.777 442.987c-.481 0-.966-.019-1.453-.058-9.98-.792-17.43-9.525-16.637-19.505l16.453-207.354c.792-9.98 9.52-17.431 19.505-16.637 9.98.792 17.43 9.525 16.637 19.505l-16.453 207.354c-.754 9.492-8.691 16.695-18.052 16.695z" />
			<path d="m162.223 442.987c-9.362 0-17.299-7.202-18.052-16.695l-16.453-207.354c-.792-9.98 6.657-18.713 16.637-19.505 9.978-.805 18.713 6.656 19.505 16.637l16.453 207.354c.792 9.98-6.657 18.713-16.637 19.505-.487.04-.972.058-1.453.058z" />
		</g></g></svg>
)