import React, { Component } from "react";

// API
import { basic } from "../../../Api";

// components

// SVG icons
import BlueFacebook from "../../../assets/images/social/social-facebook.png";
import BlueYoutube from "../../../assets/images/social/social-youtube.png";
import BlueInstagram from "../../../assets/images/social/social-insta.png";
import BlueTwitter from "../../../assets/images/social/social-twitter.png";

class Footer extends Component {
  state = {
    webSite: "",
  };
  async componentDidMount() {
    await this.getBasic();
  }

  getBasic = async () => {
    const { data } = await basic.basic();
    this.setState({ webSite: data });
  };

  render() {
    const { webSite } = this.state;
    return (
      <>
        <div
          className={`FooterBack ${this.props.red ? "deberna" : ""} ${
            this.props.all ? "all" : ""
          }`}
        >
          <div className="footer">
            <div className="row">
              {/* <div className="medium-6 columns">
                <ul className="footer_menu">
                  {this.props.all ? (
                    <>
                      <li><NavLink to="/WebsiteRules">قوانین</NavLink></li>
                      <li><NavLink to="/">بلاگ</NavLink></li>
                    </>
                  ) : <>
                      {this.props.red ? (
                        <>
                          <li><NavLink to="/about-bingo">درباره بازی</NavLink></li>
                          <li><NavLink to="/bingoList">لیست بازی</NavLink></li>
                          <li><NavLink to="/">بلاگ</NavLink></li>
                        </>
                      ) : (<>
                        <li><NavLink to="/about-wingo">درباره بازی</NavLink></li>
                        <li><NavLink to="/archive">آرشیو</NavLink></li>
                        <li><NavLink to="/">بلاگ</NavLink></li>
                      </>)}
                    </>}
                </ul>
              </div> */}
              <div className="medium-6 columns">
                <ul className="menu align-center social_icons">
                  <li>
                    <a
                      href={webSite.facebookUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={BlueFacebook} alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={webSite.youtubeUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={BlueYoutube} alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={webSite.instagramUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={BlueInstagram} alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={webSite.telegramUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={BlueTwitter} alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Footer;
