import React, { Component } from "react";

// API
import { basic, tournamentsDaberna } from "../../Api";

// Components
import Slider from "./slider";
import Deberna from "./deberna";
import Game from "../tornoment-new/index-without-layout";
import { NavLink } from "react-router-dom";
import Layout from "../../components/layout/main";
import "./style.css";
import ImgBtn1 from "./wingo-imgg.png";
import ImgBtn2 from "./bingo-imgg.png";
import SweetAlert from "../../components/sweetalert/v";
import { getDrawTimeStart, getLongDate } from "../../utills";
import ShowTournoment from "./ShowTournoment";

class HomeAll extends Component {
  state = {
    options: {
      items: 1,
      dots: true,
      nav: false,
      rewind: true,
      rtl: true,
      loop: true,
      margin: 0,
      autoplay: true,
      smartSpeed: 600,
      animateIn: "fadeIn",
      animateOut: "fadeOut",
    },
    Slider: "",
    webSite: "",
    LatestDaberna: {
      IsStarted: false,
      Price: 0,
      Prize: 0,
      StartsAt_Raw: "",
      StartsAt_gregorian: "",
      StartsAt_jalali: "",
    },
    startedTornoment: false,
    showCountDown: false,
  };
  Latest;
  async componentDidMount() {
    await this.getBasic();
    this.getLatestDeberna();
    const { webSite } = this.state;
    if (webSite && webSite.activeMainPageAnnouncement) {
      this.getAnnouncements();
    }
  }
  getBasic = async () => {
    try {
      const { data } = await basic.basic();
      this.setState({ webSite: data });
    } catch (error) {
      console.error(error);
    }
  };

  getLatestDeberna = async () => {
    try {
      const { data } = await tournamentsDaberna.tournamentLatest();
      if (data.success) {
        const drawTimeStart = getDrawTimeStart(data.tournament.StartsAt_gregorian)
        this.setState({
          LatestDaberna: data.tournament,
          startedTornoment: true,
          showCountDown: drawTimeStart > 0,
        });
        if (drawTimeStart < 500000) {
          this.Latest = setTimeout(() => {
            this.getLatestDeberna();
          }, drawTimeStart);
        }

      }
    } catch (error) {
      console.error(error);
    }
  };

  getAnnouncements = async () => {
    const { data } = await basic.announcements();
    if (data.success) {
      this.SiteNotification(data.announcements);
    }
  };

  SiteNotification = (data) => {
    const isMainPage = data.find((x) => x.isMainPage);
    SweetAlert({
      allowOutsideClick: data ? true : false,
      red: true,
      ismainPage: true,
      title: isMainPage.announcementTitle_Fa,
      width: "60vw",
      html: <>{isMainPage.announcementDescription_Fa}</>,
    });
  };

  componentWillUnmount() {
    clearTimeout(this.Latest);
  }

  render() {
    const {
      webSite,
      LatestDaberna,
      startedTornoment,
      showCountDown,
    } = this.state;
    const gameId = webSite.LottoPageTournamentId
      ? { params: { id: webSite.LottoPageTournamentId } }
      : "";


    return (
      <>
        <Slider />
        <ShowTournoment />
        <div className="page_components">
          <Game match={gameId} />
          <br />
          {startedTornoment && showCountDown ? (
            <Deberna
              LatestDaberna={LatestDaberna}
              {...this.props}
              showCountDown={showCountDown}
            />
          ) : (
            ""
          )}

          {/* banner */}

          <div className="banner_all_home ">
            <div className="row display_flex align_items_center flex_wrap">
              <div className="medium-6 small-6 columns text-center">
                <NavLink to={"/bingo"}>
                  <img src={ImgBtn2} alt="" />
                </NavLink>
              </div>
              <div className="medium-6 small-6 columns text-center">
                <NavLink to={"/wingo"}>
                  <img src={ImgBtn1} alt="" />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Layout(HomeAll);
