import React from "react";

const CountDown= ({ days, hours, minutes, seconds, completed }) => {
  // Render a countdown
  return (
    <div className="Countdown">
      <div className="Countdown-col days">
        <div className="Countdown-col-element">
          <strong>{days}</strong>
          <span>{days === 1 ? "Day" : "Days"}</span>
        </div>
      </div>

      <div className="times">
        <div className="Countdown-col">
          <div className="Countdown-col-element">
            <strong>{hours}</strong>
            <span>hours</span>
          </div>
        </div>

        <div className="Countdown-col">
          <div className="Countdown-col-element">
            <strong>{minutes}</strong>
            <span>minutes</span>
          </div>
        </div>

        <div className="Countdown-col">
          <div className="Countdown-col-element">
            <strong>{seconds}</strong>
            <span>seconds</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountDown
