import React, { Component } from 'react';


class Title extends Component {

    render() {
        return (
            <div className="Title_dashboard">
                <span className={`icon pe-${this.props.icon}`}></span>
                <div className="text">{this.props.title}</div>
            </div>
        )
    }
}
export default Title;