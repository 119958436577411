import React, { Component } from "react";
// import Layout from "../../components/layout/";
import { RulesAccordion } from "../../Api/basic";

//
import ReactHtmlParser from "react-html-parser";

import {
  interpreter,
} from "../../utills";
import { Helmet } from "react-helmet";
import Header from "../../components/layout/header-main";
import Footer from "../../components/layout/footer";

class About extends Component {
  state = {
    data: "",
    openIndex: "",
  };
  componentDidMount = async () => {
    await this.getData();
  };
  toggle = (key) => {
    if (this.state.openIndex === key) {
      this.setState({ openIndex: "" });
    }
    this.setState({ openIndex: key });
  };
  getData = async () => {
    const { data } = await RulesAccordion();
    if (data.success) {
      this.setState({
        data: JSON.parse(data.dabernaAboutAcardeon),
      });
    }
  };

  render() {
    const { data, openIndex } = this.state;

    return (
      <><Header color="red" />
        <Helmet>
          <title> {interpreter("About")}</title>
        </Helmet>
        <div className="row columns">
          <div className="callout_panel">
            <div className="inner_panel_title">{interpreter("About")}</div>
            <p>{ReactHtmlParser(interpreter("aboutUs"))}</p>
            <br />
            <div className="dr_accordion red">
              <div className="accordion">
                {data.length > 0
                  ? data.map((item, key) => (
                    <div class={`accordion__item ${openIndex === key ? "opened" : ""}`}>
                      <div
                        class={`accordion__title js-title ${openIndex === key ? "is-active" : ""
                          }`}
                        onClick={() => this.toggle(key)}
                      >
                        <span className="pe-angle-left icon"></span>
                          &nbsp;{item.title}
                      </div>
                      <div class={`accordion__copy js-copy`}>
                        {ReactHtmlParser(item.description)}
                      </div>
                    </div>
                  ))
                  : null}
              </div>
            </div>
            {/*  */}
          </div>
        </div><Footer red />
      </>
    );
  }
}
export default About;
