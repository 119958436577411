import React, { Component } from "react";

import Img from "./img-errorDaberna.jpg";
import { interpreter } from "../../utills";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

class Notfound extends Component {
  componentDidMount = () => {
    document.body.classList.add("error_page");
  };

  componentWillUnmount = () => {
    document.body.classList.remove("error_page");
  };

  component;

  render() {
    return (
      <>
        <br />
        <br />
        <br />
        <div className="row text-center columns">
          <div className="large-6 medium-8 float-center">
            <div className="icon-notFound">
              <img src={Img} alt="" />
            </div>
          </div>

          <div className="large-6 medium-8 float-center text-center">
            <h1 className="404_title">
              {interpreter("error503") || "503 Error"}
            </h1>
            <p>
              {interpreter("websiteDisabledText")
                ? ReactHtmlParser(interpreter("websiteDisabledText"))
                : "! Website is temporarily unavailable"}
            </p>
            <Link to="/" className="button confirm">
              {interpreter("backToHome") || "بازگشت به صفحه اصلی"}
            </Link>
          </div>
        </div>
      </>
    );
  }
}
export default Notfound;
