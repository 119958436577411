import { APIClientRippo2 } from "./_api";

export function archiveList(values) {
  let bodyFormData = new FormData();
  let userAuth = localStorage.getItem("userAuth");
  for (let key in values) {
    bodyFormData.append(key, values[key]);
  }
  bodyFormData.append("userAuth", userAuth || "");

  return APIClientRippo2.post(`/archive/list/`, bodyFormData);
}

export function tournamentsList(id) {
  let bodyFormData = new FormData();
  let userAuth = localStorage.getItem("userAuth");

  bodyFormData.append("userAuth", userAuth || "");

  return APIClientRippo2.post(`/archive/lotto/view/${id}`, bodyFormData);
}
export function tournamentsListBingo(id) {
  let bodyFormData = new FormData();
  let userAuth = localStorage.getItem("userAuth");

  bodyFormData.append("userAuth", userAuth || "");

  return APIClientRippo2.post(`/archive/daberna/view/${id}`, bodyFormData);
}

export function video() {
  return APIClientRippo2.get(`/Lotto/extraIndexContent/`);
}
