import React from 'react';

import './style.css';

const spinner = () => {
  return (
    <div className="pre_loader">
      <div className="loader2">
        <div className="line" />
        <div className="line" />
        <div className="line" />
      </div>
    </div>
  );
};

export default spinner;
