import React, { Component } from "react";

// api Services

// Components
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "./scrollTop";

// Loading
import Spinner from "./Loading";

// Routers Components
import Wingoo from "../container/wingo";
import Deberna from "../container/deberna";
import BingoLive from "../container/bingoLive";
import BingoLive2 from "../container/bingoLive2";
import DebernaList from "../container/deberna-list";
import DebernaGame from "../container/deberna-game";
import AboutWingo from "../container/About/wingo";
import AboutBingo from "../container/About/bingo";
import WebsiteRules from "../container/websiterules";
import LiveStream from "../container/liveStream";
import notFound from "../container/not-found";
import notFound503 from "../container/not-found/503";
import Daberna503 from "../container/not-found/503Daberna";
import Lotto503 from "../container/not-found/503Lotto";
import Login from "../container/authentication/login";
import Register from "../container/authentication/register";
import ForgetPass from "../container/authentication/forgetpass";
import ForgetpassStep2 from "../container/authentication/forgetpassStep2";
import Archive from "../container/archive";
import ArchiveItem from "../container/archive/item";
import ArchiveItemBingo from "../container/archive/bingo";
import TornimentItemNew from "../container/tornoment-new";
import Futuretornoment from "../container/futuretornoment";
// dashboard
import Dashboard from "../container/dashboard";
import ChangePass from "../container/dashboard/change-pass";
import TornomentArchive from "../container/dashboard/tornoment-archive";
import Authentication from "../container/dashboard/authentication";
import AuthenticationArchive from "../container/dashboard/authentication-archive";
import Blance from "../container/dashboard/charge-blance";
import Transactions from "../container/dashboard/transactionHistory";
import Withdrawal from "../container/dashboard/whithdraw";
import { basic } from "../Api";
import { stringifier, interpreter } from "../utills";
import BingoArchive from "../container/dashboard/bingo-archive";
import BingoArchiveOnline from "../container/dashboard/bingo-online-archive";
import BingoArchiveOffline from "../container/dashboard/bingo-offline-archive";
import "./App.css";

import { APIClient, APIClientDaberna, APIClientRippo, APILiveBingo } from "../Api/_api";

import { ToastContainer } from "react-toastr";
import HomeAll from "../container/home";

let container;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      routes: [
        {
          route: `/`,
          exact: true,
          component: HomeAll,
        },
        {
          route: `/wingo`,
          exact: true,
          component: Wingoo,
        },
        // == bingo
        {
          route: `/bingo`,
          exact: true,
          component: Deberna,
        },
        {
          route: `/bingoList`,
          exact: true,
          component: DebernaList,
        },
        {
          route: `/bingoLive`,
          exact: true,
          component: BingoLive,
        },
        {
          route: `/bingoLiveStarted`,
          exact: true,
          component: BingoLive2,
        },
        {
          route: `/bingoGame-:id`,
          exact: true,
          component: DebernaGame,
        },
        // server error page
        {
          route: `/serverError`,
          exact: true,
          component: notFound503,
        },
        // server error page Lotto
        {
          route: `/serverErrorLotto`,
          exact: true,
          component: Lotto503,
        },
        // server error page Daberna
        {
          route: `/serverErrorDaberna`,
          exact: true,
          component: Daberna503,
        },
        {
          route: `/About-wingo`,
          exact: true,
          component: AboutWingo,
        },
        {
          route: `/About-bingo`,
          exact: true,
          component: AboutBingo,
        },
        {
          route: `/WebsiteRules`,
          exact: true,
          component: WebsiteRules,
        },
        {
          route: `/LiveStream`,
          exact: true,
          component: LiveStream,
        },
        {
          route: `/login`,
          exact: true,
          component: Login,
        },
        {
          route: `/forget-pass-step2`,
          exact: true,
          component: ForgetpassStep2,
        },
        {
          route: `/forget-pass`,
          exact: true,
          component: ForgetPass,
        },
        {
          route: `/register`,
          exact: true,
          component: Register,
        },
        {
          route: `/dashboard-home`,
          exact: false,
          component: Dashboard,
        },
        {
          route: `/dashboard-change-pass`,
          exact: true,
          component: ChangePass,
        },
        {
          route: `/dashboard-archive-tornoments`,
          exact: true,
          component: TornomentArchive,
        },
        {
          route: `/dashboard-archive-bingo`,
          exact: true,
          component: BingoArchive,
        },
        {
          route: `/dashboard-archive-online-bingo`,
          exact: true,
          component: BingoArchiveOnline,
        },
        {
          route: `/dashboard-archive-offline-bingo`,
          exact: true,
          component: BingoArchiveOffline,
        },
        {
          route: `/dashboard-authentication`,
          exact: true,
          component: Authentication,
        },
        {
          route: `/dashboard-authentication-archive`,
          exact: true,
          component: AuthenticationArchive,
        },
        {
          route: `/dashboard-add-blance`,
          exact: true,
          component: Blance,
        },
        {
          route: `/dashboard-Withdrawal`,
          exact: true,
          component: Withdrawal,
        },
        {
          route: `/dashboard-transactions`,
          exact: true,
          component: Transactions,
        },
        {
          route: `/archive`,
          exact: true,
          component: Archive,
        },
        {
          route: `/archive-:id`,
          exact: true,
          component: ArchiveItem,
        },
        {
          route: `/archiveBingo-:id`,
          exact: true,
          component: ArchiveItemBingo,
        },
        {
          route: `/tornoment-:id`,
          exact: true,
          component: TornimentItemNew,
        },
        {
          route: `/futuretornoment-:id`,
          exact: true,
          component: Futuretornoment,
        },
        {
          exact: true,
          component: notFound,
        },
      ],
      webSite: "",
    };
  }
  timeOut;

  // لودینگ سایت
  loader() {
    return new Promise((resolve) => setTimeout(() => resolve(), 1500));
  }

  componentDidMount() {
    this.getBasic();
    this.getLangContent();
    this.ErrorHandler();
    this.loader().then(() => this.setState({ loading: false }));
  }

  setResponse = (response) => {
    if (
      response.data &&
      response.data.message &&
      container &&
      response.data.success
    ) {
      container.success(`${interpreter(response.data.message)}`, ``, {
        closeButton: true,
        timeOut: 2000,
      });
      response.data.message = "";
    } else if (
      response.data &&
      response.data.message &&
      container &&
      !response.data.success
    ) {
      container.error(`${interpreter(response.data.message)}`, ``, {
        closeButton: true,
        timeOut: 2000,
      });
    }
    return response;
  }
  ErrorHandler = () => {
    APIClient.interceptors.response.use(this.setResponse);
    APIClientDaberna.interceptors.response.use(this.setResponse);
    APIClientRippo.interceptors.response.use(this.setResponse);

    APILiveBingo.interceptors.response.use(this.setResponse);
    return <span />;
  };
  getLangContent = async () => {
    const { data } = await basic.translator({ lang: "fa" });

    localStorage.setItem("lang", stringifier(data));
  };
  getBasic = async () => {
    const { data } = await basic.basic();
    this.setState({ webSite: data });
    localStorage.setItem("webSite", stringifier(data));
  };

  render() {
    const { loading } = this.state;
    if (loading) {
      return (
        <div className="loading_apge">
          <Spinner />
        </div>
      );
    }

    return (
      <>

        <ToastContainer
          ref={(r) => (container = r)}
          className="toast-bottom-left"
        />
        <Router>
          <ScrollToTop>
            <Switch>
              {this.state.routes.map((route, k) => (
                <Route
                  key={k}
                  exact={route.exact}
                  path={route.route}
                  component={route.component}
                />
              ))}
            </Switch>
          </ScrollToTop>
        </Router>
      </>
    );
  }
}

export default App;
