import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from './App'
import * as serviceWorker from "./serviceWorker";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import store from "./redux/store";

ReactDOM.render(
  <>
    <Helmet>
      <title>{window.site_title}</title>
      <meta name="description" content={window.site_description} />
    </Helmet>
    <Provider store={store}>
      <App />
    </Provider>
  </>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
