import React, { Component } from "react";

// API

// Components

import Layout from "../../components/layout/main";
import Menu from "./menu.js";
import ProfileCard from "./profile_card.js";
import Title from "./title";
import { user } from "../../Api";
import { interpreter, userDataGetter, toJalaliHour } from "../../utills";
import { NavLink } from "react-router-dom";

class Dashboard extends Component {
  state = { List: [] };

  componentDidMount() {
    this.handleSubmitCard();
  }
  handleSubmitCard = async () => {
    const viewModel = {
      type: "info"
    };

    const { data } = await user.cardAuthentication(viewModel);
    if (data.success) {
      this.setState({ List: data.cardsList || [] });
    }
  };
  render() {
    const { List } = this.state;
    const userLang = userDataGetter("userLang", "data");
    return (
      <div>
        {/* breadcrumb */}
        <div className="row">
          <div className="large-3 medium-4 columns">
            <div className="dashboard_sidebar">
              <ProfileCard />
              <Menu />
            </div>
          </div>

          {/* start content */}
          <div className="large-9 medium-8 columns">
            <div className="dashboard_panel">
              <Title title={interpreter("cardBankArchive")} icon="note2" />
              <div className="text-left">
                <NavLink
                  className="button confrim"
                  to="/dashboard-authentication"
                >
                  {interpreter("add")}
                </NavLink>
              </div>
              <table className="ripo_table">
                <thead>
                  <tr>
                    <td>{interpreter("row")}</td>
                    <td>{interpreter("requstTime")}</td>
                    <td>{interpreter("image")}</td>
                    <td>{interpreter("cardNumber")}</td>
                    <td>{interpreter("status")}</td>
                  </tr>
                </thead>
                <tbody>
                  {List.map((x, i) => (
                    <tr key={Math.random()}>
                      <td data-title={interpreter("row")}>{i + 1}</td>
                      <td data-title={interpreter("requstTime")}>
                        {!userLang || userLang === "fa"
                          ? toJalaliHour(x.created_gregorian)
                          : x.created_gregorian}
                      </td>
                      <td data-title={interpreter("image")}>
                        <img className="imgtd" src={x.imageContent} alt="" />
                      </td>
                      <td data-title={interpreter("cardNumber")}>{x.cardNumber}</td>
                      <td data-title={interpreter("status")}>{interpreter(x.status)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* // end content */}
        </div>
      </div>
    );
  }
}
export default Layout(Dashboard);
