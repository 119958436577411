import React, { Component } from "react";

// API

// Components

class Step2 extends Component {
  render() {
    return (
      <>
        <div className="deberna_game_step2">
          {/* بعد از 2 ثانیه باید کلاس 
                    scale 
                    بگیره که محو بشه و دایو بعدی رو اضافه کنید */}
          {/* بعد از اینکه این کلاس اضافه شد
                    به اندازه 1.5 ثانیه
                    طول میکشه تا انیمیشن مخفی شدن اجرا بشه. بعد از اون باید کلا مرحله 
                    step2 
                    بره و مرحله استپ 3 بیاد که عدد نهایی توشه */}
          <div className="deberna_lottery_box spinner ">
            <div className="ball ball-1"></div>
            <div className="ball ball-2"></div>
            <div className="ball ball-3"></div>
            <div className="ball ball-4"></div>
            <div className="ball ball-5"></div>
            <div className="ball ball-6"></div>
            <div className="ball ball-7"></div>
            <div className="ball ball-8"></div>
            <div className="ball ball-9"></div>
            <div className="ball ball-10"></div>
          </div>
        </div>
      </>
    );
  }
}
export default Step2;
