import React, { Component } from "react";

import { Grid } from "@material-ui/core";
// API

// components
import ReactPlayer from "react-player";
import Layout from "../../components/layout/main";
//import Layout from "../../components/layout";
//
import "./index.css";
import { archive } from "../../Api";
import { interpreter, userDataGetter } from "../../utills";
import TornomentCard from "../../components/card-tornoment/index-archive";
import TornomentCardBing from "./bingo/card-tornoment/index-archive";
import ImgTornoment from "../../assets/images/tornoment/monthly.png";
import { Helmet } from "react-helmet";
import Bingo from './bingo'

class Archive extends Component {
  state = {
    months: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
    years: [],
    typeTournament:[interpreter('type_bingoo'),interpreter('type_baberna')],
    totalTournaments: "",
    totalPages: "",
    tournaments: [],
    userLang: "",
    pages: [],
    page: 1,
    year: "00",
    month: "00",
    gameType:"",
  };

  componentDidMount() {
    this.getItem({ jalaliYear: "00", jalaliMonth: "00"  });
  }
  handleChangeMonth = async ({ target }) => {

     
    let val=null;
    if( target.value==interpreter('type_bingoo'))
    val=1;
    else if(target.value==interpreter('type_baberna')){
      val=0;
    }else if(target.value=="-1"){
      val=-1;
    }
    else{
        val= target.value
    }
    
    if(target.name=='month' && val!=="00"){
      const yearValue=   this.state.year==="00"?this.state.years[this.state.years.length-1] : this.state.year;
      
      await this.setState({ [target.name]:val,   year:yearValue });
    }else{
      if(target.name=='year' && val=="00"){
        await this.setState({  year:"00",month:"00" });
      }else
      await this.setState({  [target.name]:val });
    }
    
    
     this.search();
  };

  getItem = async (values) => {
    try {
      
      
       
      let userLang = await userDataGetter("userLang", "data");

    
       const { data } = await archive.archiveList(values);
       if(data.success){
 
        if(Object.keys( data.tournaments[0]).length>0 ){
          let years = []; 
          if (!userLang || userLang === "fa") {
            for (
              let i = data.firstTournamentYear_jalali;
              i <= data.lastTournamentYear_jalali;
              i++
            ) {
              years.push(i);
            }
          } else {
            for (
              let i = data.firstTournamentYear_gregorian;
              i <= data.lastTournamentYear_gregorian;
              i++
            ) {
              years.push(i);
            }
          }
          let pages = [];
    
          for (let i = 1; i <= data.totalPages; i++) {
            pages.push(i);
          }
          this.setState({ years, userLang, pages: pages || [], ...data });
        }else{
          this.setState({ userLang, pages:   [],tournaments:[] });
        }
       
       }else{

       }

     
    } catch (error) {
      console.error(error);
    }
  };
  search = () => {
    let values = "";
  
    if (!this.state.userLang || this.state.userLang === "fa") {
      values = { jalaliYear:this.state.year, jalaliMonth: this.state.month ,gameType: this.state.gameType};
    } else {
      values = {
        gregorianYear: this.state.year,
        gregorianMonth: this.state.month 
        ,gameType: this.state.gameType
      };
    }
    if(this.state.gameType==-1)
    delete values.gameType;
     
    this.getItem(values);
  };

  handleChangePage = (page) => {
    this.setState({ page: page });
    this.getItem({ page: page, jalaliYear: "00", jalaliMonth: "00" });
  };
  render() {
    const { years, months, tournaments, pages, page,typeTournament } = this.state;
    const { handleChangeMonth } = this;
    return (
      <>

        <Helmet>
          <title> {interpreter("Archive")}</title>
        </Helmet>
        <div>
          {/* sorting by year and month */}
          <div className="row columns">
            <div className="sorting_panel">
              <label> {interpreter("showTornomentBase")}</label>
              <div className="display_flex">

              <div className="sort_item">
                  <label className="label_type_tornoment">{interpreter("Tournament type")}</label>
                  <select name="gameType" onChange={handleChangeMonth}>
                    <option value="-1">{interpreter("all")}</option>
                    {typeTournament.map((x) => (
                      <option value={x} key={x}>
                        {x}
                      </option>
                    ))}
                  </select>
                </div>


                <div className="sort_item">
                  <label>{interpreter("year")}</label>
                  <select name="year" onChange={handleChangeMonth} 
                value={this.state.year}
                  >
                    <option value="00">{interpreter("all")}</option>
                    {years.map((x) => (
                      <option value={x} key={x}>
                        {x}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="sort_item">
                  <label>{interpreter("month")}</label>
                  <select name="month" onChange={handleChangeMonth}
                   value={this.state.month}>
                    <option value="00">{interpreter("all")}</option>
                    {months.map((x) => (
                      <option value={x} key={x}>
                        {x}
                      </option>
                    ))}
                  </select>
                </div>
               
               
             
             
              </div>
            </div>
          </div>
         
          {tournaments.length>0? tournaments.map((x) => (
            <div key={Math.random()} className="row columns">
              <div className={`archive_list_item  ${x.game_type=="wingo" ? "wingo":"bingo"}`}>
                <div className="row">
                  {x.tournamentHasVideo ? (
                    <div className="large-6 medium-6 small-12 columns col1">
                      <ReactPlayer
                        url={x.tournamentVideoUrl}
                        width="100%"
                        controls={true}
                      />
                    </div>
                  ) : (
                   <div style={{width:"50%"}}>
                     {interpreter("There_no_video")}
                   </div>
                  )}
                  <div className="large-6 medium-6 small-12 columns col2">
                    <div className="tornoment_card">
                      {/* عکس باید طبق نوع تورنومنت تغییر کنه */}
                    { 
                    x.game_type==="wingo"?
                    <TornomentCard
                        tournament={x}
                        key={Math.random()}
                        image={ImgTornoment}
                        type="monthly"
                        userHasParticipatedInTournament={
                          x.userHasParticipatedInTournament
                        }
                      />
                      :
                      <TornomentCardBing
                      tournament={x}
                      key={Math.random()}
                      image={ImgTornoment}
                      type="bingo"
                      userHasParticipatedInTournament={
                        x.userHasParticipatedInTournament
                      }
                    />
                    
                    }
                    </div>

                    {/* <span className="title">{x.tournamentName}</span>
                    <div className="type">
                      <span className="pe-pin"></span>&nbsp;
                      {interpreter(x.tournamentType)}
                    </div>
                    <div className="total">
                      <div className="price">
                        <span className="pe-cash"></span>&nbsp;
                        {interpreter("totalPrize")}
                      </div>
                      <div className="money">
                        {numberWithCommas(x.tournamentTotalPrize)}&nbsp;
                        <span className="tm">{interpreter("Toman")}</span>
                      </div>
                    </div> */}
                    {/* اگه توی این تورنومنت شرکت کرده باشه بهش این پایینی نشون داده میشه */}
                    {/* {x.userHasParticipatedInTournament ? (
                      <div className="presence">
                        <span className="pe-check"></span>&nbsp;
                        {interpreter("userHasParticipatedInTournament")}
                      </div>
                    ) : (
                        ""
                      )} */}

                    {/* <NavLink to={`/archive/${x.tournamentId}`} className="button confrim">
                      {interpreter("seeResult")}
                    </NavLink> */}
                  </div>
                </div>
              </div>
            </div>
          ))
          :
          (
            <Grid container justify="center" direction="row"  className="row columns label_type_tornoment liveBingo"
            style={{minHeight:150,color:"red",paddingTop:80}}>
              {interpreter("not_info_result")}
            </Grid>
          )
        
        }

          {/* pagination */}
          {pages && pages.length ? (
            <ul className="pagination text-center">
              <li
                className={
                  pages[0] === page
                    ? "pagination-previous disabled"
                    : "pagination-previous page pointer"
                }
                onClick={() => this.handleChangePage(page - 1)}
                style={{borderColor:"#757575"}}
              >
                {interpreter("before")}
              </li>
              {pages.map((x) => (
                <li
                  key={x}
                  className={page === x ? "current" : "page pointer"}
                  onClick={() => this.handleChangePage(x)}
                  style={{background:"#757575",borderColor:"#757575"}}
                >
                  {x}
                </li>
              ))}

              <li
                className={
                  pages[pages.length - 1] === page
                    ? "pagination-next disabled"
                    : "pagination-next page pointer"
                  
                }
                onClick={() => this.handleChangePage(page + 1)}
                style={{borderColor:"#757575"}}
              >
                {interpreter("after")}
              </li>
            </ul>
          ) : (
            ""
          )}
        </div>
     
     
      </>
    );
  }
}

export default  Layout(Archive);
