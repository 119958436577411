import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme , withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CardItem from "../deberna-game/card";
import Imgbuyticket from "../deberna-game/buy-ticket.png";
import {
  interpreter,
  localStorgeSubscriber,
  numberWithCommas,
  toJalaliHour,
} from "../../utills";
import "../home/style.css";
import OwlCarousel from "react-owl-carousel2";
import SwipeableViews from 'react-swipeable-views';
import Loading from './LoadingProcess2'
 
import { Suspense, lazy } from 'react';



const AllCard = React.lazy(() => import('./allCard'));
const Box6Cards = React.lazy(() => import('./box6cards'));
const CardSelected = React.lazy(() => import('./selectedCards'));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': { 
      width: '90%',
      backgroundColor: '#fff',
      height:"50px"
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    width: "100%",
  },
  layoutTab: {
    background: "#0000",
  },
  tab: {
    "&:focus": {
      outlineStyle: "none!important",
      background: "rgb(245, 62, 67)",
      borderBottom:"none"
    },
    "&:hover": {
      background:
        "linear-gradient(270deg,rgba(245, 72, 77, 1) 0%,rgba(190, 10, 1, 1) 100%)",
    },
    "& span": {
      color: "#fff",
      fontFamily: "rezvan",
      fontSize: "24pt", 
    },
    background:
      "linear-gradient(270deg,rgba(245, 62, 67, 1) 0%,rgba(190, 10, 1, 1) 100%)",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    borderBottom:"1px soild #000"
  },
  tab1: {
    marginLeft: 25,
    marginRight: 15,
  },
  tab2: {
    marginLeft: 15,
    marginRight: 25,
  },
  tabPanel: {
    "& > div": {
      padding: "0px !important",
      paddingLeft: "15px !important",
      paddingRight: "15px !important",
    },
  },
}));
 
const TabLayoutPage = () => {
  const classes = useStyles();
  const theme = useTheme();

  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
  setTimeout(() => {
    setValue(newValue);
  }, 100);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const ticketSelector = (id) => {};
  return (
    <div className={classes.root}>
      <StyledTabs
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="primary"
        variant="fullWidth"
        aria-label="full width tabs example"
        className={classes.layoutTab}
      >
        <Tab
          label={interpreter("PurchasedCards")}  
          {...a11yProps(0)}
          className={`${classes.tab} ${classes.tab1}`}
        />
        <Tab label={interpreter("Cards")}   {...a11yProps(1)} className={classes.tab} />
      
        <Tab
          label={interpreter("packs6")}   
          {...a11yProps(1)}
          className={`${classes.tab} ${classes.tab2}`}
        />
      </StyledTabs>

      <div className="bingo_live min-Height "> 
      
      <TabPanel value={value}  className={classes.tabPanel} index={0} dir={theme.direction}>
      <Suspense fallback={<div className="loadingText"> {interpreter("loading")} </div>}>
        <CardSelected />
      </Suspense> 
      </TabPanel>
      <TabPanel
        value={value}
        index={1}
        dir={theme.direction}
        className={classes.tabPanel}
      >
       <Suspense fallback={<div className="loadingText">{interpreter("loading")}</div>}>
       
        <AllCard />
      </Suspense> 
      </TabPanel>
      <TabPanel value={value}  className={classes.tabPanel} index={2} dir={theme.direction}>
      <Suspense fallback={<div className="loadingText"> {interpreter("loading")}  </div>}>
        <Box6Cards />
      </Suspense> 
      </TabPanel>
     
      </div>
      
    </div>
  );
};

export default TabLayoutPage;
