import React, { Component } from "react";

// API

// Components

class Step3 extends Component {
  render() {
    const { LastNumber } = this.props;
    return (
      <>
        <div className="deberna_game_step3 display_flex align_items_center justify_content_center">
          <div className="final_debernaLuckyNumber">{LastNumber}</div>
        </div>
      </>
    );
  }
}
export default Step3;
