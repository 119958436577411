import { APILiveBingo ,APILiveBingoNoError} from "./_api";
const base = "";

 

export function bingoDetails() {
  let bodyFormData = new FormData();

  const userAuth = localStorage.getItem("userAuth");
  bodyFormData.append("userAuth", userAuth || "");

  return APILiveBingoNoError.post(`${base}/Detailes/`, bodyFormData);
}
export function bingoTicketList() {
  let bodyFormData = new FormData();

  const userAuth = localStorage.getItem("userAuth");
  bodyFormData.append("userAuth", userAuth || ""); 

  return APILiveBingoNoError.post(`${base}/ticket-list/`, bodyFormData);
}


export function bingoBoughtTicketList() {
  let bodyFormData = new FormData();

  const userAuth = localStorage.getItem("userAuth");
  bodyFormData.append("userAuth", userAuth || ""); 

  return APILiveBingoNoError.post(`${base}/bought-ticket/`, bodyFormData);
}

export function bingoNearestCards() {
  let bodyFormData = new FormData();

  const userAuth = localStorage.getItem("userAuth");
  bodyFormData.append("userAuth", userAuth || ""); 

  return APILiveBingoNoError.post(`${base}/nearest-cards/`, bodyFormData);
}


export function bingoBuyBoughtTicket(selectionArray) {
  let bodyFormData = new FormData();

  const userAuth = localStorage.getItem("userAuth");
  bodyFormData.append("userAuth", userAuth || ""); 
  bodyFormData.append("selectionArray",JSON.stringify(selectionArray));  

  return APILiveBingo.post(`${base}/buy-ticket/`, bodyFormData);
}

export function bingoBuyStripTicket(selectionArray) {
  let bodyFormData = new FormData();

  const userAuth = localStorage.getItem("userAuth");
  bodyFormData.append("userAuth", userAuth || ""); 
  bodyFormData.append("selectionArray", JSON.stringify(selectionArray));  

  return APILiveBingo.post(`${base}/Buy-Strip/`, bodyFormData);
}

export function bingoStripTicketList() {
  let bodyFormData = new FormData();

  const userAuth = localStorage.getItem("userAuth");
  bodyFormData.append("userAuth", userAuth || ""); 

  return APILiveBingoNoError.post(`${base}/Strip-List/`, bodyFormData);
}


export function bingoDrawnumbers() {
  let bodyFormData = new FormData();

  const userAuth = localStorage.getItem("userAuth");
  bodyFormData.append("userAuth", userAuth || ""); 

  return APILiveBingoNoError.post(`${base}/drawnumbers/`, bodyFormData);
}



 
