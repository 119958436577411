import React, { Component } from "react";

import Layout from "../../components/layout/main";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { user } from "../../Api";
import { interpreter } from "../../utills";

class ForgetpassStep2 extends Component {
  state = { Step3: false, type: "verify" };
  initialValues = {
    key1: "",
    key2: "",
    password1: "",
    password2: ""
  };
  handleSubmit = async (values, action) => {
    try {
      const viewModel = { ...values, type: this.state.type };

      const { data } = await user.forgetPass(viewModel);

      if (data.success) {
        this.setState({ Step3: true, type: "change" });
      }
    } catch (error) {
      console.error(error);
    }
  };
  render() {
    return (
      <div>
        <div className="row columns">
          <div className="large-8 medium-12 small-12 float-center ">
            <div className="authentication_wrapper">
              <div className="image_box">
                {/* <div className="text"> {interpreter("recoverPassword")}</div> */}
              </div>
              <div className="content_box">
                <Formik
                  initialValues={this.initialValues}
                  onSubmit={this.handleSubmit}
                >
                  {props => (
                    <Form onSubmitCapture={props.handleSubmit}>
                      {this.state.Step3 ? (
                        <>
                          <div className="form_control">
                            <Field
                              name="password1"
                              placeholder={interpreter("newPassword")}
                              type="text"
                            />
                          </div>
                          <div className="form_control">
                            <Field
                              name="password2"
                              placeholder={interpreter("repeatNewPassword")}
                              type="text"
                            />
                          </div>
                          <div className="text-left">
                            <button className="button" type="submit">
                              {interpreter("changePassword")}
                            </button>
                          </div>
                        </>
                      ) : (
                          <>
                            <div className="form_control">
                              <Field name="key1" placeholder="key1" type="text" />
                            </div>
                            <div className="form_control">
                              <Field name="key2" placeholder="key2" type="text" />
                            </div>
                            <div className="text-left">
                              <button className="button" type="submit">
                                {interpreter("checkKeys")}
                              </button>
                            </div>
                          </>
                        )}
                    </Form>
                  )}
                </Formik>
                <div>
                  <Link to="/login" className="forget_pass_link">
                    {interpreter("doYouHaveAccount")}
                  </Link>
                  <br />
                  <label>{interpreter("doYouWantRegister")}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Layout(ForgetpassStep2);
