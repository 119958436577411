import React from "react";
import { Redirect } from "react-router-dom";
import SweetAlert from "./index";

const Swal = async (props) => {
  const val = await SweetAlert.fire({
    allowOutsideClick: props.allowOutsideClick !== false,
    background: "transparent",
    showConfirmButton: false,
    width: props.width || null,
    html: (
      // کلاس red  یا blue اضافه بشه
      <div
        className={`ripo_popup ${props.ismainPage ? "" : props.red ? "red" : "blue"
          }`}
      >
        <div className="head">
          <div className="text_darkblue_v1">{props.title}</div>


          <span className="close_modal" onClick={() => {
            SweetAlert.clickCancel()
            if (typeof props.closeDialog === "function")
              props.closeDialog()
          }}>
            <span className="pe-close"></span>
          </span>

        </div>
        <div className="popup_content">{props.html}</div>
      </div>
    ),

  });
  return val;
};

export default Swal;
