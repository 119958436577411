import React from "react";
import CardItem from "./card";
import Imgbuyticket from "../../deberna-game/buy-ticket.png";

import { Grid } from "@material-ui/core";
import { changeCountCardSelectedOffline } from "../../../redux/actions";
import { NavLink } from "react-router-dom";
import {
  interpreter,
  localStorgeSubscriber,
  numberWithCommas,
  toJalaliHour,
} from "../../../utills";
import "../../home/style.css";
import OwlCarousel from "react-owl-carousel2";
import { connect } from "react-redux";
import { getBingoOffline } from "../../../redux/selectors";
import { ticketDaberna } from "../../../Api";
const options =(startPosition_)=> {

  return ({
  center: false,
  items: 3,
  dots: false,
  nav: true,
  rewind: false,
  rtl: true,
  loop: false,
  margin: 15,
  startPosition: parseInt(startPosition_),
  autoplay: false,
  smartSpeed: 600,
  responsiveClass: true,
  responsive: {
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    640: {
      items: 3,
    },
    1025: {
      items: 3,
    },
  },

})}
let selectedTicket = [];
let listTicket = [];

const mapStateToProps = (state) => {
  const todos = getBingoOffline(state);
  let countCardSelected = todos.countCardSelected;
  return { countCardSelected };
};
const FooterTable = connect(null, { changeCountCardSelectedOffline })(
  connect(mapStateToProps)(
    ({
      countCardSelected,
      piceCard,
      changeCountCardSelectedOffline,
      handleBuyTicket,
    }) => {
      const [countCard, setCountCard] = React.useState(0);
      const [isLogin, setIslogin] = React.useState(false);
      React.useEffect(() => {
         selectedTicket = [];
            changeCountCardSelectedOffline(-1);
        if (
          localStorage.getItem("userAuth") &&
          localStorage.getItem("userAuth").length > 2
        ) {
          setIslogin(true);
        }
      }, []);
      
      React.useEffect(() => {
        setCountCard(countCardSelected);
      }, [countCardSelected]);

      const handleSubmit = () => {
        requestBuyTicket();
      };

      const requestBuyTicket = async () => {
        try {
          let { data } = await ticketDaberna.buyTicket({
        tournamentId: localStorage.getItem("idGameOffline"),
        selectionArray: JSON.stringify(selectedTicket),
      }); 
      
      //liveBingo.bingoBuyBoughtTicket(selectedTicket);

          if (data.success) {
            selectedTicket = [];
            changeCountCardSelectedOffline(-1);
            setTimeout(() => {
              handleBuyTicket();
            }, 200);
          }
        } catch (error) {
          console.error(error);
        }
      };

      return (
        <div className="deberna_prices">
          <div className="price_item">
            <span className="text textInherited">
              {interpreter("cardPrice")}
            </span>
            <span className="textInherited value">
              {numberWithCommas(piceCard)}&nbsp;
              {interpreter("Toman")}
            </span>
          </div>
          <div className="price_item">
            <span className="text textInherited">
              {interpreter("selectedCards")}
            </span>
            <span className="textInherited value">{countCard}</span>
          </div>
          <div className="price_item">
            <span className="text textInherited">
              {interpreter("totalPrice")}
            </span>
            <span className="textInherited value">
              {numberWithCommas(countCard * piceCard)}&nbsp;
              {interpreter("Toman")}
               {countCard==0 && <span style={{opacity:0}}>545</span>}
            </span>
          </div>

          {!isLogin ? (
            <NavLink onClick={handleSubmit} to="/login">
              <img src={Imgbuyticket} alt="" />
            </NavLink>
          ) : (
            <button onClick={handleSubmit}>
              <img src={Imgbuyticket} alt="" />
            </button>
          )}
        </div>
      );
    }
  )
);

const CardSelected = ({ isComplete,piceCard }) => {
  const [listCard, setListCard] = React.useState([]);
  const [completeCountDown, setCompleteCountDown] = React.useState(false);

  React.useEffect(() => { 
    if (!completeCountDown) 
    requestTicketList();
      // if (listTicket.length === 0) setTimeout(() => {
      //   requestTicketList();
      // }, 100);
      // else {
      //   setListCard(listTicket);
      // }
  }, []);

  React.useEffect(() => {
    if (isComplete) setCompleteCountDown(isComplete);
  }, [isComplete]);

   const requestTicketList = async () => {
    try {
      let { data } = await ticketDaberna.TicketList({ tournamentId: localStorage.getItem("idGameOffline") });

      if (data.success) {
        let this3 = [];
        const newArray = [];
        await data.tickets.forEach((ticket, i) => {
          if ((i + 1) % 3 !== 0) {
            this3.push(ticket);
            if (i + 1 === data.tickets.length) {
              newArray.push(this3);
              this3 = [];
            }
          }
          if ((i + 1) % 3 === 0) {
            this3.push(ticket);
            newArray.push(this3);
            this3 = [];
          }
        });

        listTicket = newArray;
        setListCard(newArray);
      }else{
        setCompleteCountDown(true)
      }
    } catch (error) {
      console.error(error);
    }
  };

  const ticketSelector = (id) => {
    let selectedTicket_ = selectedTicket;
    const exsit = selectedTicket_.findIndex((x) => String(x) === String(id));

    if (exsit === -1  ) {
      selectedTicket_.push(id);
    }
    if (exsit > -1) {
      selectedTicket_.splice(exsit, 1);
    }
    //  const totalPrice = this.calculator(selectedTicket);
    //this.setState({ selectedTicket: selectedTicket, totalPrice });

    selectedTicket = selectedTicket_;
  };
  return (
    <div className="row columns" style={{ direction: "rtl" }}>
      <div
        className="  deberna_cards_panel deberna_groupbox_slider pos-r  p-t80"
        style={{ background: "none" }}
      >
        {!completeCountDown ? (
          listCard.length > 0 ? (
            <>
              <OwlCarousel options={options(listCard.length/2)}
              >
                {listCard.map((rowTicket) => (
                  <div className="groupBox_user_cards" key={Math.random()}>
                    {rowTicket && rowTicket.length
                      ? rowTicket.map((ticket) => {
                          const selected = selectedTicket.findIndex(
                            (x) => String(x) === String(ticket.TicketID)
                          );
                          return (
                            <CardItem
                              data={ticket}
                              ticketSelector={ticketSelector}
                              key={ticket.TicketID}
                              selected={selected > -1}
                              DrawNumbers={[]}
                            />
                          );
                        })
                      : ""}
                  </div>
                ))}
              </OwlCarousel>

              <FooterTable
                piceCard={piceCard}
                handleBuyTicket={() => {
                  requestTicketList();
                }}
              />
            </>
          ):(
            <Grid container direction="row" justify="center" alignContent="center" alignItems="center">
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
         </Grid>
          )
        ) : (
          <div className="loadingText">{interpreter("completeCountDown")} </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps2 = (state) => {
  const todos = getBingoOffline(state);
  const isComplete = todos.isComplete;
  return { isComplete };
};
export default connect(mapStateToProps2)(CardSelected);
//export default CardSelected;
