import { APIClient } from "./_api";
let base = "/user/financial";

export function getPaymentLink() {
  let bodyFormData = new FormData();
  let userAuth = localStorage.getItem("userAuth");

  bodyFormData.append("userAuth", userAuth || "");
  return APIClient.post(`${base}/getPaymentLink/`, bodyFormData);
}
export function getWithdrawalLink() {
  let bodyFormData = new FormData();
  let userAuth = localStorage.getItem("userAuth");

  bodyFormData.append("userAuth", userAuth || "");
  return APIClient.post(`${base}/getWithdrawalLink/`, bodyFormData);
}

export function perfectCharge(values) {
  let bodyFormData = new FormData();
  let userAuth = localStorage.getItem("userAuth");
  for (let key in values) {
    bodyFormData.append(key, values[key]);
  }
  bodyFormData.append("userAuth", userAuth || "");
  return APIClient.post(`${base}/perfectCharge/`, bodyFormData);
}
