/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from "react";

// API

// Components

import Layout from "../../components/layout/main";
import Menu from "./menu.js";
import ProfileCard from "./profile_card.js";
import Title from "./title";
import { user } from "../../Api";
import { interpreter } from "../../utills";
import SweetAlert from "../../components/sweetalert/v";

import Img from "../../assets/images/ezafi/cardId.png";

class Dashboard extends Component {
  state = {
    me: "",
    disableSendCode: false,
    sendButtonTitle: interpreter("sendCode"),
    code: "",
    min: 1,
    sec: 59,
    Authentication: { file: "", src: "" },
    cardAuthentication: { file: "", src: "", cardNumber: "" },
    meliCard: true
  };
  timer;
  componentDidMount() {
    this.setData();
    this.meliCard();
  }
  setData = async () => {
    let data = window.localStorage.getItem("data");
    this.setState({ me: JSON.parse(data) });
  };

  sendSmS = async code => {
    if (!code) {
      this.setState({
        disableSendCode: true
      });
      this.setTimer();
      setTimeout(() => {
        this.setState({
          disableSendCode: false,
          sendButtonTitle: interpreter("resendCode")
        });
      }, 119000);
    }
    let viewModel = code ? { code: code, type: "verify" } : { type: "send" };
    await user.smsVerification(viewModel);
  };
  setTimer = () => {
    this.timer = setInterval(() => {
      if (this.state.sec > 0) {
        this.setState({ sec: --this.state.sec });
      } else {
        this.setState({ min: --this.state.min, sec: 59 });
      }
      if (this.state.min === 0 && this.state.sec === 0) {
        clearInterval(this.timer);
        this.setState({ min: 1, sec: 59 });
      }
    }, 1000);
  };

  handlePic = async e => {
    if (e.target.files[0]) {
      let file = e.target.files[0],
        src = URL.createObjectURL(e.target.files[0]);

      this.setState({
        [e.target.name]: { ...this.state[e.target.name], file: file, src: src }
      });
    }
  };

  meliCard = async () => {
    const viewModel = {
      type: "info"
    };

    const { data } = await user.identityAuthentication(viewModel);
    if (data.success) {
      this.setState({ meliCard: data.addNewSubmissionPermission });
    }
  };
  handleSubmitIdentity = async () => {
    const viewModel = {
      type: "add",
      cardSelfie: this.state.Authentication.file
    };
    await user.identityAuthentication(viewModel);
  };
  handleSubmitCard = async () => {
    const viewModel = {
      type: "add",
      cardSelfie: this.state.cardAuthentication.file,
      cardNumber: this.state.cardAuthentication.cardNumber
    };

    await user.cardAuthentication(viewModel);
  };

  // مودال کارت ملی
  helpCard = () => {
    SweetAlert({
      allowOutsideClick: true,
      title: interpreter("howCaptureMeliCard"),
      width: "60vw",
      html: (
        <>
          <div className="text-justify mb">
            <p>{interpreter("howCaptureMeliCardDescription")}</p>
          </div>
          <div className="large-6 float-center text-center">
            <img src={Img} alt="" />
          </div>
        </>
      )
    });
  };

  render() {
    const {
      me,
      disableSendCode,
      sendButtonTitle,
      code,
      Authentication,
      cardAuthentication,
      meliCard
    } = this.state;

    return (
      <div>
        {/* breadcrumb */}
        <div className="row">
          <div className="large-3 medium-4 columns">
            <div className="dashboard_sidebar">
              <ProfileCard />
              <Menu />
            </div>
          </div>

          {/* start content */}
          <div className="large-9 medium-8 columns">
            <div className="dashboard_panel">
              <Title title={interpreter("Authentication")} icon="door-lock" />
              <br />
              <div className="mb">
                <p
                  className="pointer underline"
                  onClick={() => this.helpCard()}
                >
                  {interpreter("howCaptureMeliCard")}
                </p>
              </div>
              <br />

              {/* کارت ملی */}
              {meliCard ? (
                <>
                  <div className="mb">
                    {interpreter("identityAuthentication")}
                  </div>
                  <div className="row mb">
                    <div className="medium-7 columns">
                      <label className="uploaderImage ">
                        <p>{interpreter("selectFileMessage")}</p>
                        <img src={Authentication.src} alt="" />
                        <input
                          name="Authentication"
                          type="file"
                          accept="image/*"
                          onChange={this.handlePic}
                        />
                      </label>
                    </div>
                    <div className="medium-5 columns">
                      <div className="text_panel ">
                        {interpreter("Authenticationityiden")}
                      </div>
                    </div>

                    <div className="columns text-left">
                      <button
                        className="button confrim"
                        type="button"
                        onClick={this.handleSubmitIdentity}
                      >
                        {interpreter("requstIdentityAuthentication")}
                      </button>
                    </div>
                  </div>
                  <hr />
                </>
              ) : (
                ""
              )}

              {/* ==== افزودن کارت ===== */}
              <div className="mb">{interpreter("cardAuthentication")}</div>

              <div className="row mb">
                <div className="medium-7 columns">
                  <div className="form_control">
                    <input
                      type="text"
                      placeholder={interpreter("cardNumber")}
                      onChange={({ target }) =>
                        this.setState({
                          cardAuthentication: {
                            ...this.state.cardAuthentication,
                            cardNumber: target.value
                          }
                        })
                      }
                      value={cardAuthentication.cardNumber}
                    />
                  </div>
                  <label>{interpreter("addCardBankPic")}</label>
                  <label className="uploaderImage ">
                    <p>{interpreter("selectFileMessage")}</p>
                    <img src={cardAuthentication.src} alt="" />
                    <input
                      name="cardAuthentication"
                      type="file"
                      accept="image/*"
                      onChange={this.handlePic}
                    />
                  </label>
                </div>
                <div className="medium-5 columns">
                  <div className="text_panel ">
                    {interpreter("cardAuthenticationMessage")}
                  </div>
                </div>

                <div className="columns text-left">
                  <button
                    className="button confrim"
                    type="button"
                    onClick={this.handleSubmitCard}
                  >
                    {interpreter("requstCardAuthentication")}
                  </button>
                </div>
              </div>

              {/* ===== */}

              {/* {!me.userMobileHasVerified ? (
                <>
                  <hr />
                  <div className="mb">
                    {interpreter("AuthenticationMobile")}
                  </div>
                  <div className="row">
                    <div className="large-6 columns">
                      <div className="form_control">
                        <input type="text" value={me.userMobile} disabled />
                      </div>
                    </div>
                    <div className="large-6 columns">
                      <div className="form_control">
                        <input
                          type="text"
                          placeholder={interpreter("enterCode")}
                          onChange={({ target }) =>
                            this.setState({ code: target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="columns">
                      <div className="text-left">
                        <button className="button cancel">
                          {interpreter("reject")}
                        </button>
                        &nbsp;
                        {disableSendCode ? (
                          <button
                            type="button"
                            onClick={() => this.sendSmS()}
                            className="button confrim"
                            disabled={disableSendCode}
                          >
                            {`${this.state.min}:${this.state.sec}`}
                          </button>
                        ) : (
                            <button
                              type="button"
                              onClick={() => this.sendSmS()}
                              className="button confrim"
                              disabled={disableSendCode}
                            >
                              {sendButtonTitle}
                            </button>
                          )}
                        &nbsp;
                        <button
                          type="button"
                          onClick={() => this.sendSmS(code)}
                          className="button confrim"
                        >
                          {interpreter("submitCode")}
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                  ""
                )} */}
              {/* // end === */}
            </div>
          </div>
          {/* // end content */}
        </div>
      </div>
    );
  }
}
export default Layout(Dashboard);
