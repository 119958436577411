import axios from "axios";

// let baseURL =
//   process.env.NODE_ENV === "development"
//     ? window.base_api_Address
//     : `https://api.msv-otomotiv.com/`;
// const baseURLPIC =
//   process.env.NODE_ENV === "development"
//     ? window.base_api_Pic
//     : `https://msv-otomotiv.com/`;


const baseURL =
  process.env.NODE_ENV === "development"
    ? window.base_api_Address
    : `https://api.${window.location.hostname.replaceAll("www.", "")}`;
const baseURLPIC =
  process.env.NODE_ENV === "development"
    ? window.base_api_Pic
    : `https://${window.location.hostname.replaceAll("www.", "")}`;

const APIClient = axios.create({ baseURL });
const APIClientRippo = axios.create({
  baseURL: `${baseURL}/Lotto`,
});
const APIClientRippoNotError = axios.create({
  baseURL: `${baseURL}/Lotto`,
});
const APIClientRippo2 = axios.create({
  baseURL: `${baseURL}/`,
});
const APIClientDaberna = axios.create({
  baseURL: `${baseURL}/Daberna`,
});
const APIClientDabernaNoError = axios.create({
  baseURL: `${baseURL}/Daberna`,
});

const APILiveBingo = axios.create({
  baseURL: `${baseURL}/liveBingo`,
});
const APILiveBingoNoError = axios.create({
  baseURL: `${baseURL}/liveBingo`,
});

export { APIClient, APIClientRippo, APIClientRippoNotError, APIClientRippo2, APIClientDaberna, APIClientDabernaNoError, baseURL, APILiveBingoNoError, baseURLPIC, APILiveBingo };
