import React, { Component } from "react";

// API

// Components
import { interpreter } from "../../utills";
import { Formik, Form, Field } from "formik";
import { ToastContainer } from "react-toastr";
import { Link, Redirect } from "react-router-dom";
import Layout from "../../components/layout/main";
import { baseURL } from "../../Api/_api";

let container;

class Login extends Component {
  state = { Redirect: false, imageRepeat: "" };
  initialValues = {
    email: "",
    captcha: "",
  };
  componentDidMount() {
    this.imageRepeater();
  }
  handleSubmit = async (values, action) => {
    try {
      const viewModel = { ...values, type: "request" };

      var formdata = new FormData();
      for (let key in viewModel) {
        formdata.append(key, viewModel[key]);
      }
      var requestOptions = {
        method: "POST",
        body: formdata,
        mode: "cors",
        credentials: "include",
        redirect: "follow",
      };

      const result = await fetch(
        baseURL + "/user/forgetPassword/",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          return result;
        });
      const data = JSON.parse(result);

      if (data.success) {
        container.success(`${interpreter(data.message)}`, ``, {
          closeButton: true,
          timeOut: 2000,
        });
        await this.setState({ Redirect: true });
        this.imageRepeater();
      } else {
        container.error(`${interpreter(data.message)}`, ``, {
          closeButton: true,
          timeOut: 2000,
        });

        this.imageRepeater();
      }
    } catch (error) {
      console.error(error);
    }
  };

  imageRepeater = async () => {
    await this.setState({ imageRepeat: "" });

    this.setState({
      imageRepeat: `${baseURL}/captcha/?_${Math.floor(
        Math.random() * 10000000
      )}`,
    });
  };
  render() {
    return (
      <div>
        <ToastContainer
          ref={(r) => (container = r)}
          className="toast-bottom-right"
        />
        <div className="row columns">
          <div className="large-8 medium-12 small-12 float-center ">
            <div className="authentication_wrapper">
              <div className="image_box">
                {/* <div className="text"> {interpreter("recoverPassword")}</div> */}
              </div>
              {this.state.Redirect && <Redirect to="/forget-pass-step2" />}
              <div className="content_box">
                <Formik
                  initialValues={this.initialValues}
                  onSubmit={this.handleSubmit}
                >
                  {(props) => (
                    <Form onSubmitCapture={props.handleSubmit}>
                      <div className="form_control">
                        <Field
                          name="email"
                          placeholder={interpreter("enterYourEmail")}
                          type="text"
                        />
                      </div>
                      <div className="form_control captcha">
                        <Field
                          name="captcha"
                          placeholder={interpreter("captcha")}
                          type="text"
                        />
                        <img src={this.state.imageRepeat} alt="" />
                      </div>
                      <div
                        className="display_flex align_items_center pointer"
                        onClick={this.imageRepeater}
                      >
                        <span className="pe-refresh"></span>&nbsp;
                        {interpreter("refreshCode")}
                      </div>
                      <div className="text-left">
                        <button className="button" type="submit">
                          {interpreter("recoverPassword")}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
                <div>
                  <Link to="/login" className="forget_pass_link">
                    {interpreter("doYouHaveAccount")}
                  </Link>
                  <br />
                  <label>{interpreter("doYouWantRegister")}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Layout(Login);
