import React, { useEffect,useState } from "react";

// API
import { archive } from "../../../Api";

// components
import ReactPlayer from "react-player";
import Layout from "../../../components/layout/main";
import {
  interpreter,
  userDataGetter,
  numberWithCommas,
  toJalaliHour,
} from "../../../utills";

// images
import Img1 from "../img/Icon-MajmooeJavayez.png";
import Img2 from "../img/Icon-TedadJaygahBarandegan.png";
import Img3 from "../img/Icon-TedadSherkatKonandegan.png";
import Img4 from "../img/Icon-ZamanGhoreeKeshi.png";
import Img5 from "../img/Icon-ZamanPayan.png";
import Img6 from "../img/Icon-ZamanShuru.png";
import Imgwinner1 from "../img/GoldMedal.png";
import Imgwinner2 from "../img/SilverMedal.png";
import Imgwinner3 from "../img/BronzeMedal.png";

// Style
import "../index.css";
import { Helmet } from "react-helmet";
import LuckyNumbers from './luckyNumbers'

const ArchiveItems=(props)=>  {

  const [info,setInfo]=useState({})
  // state = {
  //   details: "",
  //   Numbers: [],
  //   ticketNumbers: [],
  // };
  // buttonArray = [
  //   { num: 1 },
  //   { num: 2 },
  //   { num: 3 },
  //   { num: 4 },
  //   { num: 5 },
  //   { num: 6 },
  //   { num: 7 },
  // ];
  // componentDidMount = async () => {
  //   await this.getArchive(this.props.match.params.id);
  //   if (this.state.details.tournamentResult) {
  //     this.setState({
  //       ticketNumbers: JSON.parse(this.state.details.tournamentResult),
  //     });
  //   }
  // };

  useEffect(()=>{

    getArchive( localStorage.getItem("tournamentId"))
  },[])
  const getArchive = async (id) => {
    try {
    let { data } = await archive.tournamentsListBingo(id);

      
      setInfo(data)
      // const winnersDetails = JSON.parse(data );
      // this.setState({
      //   details: { ...data, winnersDetails },
      // });
    } catch (error) {
      console.error(error);
    }
  };
 
    let userLang = userDataGetter("userLang", "data");

    // const { details } = this.state;
    // const {
    //   tournamentName,
    //   startDateTime_gregorian,
    //   finishDateTime_gregorian,
    //   drawDateTime_gregorian,
    //   noWinnersSeats,
    //   noWinners,
    //   tournamentTotalPrize,
    //   tournamentDescription,
    //   userHasParticipatedInTournament,
    //   tournamentHasVideo,
    //   tournamentVideoUrl,
    // } = this.state.details;


    return (
      <>
        <Helmet>
          <title> {interpreter("Archive") + " " + info.tournamentName}</title>
        </Helmet>
        <div>
          <div className="row columns archive_item">
            <div className="text_panel mb   background_red">
              <div className="panel_title ">
                {interpreter("winnedList")} { info.tournamentName}
              </div>
              <p>{ info.tournamentDescription}</p>

              {/* مشخص میکند آیا کاربر در این تورنومنت شرکت کرده یا نه */}
              { info.userHasParticipatedInTournament ? (
                <div className={`HasParticipatedInTournament mb`}>
                  <span className="pe-check"></span>&nbsp;
                  {interpreter("userHasParticipatedInTournament")}
                </div>
              ) : (
                ""
              )}

              {/* ویدیوی تورنومنت */}
              { info.tournamentHasVideo ? (
                <div className="large-9 float-center video_box">
                  <ReactPlayer url={ info.tournamentVideoUrl} width="100%" controls={true} />
                </div>
              ) : (
                ""
              )}

              {
               info.tournamentResult  &&
               <LuckyNumbers numbers={JSON.parse(info.tournamentResult)}/>
              }

              {/* { JSON.parse(info.tournamentResult) ? (
                <>
                  <div className="text-center mb">
                    <span className="blue_button not_expand">
                      {interpreter("NumbersComingFromTheCircle")}
                    </span>
                  </div>

                  <div className={`phone_numbers_new click_false livestream`}>
                    {this.buttonArray.map((x, i) => (
                      <button
                        key={x.num}
                        className={` ${
                          this.state.ticketNumbers &&
                          this.state.ticketNumbers.length &&
                          this.state.ticketNumbers[i]
                            ? ""
                            : ""
                        }`}
                      >
                        <span className="num">{this.state.ticketNumbers[i] || ""}</span>
                      </button>
                    ))}
                  </div>
                </>
              ) : (
                ""
              )} */}
            </div>

            <div className="text_panel ">
              <div className="tornoment_description">
                 

                <div className="item">
                  <img src={Img2} alt="" />
                  <span>
                    { info.noWinnersSeats} {interpreter("place")}
                  </span>
                </div>

                

                <div className="item">
                  <img src={Img1} alt="" />
                  {`${numberWithCommas( info.tournamentTotalPrize)} ${interpreter("Toman")}`}
                </div>

                <div className="item">
                  <img src={Img4} alt="" />
                  {!userLang || userLang === "fa"
                    ? toJalaliHour( info.drawDateTime_gregorian)
                    :  info.startDateTime_gregorian}
                </div>

                <div className="item">
                  <img src={Img3} alt="" />
                  { info.noWinners} {interpreter("person")}
                </div>
              </div>
            </div>

            <div className="text_panel ">
              {/* جدول برندگان */}
              <table className="ripo_table2">
                <thead>
                  <tr>
                    <td width="70"></td>
                    <td>{interpreter("row")}</td>
                    {/* <td className="hide-for-small-only">{interpreter("place")}</td> */}
                    <td>{interpreter("personCount")}</td>
                    <td>{interpreter("winPrize")}</td>
                  </tr>
                </thead>
                <tbody>
                  { info.winnersDetails?.map((x, i) => (
                      <tr key={Math.random()}>
                        
                          <td data-title={interpreter("Medal")}>
                            <img
                              src={
                                x.winningPlace === 2 ? Imgwinner1 : x.winningPlace === 1 ? Imgwinner2 : Imgwinner3
                              }
                              alt=""
                            />
                          </td>
                        
                        <td data-title={interpreter("row")}>{i + 1}</td>
                        {/* <td
                          data-title={interpreter("place")}
                          className="hide-for-small-only"
                        >
                          {interpreter(x.name)}
                        </td> */}
                        <td data-title={interpreter("personCount")}>
                          {x.winningPlaceCount}
                        </td>
                        <td data-title={interpreter("winPrize")}>
                          {`${numberWithCommas(x.winningAmount)} ${interpreter("Toman")}`}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {/* // end panel */}
          </div>
        </div>
      </>
    );
  
}

export default Layout(ArchiveItems);
