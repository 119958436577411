import React, { Component } from "react";

// API
import { tournaments } from "../../Api";

// components
import Swal from "sweetalert2";
import LuckyNumber from "./lucky-number";
import { Redirect } from "react-router-dom";
import { Link, Element, scroller } from "react-scroll";
import SweetAlert from "../../components/sweetalert/v";
import { interpreter, numberWithCommas, stringifier } from "../../utills";
import Countdown from "react-countdown";

//
import "./index.css";

// svg icons
import Trash from "../../assets/svg/trash";
import Pencil from "../../assets/svg/edit";
import Accept from "../../assets/svg/accept";
import Reject from "../../assets/svg/reject";

class TornomentNew extends Component {
  state = {
    isTornomentExist:false,
    details: {
      tournamentName: "",
      tournamentType: "",
      startDateTime_gregorian: "",
      startDateTime_jalali: "",
      finishDateTime_gregorian: "",
      finishDateTime_jalali: "",
      drawDateTime_gregorian: "",
      drawDateTime_jalali: "",
      noWinnersSeats: "",
      ticketPrice: "",
      totalPrize: "",
    },
    ticketNumber: [],
    redirectLogin: "",
    redirectRegister: "",
    numberArray: [],
    editIndex: "",
    Tickets: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
    selectedNumber: { ticketNumbers: [], Luckynumber: "" },
    seletedTickets: [],
    newTickets: [],
    luckyNumberDisable: false,
    lucky: "",
    totalTicketPrice: "",
    disableNumber: false,
    id: "",
    userCanEditTickets: "",
    userHasParticipatedInTheTournament: "",
    userAuth: { loginStatus: true, userBalance: 0 },
    totalNewTicketPrice: 0,
    redirect: "",
    newAddedTicket: true,
    backUpTicket: { ticketNumbers: [], Luckynumber: "" },
    finishTime: 0,
    countDownShow: false,
  };

  async componentDidUpdate(prev) {
    if (
      prev.match !== this.props.match &&
      this.props.match.params &&
      this.props.match.params.id
    ) {
      await this.getArchive(this.props.match.params.id);
      await this.setState({ id: this.props.match.params.id });
      let newTickets = localStorage.getItem("newTickets");
      newTickets = JSON.parse(newTickets);

      if (
        newTickets &&
        this.state.seletedTickets.length + newTickets.length <= 14
      ) {
        this.setState({ newTickets: newTickets });
      } else if (newTickets) {
        let newLength = 14 - this.state.seletedTickets.length;
        newTickets.splice(0, newLength);

        this.setState({ newTickets: newTickets });
      }
    }
  }
  async componentDidMount() {
    if (this.props.match.params && this.props.match.params.id) {
      this.getArchive(this.props.match.params.id);
      this.setState({ id: this.props.match.params.id });
    }

    this.numberMaker();
  }

  numberMaker = () => {
    let numberArray = [];
    for (let i = 1; i < 43; i++) {
      numberArray = [...numberArray, i];
    }
    this.setState({ numberArray });
  };
  getArchive = async (id) => {
    try {
      let { data } = await tournaments.tournamentDetails({ tournamentId: id });
     
      if(data.success){
 this.setState({
   isTornomentExist:true,
        details: data,
        userCanEditTickets: data.userCanEditTickets,
        seletedTickets:
          (data.userHasParticipatedInTheTournament &&
            data.participationDetails &&
            data.participationDetails.selections) ||
          [],
        userHasParticipatedInTheTournament:
          data.userHasParticipatedInTheTournament,
        totalTicketPrice:
          (data.userHasParticipatedInTheTournament &&
            data.participationDetails &&
            data.participationDetails.totalPriceOfPurchasedTickets) ||
          0,
        finishTime: data.finishTime_Raw * 1000,
      });
      } 
     
       
    } catch (error) {
      console.error(error);
    }
  };

  sweetRegister = () => {
    SweetAlert({
      allowOutsideClick: true,
      title: interpreter("youDidntLogin"),

      html: (
        <div className="text-center">
          <p>{interpreter("doYouHaveAccount")}</p>
          <div className="small-6 float-center">
            <button
              className="button confrim expanded"
              onClick={() => this.redirecter("redirectLogin")}
            >
              {interpreter("loginButton")}
            </button>
            &nbsp;{interpreter("or")}&nbsp;
            <button
              className="button confrim expanded"
              onClick={() => this.redirecter("redirectRegister")}
            >
              {interpreter("registerButton")}
            </button>
          </div>
        </div>
      ),
    });
  };
  // ==

  redirecter = (elem) => {
    this.setState({ [elem]: true });
    Swal.close();
  };
  handleSubmit = async () => { 
     if(this.state.newTickets.length>0){
try {
      let viewModel = {
        tournamentId: this.state.id,
        type: "add",
        selectionArray: stringifier(this.state.newTickets),
      };
      const { data } = await tournaments.buyTicket(viewModel);
      if (data.success) {
        this.getArchive(this.state.id);
      }
      localStorage.removeItem("newTickets");

      this.setState({
        newTickets: [],
        selectedNumber: { ticketNumbers: [], Luckynumber: "" },
        disableNumber: true,
        editIndex: "",
      });
    } catch (error) {
      console.error(error);
    }
     }

     
    
  };

  handleChangeLucky = async (value) => {
    const { selectedNumber, editIndex, newTickets } = this.state;
    await this.setState({
      selectedNumber: { ...selectedNumber, Luckynumber: value },
    });
    if (editIndex || editIndex === 0) {
      const newTicketsCopy = [...newTickets];
      let thisTicket = { ...newTicketsCopy[editIndex] };
      newTicketsCopy[editIndex] = { ...thisTicket, Luckynumber: value };
      this.setState({ newTickets: newTicketsCopy });
    } else {
      if (selectedNumber.ticketNumbers.length === 6) this.addTicket();
    }
  };

  handleChangeNumber = async (number) => {
    let { seletedTickets, newTickets, editIndex } = this.state;
    let selectedNumber = { ...this.state.selectedNumber };
    let ticketNumbers = [...selectedNumber.ticketNumbers];

    if (seletedTickets.length + newTickets.length < 14) {
      const existNumber = ticketNumbers.findIndex((x) => x === number);

      if (existNumber > -1) {
        ticketNumbers.splice(existNumber, 1);
      } else {
        if (ticketNumbers.length === 6) {
          ticketNumbers.splice(5, 1);
        }
        if (ticketNumbers.length <= 5) {
          ticketNumbers.push(number);
        }
      }
      if (editIndex || editIndex === 0) {
        let newTicketCopy = [...newTickets];
        let editTicket = { ...newTicketCopy[editIndex] };

        const existNumber = editTicket.ticketNumbers.findIndex(
          (x) => x === number
        );
        if (existNumber > -1) {
          editTicket.ticketNumbers.splice(existNumber, 1);
        } else {
          if (editTicket.ticketNumbers.length === 6) {
            editTicket.ticketNumbers.splice(5, 1);
          }
          if (editTicket.ticketNumbers.length <= 5) {
            editTicket.ticketNumbers.push(number);
          }
        }
        newTicketCopy[editIndex].ticketNumbers = editTicket.ticketNumbers;
        this.setState({ newTickets: newTicketCopy });
      }
      selectedNumber.ticketNumbers = ticketNumbers;

      await this.setState({
        selectedNumber: selectedNumber,
      });
      if (
        ticketNumbers.length === 6 &&
        selectedNumber.Luckynumber &&
        !editIndex &&
        editIndex !== 0
      )
        this.addTicket();
    }
  };

  addTicket = () => {
    const { selectedNumber, seletedTickets, newTickets } = this.state;
    const Tickets = [...this.state.Tickets];
    if (seletedTickets.length + newTickets.length < 14) {
      this.calculateTicket([...seletedTickets, ...newTickets, selectedNumber]);
      Tickets.splice(0, 1);
      scroller.scrollTo("tickets", {
        spy: true,
        smooth: true,
        duration: 1000,
        offset: -200,
      });
      this.setState({
        newTickets: [...newTickets, selectedNumber],
        selectedNumber: { ticketNumbers: [], Luckynumber: "" },
        Tickets,
        newAddedTicket: false,
        disableNumber: true,
        luckyNumberDisable: true,
      });
      this.calculateNewTicket([...newTickets, selectedNumber]);
    } else {
      this.setState({ disableNumber: true });
    }
  };
  newTicket = () => {
    this.setState({
      newAddedTicket: true,
      disableNumber: false,
      luckyNumberDisable: false,
    });
  };
  EditedTicket = async () => {
    const { selectedNumber, editIndex } = this.state;
    if (
      selectedNumber.ticketNumbers.length === 6 &&
      selectedNumber.Luckynumber
    ) {
      let newSeletedTickets = [...this.state.newTickets];
      newSeletedTickets.splice(editIndex, 1);
      newSeletedTickets.splice(editIndex, 0, selectedNumber);

      await this.setState({
        newTickets: newSeletedTickets,
        editIndex: "",
        selectedNumber: { ticketNumbers: [], Luckynumber: "" },
        lucky: "",
        disableNumber: this.state.newAddedTicket ? false : true,
        luckyNumberDisable: this.state.newAddedTicket ? false : true,
        backUpTicket: [],
      });
    }
  };

  //* انتخاب شانسی خانه ها
  SelectRandomNumber = async () => {
    const { seletedTickets, newTickets } = this.state;

    let RandomNumbers = [];
    let lucky = "";
    do {
      let num = Math.floor(Math.random() * 41.99 + 1);

      if (RandomNumbers && RandomNumbers.indexOf(num) === -1) {
        RandomNumbers = [...RandomNumbers, num];
      }
    } while (RandomNumbers.length < 6);

    if (RandomNumbers.length === 6) {
      lucky = Math.floor(Math.random() * 5.99 + 1);
    }
    if (this.state.editIndex || this.state.editIndex === 0) {
      this.Reject();
    }
    if (seletedTickets.length + newTickets.length < 14) {
      await this.setState({
        selectedNumber: { ticketNumbers: RandomNumbers, Luckynumber: lucky },
      });
      this.addTicket();
    } else {
      this.setState({ disableNumber: true });
    }
  };

  //* حذف بلیط
  removeTicket = async (i) => {
    const newTickets = [...this.state.newTickets],
      Tickets = [...this.state.Tickets],
      { seletedTickets } = this.state;

    if (seletedTickets.length + newTickets.length < 14) {
      Tickets.unshift(seletedTickets.length + newTickets.length + 1);
    }
    newTickets.splice(i, 1);

    this.calculateTicket([...seletedTickets, ...newTickets]);
    this.setState({
      newTickets,
      Tickets,
      editIndex: "",
      selectedNumber: { ticketNumbers: [], Luckynumber: "" },
      lucky: "",

      backUpTicket: { ticketNumbers: [], Luckynumber: "" },
    });
  };

  //* ویرایش بلیط
  editTicket = async (i) => {
    const newTickets = [...this.state.newTickets],
      Tickets = [...this.state.Tickets];
    const EditedTicket = { ...newTickets[i] };

    this.setState({
      Tickets,
      editIndex: i,
      selectedNumber: EditedTicket,
      lucky: EditedTicket[EditedTicket.length - 1],
      luckyNumberDisable: false,
      disableNumber: false,
      backUpTicket: { ...EditedTicket },
    });
  };
  //* محاسبه قیمت بلیط
  calculateTicket = (tickets) => {
    let totalTicketPrice = this.state.details.ticketPrice * tickets.length;
    this.setState({ totalTicketPrice });
  };
  calculateNewTicket = (tickets) => {
    let totalNewTicketPrice = this.state.details.ticketPrice * tickets.length;

    this.setState({ totalNewTicketPrice });
  };

  submitLogin = () => {
    try {
      if (this.state.newTickets) {
        localStorage.setItem(
          "newTickets",
          JSON.stringify(this.state.newTickets)
        );
      }
      localStorage.removeItem("returnPath");

      localStorage.setItem("returnPath", this.props.location.pathname);

      this.setState({ redirect: "/login" });
    } catch (error) {
      console.error(error);
    }
  };
  submitAddBlance = () => {
    try {
      if (this.state.newTickets) {
        localStorage.setItem(
          "newTickets",
          JSON.stringify(this.state.newTickets)
        );
      }
      this.setState({ redirect: "/dashboard-add-blance" });
    } catch (error) {
      console.error(error);
    }
  };
  Reject = () => {
    const { editIndex, backUpTicket } = this.state;

    let newSeletedTickets = [...this.state.newTickets];
    newSeletedTickets.splice(editIndex, 1);
    newSeletedTickets.splice(editIndex, 0, backUpTicket);

    this.setState({
      newTickets: newSeletedTickets,
      editIndex: "",
      selectedNumber: { ticketNumbers: [], Luckynumber: "" },
      lucky: "",
      disableNumber: this.state.newAddedTicket ? false : true,
      luckyNumberDisable: this.state.newAddedTicket ? false : true,
      backUpTicket: { ticketNumbers: [], Luckynumber: "" },
    });
  };
  renderer = ({ days, hours, minutes, seconds, completed }) => {
    // Render a countdown
    return (
      <div className="Countdown">
        <div className="Countdown-col days">
          <div className="Countdown-col-element">
            <strong>{days}</strong>
            <span>{days === 1 ? "Day" : "Days"}</span>
          </div>
        </div>

        <div className="times">
          <div className="Countdown-col">
            <div className="Countdown-col-element">
              <strong>{hours}</strong>
              <span>hours</span>
            </div>
          </div>

          <div className="Countdown-col">
            <div className="Countdown-col-element">
              <strong>{minutes}</strong>
              <span>minutes</span>
            </div>
          </div>

          <div className="Countdown-col">
            <div className="Countdown-col-element">
              <strong>{seconds}</strong>
              <span>seconds</span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  render() {
    const {
      totalPrize,
      tournamentName,
      ticketPrice,
      participationDetails,
    } = this.state.details;
    const userAuth = JSON.parse(localStorage.getItem("data"));

    const {
      numberArray,
      // Tickets,
      selectedNumber,
      seletedTickets,
      luckyNumberDisable,
      disableNumber,
      editIndex,
      userCanEditTickets,
      userHasParticipatedInTheTournament,
      newTickets,
      newAddedTicket,
      totalNewTicketPrice,
      redirect,
      finishTime,
      countDownShow,
      isTornomentExist
    } = this.state;
    return (
      <>
        <div className="row columns">
          {redirect ? <Redirect to={redirect} /> : ""}
          <div className="gamebox_new gamebox_with3_columns">

{isTornomentExist?
            <div className="row">
              <div className="large-9 medium-9 small-12 columns ">
                <div className="row col-1">
                  <div className="medium-6 columns column-ticket">
                    <Element name="tickets">
                      <div className="body-game-right">
                        {seletedTickets.map((seletedTicket, i) => (
                          <div className="game-number-row complated" key={i}>
                            <div className="tag">{i + 1}</div>
                            <div className="selected-numbers">
                              {seletedTicket &&
                              seletedTicket.ticketNumbers.length
                                ? seletedTicket.ticketNumbers.map((number) => {
                                    return (
                                      <div className="num" key={number}>
                                        {number}
                                      </div>
                                    );
                                  })
                                : ""}
                            </div>
                            <div className="final-number">
                              {seletedTicket.Luckynumber
                                ? seletedTicket.Luckynumber
                                : ""}
                            </div>
                            {userCanEditTickets ||
                            !userHasParticipatedInTheTournament ? (
                              <div
                                className="edit"
                                onClick={() => this.editTicket(i)}
                              >
                                <Pencil />
                              </div>
                            ) : (
                              ""
                            )}
                            {!userHasParticipatedInTheTournament ? (
                              <div
                                className="delete"
                                onClick={() => this.removeTicket(i)}
                              >
                                <Trash />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        ))}
                        {newTickets.map((newTicket, i) => (
                          <div
                            className={`game-number-row ${
                              editIndex === i ? " in_edit" : "complated"
                            }`}
                            key={i}
                          >
                            <div className="tag">
                              {seletedTickets.length + i + 1}
                            </div>
                            <div className="selected-numbers">
                              {newTicket && newTicket.ticketNumbers.length
                                ? newTicket.ticketNumbers.map((number) => {
                                    return (
                                      <div className="num" key={number}>
                                        {number}
                                      </div>
                                    );
                                  })
                                : ""}
                            </div>
                            {!editIndex && editIndex !== 0 ? (
                              <div
                                className="svg_icon"
                                onClick={() => this.editTicket(i)}
                              >
                                <Pencil />
                              </div>
                            ) : (
                              ""
                            )}
                            {editIndex === i ? (
                              <>
                                <div
                                  className="svg_icon"
                                  onClick={() => this.Reject(i)}
                                >
                                  <Reject />
                                </div>
                                {selectedNumber.ticketNumbers.length === 6 &&
                                selectedNumber.Luckynumber ? (
                                  <div
                                    className="svg_icon"
                                    onClick={() => this.EditedTicket(i)}
                                  >
                                    <Accept />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              ""
                            )}
                            <div
                              className="svg_icon"
                              onClick={() => this.removeTicket(i)}
                            >
                              <Trash />
                            </div>
                            <div className="final-number">
                              {newTicket.Luckynumber
                                ? newTicket.Luckynumber
                                : ""}
                            </div>
                          </div>
                        ))}

                        {seletedTickets.length + newTickets.length + 1 <= 14 &&
                        newAddedTicket ? (
                          <div className="game-number-row current">
                            <div className="tag">
                              {seletedTickets.length + newTickets.length + 1}
                            </div>
                            <div className="selected-numbers">
                              {selectedNumber.ticketNumbers.length &&
                              !editIndex &&
                              editIndex !== 0 ? (
                                selectedNumber.ticketNumbers.map((number) => {
                                  return (
                                    <div key={number} className="num">
                                      {number}
                                    </div>
                                  );
                                })
                              ) : (
                                <>
                                  <div className="num"></div>
                                  <div className="num"></div>
                                  <div className="num"></div>
                                  <div className="num"></div>
                                  <div className="num"></div>
                                  <div className="num"></div>
                                </>
                              )}
                            </div>
                            <div className="final-number">
                              {selectedNumber.Luckynumber &&
                              !editIndex &&
                              editIndex !== 0
                                ? selectedNumber.Luckynumber
                                : ""}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </Element>
                    <div className="show-for-small-only mb">
                      {userAuth && userAuth.loginStatus ? (
                        userAuth.userBalance >= totalNewTicketPrice ? (
                          <div className="text-center">
                            <button
                              className="blue_button button"
                              onClick={this.handleSubmit}
                            >
                              {interpreter("submitTickets")}
                            </button>
                          </div>
                        ) : (
                          <div className="text-center">
                            <button
                              className="blue_button button"
                              onClick={this.submitAddBlance}
                            >
                              {interpreter("balanceIsNotEnough")}
                            </button>
                          </div>
                        )
                      ) : (
                        <div className="text-center">
                          <button
                            className="blue_button button"
                            onClick={this.submitLogin}
                          >
                            {interpreter("YouMustLogin")}
                          </button>
                        </div>
                      )}
                    </div>

                    <div className="text-center">
                      <Link
                        activeclassName="active"
                        to="Numbers"
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        <button
                          className="button button_outline"
                          type="button"
                          onClick={this.newTicket}
                        >
                          {interpreter("addNewTicket")} &nbsp;
                          <span className="pe-plus"></span>
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="medium-6 columns column-numbers">
                    <Element name="Numbers">
                      <div className="countDown show-for-small-only">
                        <div className="title">{tournamentName}</div>
                        <div className="countdown-wrapper">
                          {finishTime && countDownShow ? (
                            <Countdown
                              date={finishTime}
                              intervalDelay={0}
                              precision={3}
                              renderer={this.renderer}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {/* // end countdown */}

                      <div className="jackpot_prize show-for-small-only">
                        <div className="title">
                          {interpreter("JackpotPrize")}
                        </div>
                        <div className="prize">
                          {`${numberWithCommas(totalPrize)}`}
                          <span>{interpreter("Toman")}</span>
                        </div>
                      </div>
                      <div className="body-game-left mb">
                        <div className="numerals_game">
                          {numberArray.map((num) => (
                            <div
                              className={`number ${
                                selectedNumber.ticketNumbers.findIndex(
                                  (x) => num === x
                                ) > -1
                                  ? "selected"
                                  : ""
                              } ${disableNumber ? "disabledSpan" : ""}`}
                              key={num}
                              onClick={() => this.handleChangeNumber(num)}
                            >
                              {num}
                            </div>
                          ))}
                        </div>
                        <hr />
                        <LuckyNumber
                          luckyNumberDisable={luckyNumberDisable}
                          handleChangeLucky={this.handleChangeLucky}
                          lucky={selectedNumber.Luckynumber}
                        />
                        <div className="text-center">
                          <button
                            onClick={this.SelectRandomNumber}
                            className="button confrim"
                          >
                            {interpreter("randomTicket")}
                          </button>

                          {/* ثبت بلیط */}
                          <div className="hide-for-small-only">
                            {userAuth && userAuth.loginStatus ? (
                              userAuth.userBalance >= totalNewTicketPrice ? (
                                <div className="text-center">
                                  <button
                                    className="blue_button button"
                                    onClick={this.handleSubmit}
                                  >
                                    {interpreter("submitTickets")}
                                  </button>
                                </div>
                              ) : (
                                <div className="text-center">
                                  <button
                                    className="blue_button button"
                                    onClick={this.submitAddBlance}
                                  >
                                    {interpreter("balanceIsNotEnough")}
                                  </button>
                                </div>
                              )
                            ) : (
                              <div className="text-center">
                                <button
                                  className="blue_button button"
                                  onClick={this.submitLogin}
                                >
                                  {interpreter("YouMustLogin")}
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Element>
                  </div>
                </div>
              </div>

              {/*  */}
              <div className="large-5 medium-6 small-12 columns col-2">
                <div className="countDown hide-for-small-only">
                  <div className="title">{tournamentName}</div>
                  <div className="countdown-wrapper">
                    {finishTime && countDownShow ? (
                      <Countdown
                        date={finishTime}
                        intervalDelay={0}
                        precision={3}
                        renderer={this.renderer}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {/* // end countdown */}

                <div className="jackpot_prize hide-for-small-only">
                  <div className="title">{interpreter("JackpotPrize")}</div>
                  <div className="prize">
                    {`${numberWithCommas(totalPrize)}`}
                    <span>{interpreter("Toman")}</span>
                  </div>
                </div>

                <div className="show_ticketPrices_numbers">
                  {/* نمایش مبلغ هر بلیط */}
                  <div className="display_flex align_items_center justify_content_between mb">
                    <div className="name">{interpreter("eachTicketPrice")}</div>
                    <div className="price">
                      {numberWithCommas(ticketPrice)}
                      <span>{interpreter("Toman")}</span>
                    </div>
                  </div>
                  {/* اگه بلیط خریداری کرده بود مبلغ کل بلیط های خریداری شده نمایش میده */}
                  {userHasParticipatedInTheTournament ? (
                    <div className="display_flex align_items_center justify_content_between">
                      <div className="name">
                        {interpreter("The_amount_selected")}
                      </div>
                      <div className="price">
                        {numberWithCommas(
                          participationDetails.totalPriceOfPurchasedTickets
                        )}
                        <span>{interpreter("Toman")}</span>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
         :
         <div className="row">

         </div>
         }
          </div>
        </div>
      </>
    );
  }
}

export default TornomentNew;
