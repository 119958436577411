import React, { Component } from "react";

// API
import { tournaments } from "../../Api";

// components
import {
  interpreter,
  localStorgeSubscriber,
  numberWithCommas,
  toJalaliHour,
  stringifier,
} from "../../utills";
import Swal from "sweetalert2";
import LuckyNumber from "./lucky-number";
import Trash from "../../assets/svg/trash";
import Pencil from "../../assets/svg/edit";
import Layout from "../../components/layout";
import SweetAlert from "../../components/sweetalert/v";
import Accept from "../../assets/svg/accept";
import Reject from "../../assets/svg/reject";
// Style
import "./index.css";
import { Element, scroller } from "react-scroll";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

class TornomentNew extends Component {
  state = {
    details: {
      tournamentName: "",
      tournamentType: "",
      startDateTime_gregorian: "",
      startDateTime_jalali: "",
      finishDateTime_gregorian: "",
      finishDateTime_jalali: "",
      drawDateTime_gregorian: "",
      drawDateTime_jalali: "",
      noWinnersSeats: "",
      ticketPrice: "",
      totalPrize: "",
    },
    ticketNumber: [],
    redirectLogin: "",
    redirectRegister: "",
    numberArray: [],
    editIndex: "",
    Tickets: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
    selectedNumber: { ticketNumbers: [], Luckynumber: "" },
    seletedTickets: [],
    newTickets: [],
    luckyNumberDisable: false,
    lucky: "",
    totalTicketPrice: "",
    disableNumber: false,
    id: "",
    userCanEditTickets: "",
    userHasParticipatedInTheTournament: "",
    userAuth: { loginStatus: true, userBalance: 0 },
    totalNewTicketPrice: 0,
    redirect: "",
    backUpTicket: { ticketNumbers: [], Luckynumber: "" },
  };

  async componentDidMount() {
    await this.getArchive(this.props.match.params.id);
    let newTickets = localStorage.getItem("newTickets");
    newTickets = JSON.parse(newTickets);
    const userAuth = JSON.parse(localStorage.getItem("data"));

    this.setState({ userAuth });
    if (
      newTickets &&
      this.state.seletedTickets.length + newTickets.length <= 14
    ) {
      this.setState({ newTickets: newTickets });
    } else if (newTickets) {
      let newLength = 14 - this.state.seletedTickets.length;
      newTickets.splice(0, newLength);

      this.setState({ newTickets: newTickets });
    }
    this.setState({ id: this.props.match.params.id });
    this.numberMaker();
  }

  numberMaker = () => {
    let numberArray = [];
    for (let i = 1; i < 43; i++) {
      numberArray = [...numberArray, i];
    }
    this.setState({ numberArray });
  };

  getArchive = async (id) => {
    try {
      let { data } = await tournaments.tournamentDetails({ tournamentId: id });

      await this.setState({
        details: data,
        userCanEditTickets: data.userCanEditTickets,
        seletedTickets:
          (data.userHasParticipatedInTheTournament &&
            data.participationDetails &&
            data.participationDetails.selections) ||
          [],
        userHasParticipatedInTheTournament:
          data.userHasParticipatedInTheTournament,
        totalTicketPrice:
          (data.userHasParticipatedInTheTournament &&
            data.participationDetails &&
            data.participationDetails.totalPriceOfPurchasedTickets) ||
          0,
        newTickets: [],
      });
    } catch (error) {
      console.error(error);
    }
  };

  sweetRegister = () => {
    SweetAlert({
      allowOutsideClick: true,
      title: interpreter("youDidntLogin"),

      html: (
        <div className="text-center">
          <p>{interpreter("doYouHaveAccount")}</p>
          <div className="small-6 float-center">
            <button
              className="button confrim expanded"
              onClick={() => this.redirecter("redirectLogin")}
            >
              {interpreter("loginButton")}
            </button>
            &nbsp;{interpreter("or")}&nbsp;
            <button
              className="button confrim expanded"
              onClick={() => this.redirecter("redirectRegister")}
            >
              {interpreter("registerButton")}
            </button>
          </div>
        </div>
      ),
    });
  };
  // ==

  redirecter = (elem) => {
    this.setState({ [elem]: true });
    Swal.close();
  };

  handleSubmit = async () => {
    try {
      let viewModel = {
        tournamentId: this.state.id,
        type: "add",
        selectionArray: stringifier(this.state.newTickets),
      };
      const { data } = await tournaments.buyTicket(viewModel);
      if (data.success) {
        this.getArchive(this.state.id);
      }
      this.setState({
        selectedNumber: { ticketNumbers: [], Luckynumber: "" },

        editIndex: "",
      });
    } catch (error) {
      console.error(error);
    }
  };
  handleChangeLucky = async (value) => {
    const { selectedNumber, editIndex, newTickets } = this.state;
    await this.setState({
      selectedNumber: { ...selectedNumber, Luckynumber: value },
    });
    if (editIndex || editIndex === 0) {
      const newTicketsCopy = [...newTickets];
      let thisTicket = { ...newTicketsCopy[editIndex] };
      newTicketsCopy[editIndex] = { ...thisTicket, Luckynumber: value };
      this.setState({ newTickets: newTicketsCopy });
    } else {
      if (selectedNumber.ticketNumbers.length === 6) this.addTicket();
    }
  };

  handleChangeNumber = async (number) => {
    let { seletedTickets, newTickets, editIndex } = this.state;
    let selectedNumber = { ...this.state.selectedNumber };
    let ticketNumbers = [...selectedNumber.ticketNumbers];

    if (seletedTickets.length + newTickets.length < 14) {
      const existNumber = ticketNumbers.findIndex((x) => x === number);

      if (existNumber > -1) {
        ticketNumbers.splice(existNumber, 1);
      } else {
        if (ticketNumbers.length === 6) {
          ticketNumbers.splice(5, 1);
        }
        if (ticketNumbers.length <= 5) {
          ticketNumbers.push(number);
        }
      }
      if (editIndex || editIndex === 0) {
        let newTicketCopy = [...newTickets];
        let editTicket = { ...newTicketCopy[editIndex] };

        const existNumber = editTicket.ticketNumbers.findIndex(
          (x) => x === number
        );
        if (existNumber > -1) {
          editTicket.ticketNumbers.splice(existNumber, 1);
        } else {
          if (editTicket.ticketNumbers.length === 6) {
            editTicket.ticketNumbers.splice(5, 1);
          }
          if (editTicket.ticketNumbers.length <= 5) {
            editTicket.ticketNumbers.push(number);
          }
        }
        newTicketCopy[editIndex].ticketNumbers = editTicket.ticketNumbers;
        this.setState({ newTickets: newTicketCopy });
      }
      selectedNumber.ticketNumbers = ticketNumbers;

      await this.setState({
        selectedNumber: selectedNumber,
      });
      if (
        ticketNumbers.length === 6 &&
        selectedNumber.Luckynumber &&
        !editIndex &&
        editIndex !== 0
      )
        this.addTicket();
    }
  };
  addTicket = () => {
    const { selectedNumber, seletedTickets, newTickets } = this.state;
    const Tickets = [...this.state.Tickets];
    if (seletedTickets.length + newTickets.length < 14) {
      this.calculateTicket([...seletedTickets, ...newTickets, selectedNumber]);
      Tickets.splice(0, 1);
      scroller.scrollTo("tickets", {
        spy: true,
        smooth: true,
        duration: 1000,
        offset: -200,
      });
      this.setState({
        newTickets: [...newTickets, selectedNumber],
        selectedNumber: { ticketNumbers: [], Luckynumber: "" },
        Tickets,
      });
      this.calculateNewTicket([...newTickets, selectedNumber]);
    } else {
      this.setState({ disableNumber: true });
    }
  };
  EditedTicket = async () => {
    const { selectedNumber, editIndex } = this.state;
    if (
      selectedNumber.ticketNumbers.length === 6 &&
      selectedNumber.Luckynumber
    ) {
      let newSeletedTickets = [...this.state.newTickets];
      newSeletedTickets.splice(editIndex, 1);
      newSeletedTickets.splice(editIndex, 0, selectedNumber);

      await this.setState({
        newTickets: newSeletedTickets,
        editIndex: "",
        selectedNumber: { ticketNumbers: [], Luckynumber: "" },
        lucky: "",

        backUpTicket: [],
      });
    }
  };

  //* انتخاب شانسی خانه ها
  SelectRandomNumber = async () => {
    const { seletedTickets, newTickets } = this.state;

    let RandomNumbers = [];
    let lucky = "";
    do {
      let num = Math.floor(Math.random() * 41.99 + 1);

      if (RandomNumbers && RandomNumbers.indexOf(num) === -1) {
        RandomNumbers = [...RandomNumbers, num];
      }
    } while (RandomNumbers.length < 6);

    if (RandomNumbers.length === 6) {
      lucky = Math.floor(Math.random() * 5.99 + 1);
    }
    if (this.state.editIndex || this.state.editIndex === 0) {
      this.Reject();
    }
    if (seletedTickets.length + newTickets.length < 14) {
      await this.setState({
        selectedNumber: { ticketNumbers: RandomNumbers, Luckynumber: lucky },
      });
      this.addTicket();
    } else {
      this.setState({ disableNumber: true });
    }
  };
  //* حذف بلیط
  removeTicket = async (i) => {
    const newTickets = [...this.state.newTickets],
      Tickets = [...this.state.Tickets],
      { seletedTickets } = this.state;

    if (seletedTickets.length + newTickets.length < 14) {
      Tickets.unshift(seletedTickets.length + newTickets.length + 1);
    }
    newTickets.splice(i, 1);

    this.calculateTicket([...seletedTickets, ...newTickets]);
    this.setState({
      newTickets,
      Tickets,
      editIndex: "",
      selectedNumber: { ticketNumbers: [], Luckynumber: "" },
      lucky: "",

      backUpTicket: { ticketNumbers: [], Luckynumber: "" },
    });
  };

  //* ویرایش بلیط
  editTicket = async (i) => {
    const newTickets = [...this.state.newTickets],
      Tickets = [...this.state.Tickets];
    const EditedTicket = { ...newTickets[i] };

    this.setState({
      Tickets,
      editIndex: i,
      selectedNumber: EditedTicket,
      lucky: EditedTicket[EditedTicket.length - 1],
      luckyNumberDisable: false,
      disableNumber: false,
      backUpTicket: { ...EditedTicket },
    });
  };

  //* محاسبه قیمت بلیط
  calculateTicket = (tickets) => {
    let totalTicketPrice = this.state.details.ticketPrice * tickets.length;
    this.setState({ totalTicketPrice });
  };

  calculateNewTicket = (tickets) => {
    let totalNewTicketPrice = this.state.details.ticketPrice * tickets.length;
    this.setState({ totalNewTicketPrice });
  };

  submitLogin = () => {
    try {
      if (this.state.newTickets) {
        localStorage.setItem(
          "newTickets",
          JSON.stringify(this.state.newTickets)
        );
      }
      localStorage.removeItem("returnPath");
      localStorage.setItem("returnPath", this.props.location.pathname);

      this.setState({ redirect: "/login" });
    } catch (error) {
      console.error(error);
    }
  };
  submitAddBlance = () => {
    try {
      if (this.state.newTickets) {
        localStorage.setItem(
          "newTickets",
          JSON.stringify(this.state.newTickets)
        );
      }
      this.setState({ redirect: "/dashboard/add-blance" });
    } catch (error) {
      console.error(error);
    }
  };
  Reject = () => {
    const { editIndex, backUpTicket } = this.state;

    let newSeletedTickets = [...this.state.newTickets];
    newSeletedTickets.splice(editIndex, 1);
    newSeletedTickets.splice(editIndex, 0, backUpTicket);

    this.setState({
      newTickets: newSeletedTickets,
      editIndex: "",
      selectedNumber: { ticketNumbers: [], Luckynumber: "" },
      lucky: "",
      disableNumber: false,
      luckyNumberDisable: false,
      backUpTicket: { ticketNumbers: [], Luckynumber: "" },
    });
  };

  render() {
    const {
      tournamentName,
      drawDateTime_gregorian,
      ticketPrice,
      totalPrize,
      drawDateTime_jalali,
    } = this.state.details;
    let { userLang } = localStorgeSubscriber("data");
    const {
      numberArray,
      selectedNumber,
      seletedTickets,
      luckyNumberDisable,
      totalTicketPrice,
      disableNumber,
      editIndex,
      newTickets,
      userAuth,
      totalNewTicketPrice,
      redirect,
    } = this.state;

    return (
      <>
        <Helmet>
          <title>{tournamentName}</title>
        </Helmet>
        <div className="row">
          <div className="medium-4 column">
            <div className="total_countering">
              <div className="title">
                <span>{interpreter("drawTime")}</span>
              </div>
              <div className="value">
                {!userLang || userLang === "fa"
                  ? toJalaliHour(drawDateTime_gregorian)
                  : drawDateTime_jalali}
              </div>
            </div>
          </div>
          <div className="medium-4 column">
            <div className="total_countering">
              <div className="title">
                <span>{interpreter("totalPrize")}</span>
              </div>
              <div className="value">{`${numberWithCommas(
                totalPrize
              )} ${interpreter("Toman")}`}</div>
            </div>
          </div>
          <div className="medium-4 column">
            <div className="total_countering">
              <div className="title">
                <span>{interpreter("tournamentName")}</span>
              </div>
              <div className="value">{tournamentName}</div>
            </div>
          </div>
        </div>

        <div className="row columns">
          <div className="gamebox_new inner_page">
            <div className="row">
              {redirect ? <Redirect to={redirect} /> : ""}

              <div className="large-7 medium-6 small-12 columns col-1">
                <div className="body-game-left mb">
                  <div className="numerals_game">
                    {numberArray.map((num) => (
                      <div
                        className={`number ${
                          selectedNumber.ticketNumbers.findIndex(
                            (x) => num === x
                          ) > -1
                            ? "selected"
                            : ""
                        } ${disableNumber ? "disabledSpan" : ""}`}
                        key={num}
                        onClick={() => this.handleChangeNumber(num)}
                      >
                        {num}
                      </div>
                    ))}
                  </div>
                  <hr />
                  <LuckyNumber
                    luckyNumberDisable={luckyNumberDisable}
                    handleChangeLucky={this.handleChangeLucky}
                    lucky={selectedNumber.Luckynumber}
                  />
                </div>
              </div>
              {/*  */}
              <div className="large-5 medium-6 small-12 columns col-2">
                <Element name="tickets" className="body-game-right">
                  {seletedTickets.map((seletedTicket, i) => (
                    <div className="game-number-row complated" key={i}>
                      <div className="tag">{i + 1}</div>
                      <div className="selected-numbers">
                        {seletedTicket && seletedTicket.ticketNumbers.length
                          ? seletedTicket.ticketNumbers.map((number) => {
                              return (
                                <div className="num" key={number}>
                                  {number}
                                </div>
                              );
                            })
                          : ""}
                      </div>

                      <div className="final-number">
                        {seletedTicket.Luckynumber
                          ? seletedTicket.Luckynumber
                          : ""}
                      </div>
                    </div>
                  ))}
                  {newTickets.map((newTicket, i) => (
                    <div
                      className={`game-number-row ${
                        editIndex === i ? " in_edit" : "complated"
                      }`}
                      key={i}
                    >
                      <div className="tag">{seletedTickets.length + i + 1}</div>
                      <div className="selected-numbers">
                        {newTicket && newTicket.ticketNumbers.length
                          ? newTicket.ticketNumbers.map((number) => {
                              return (
                                <div className="num" key={number}>
                                  {number}
                                </div>
                              );
                            })
                          : ""}
                      </div>
                      {!editIndex && editIndex !== 0 ? (
                        <div
                          className="svg_icon"
                          onClick={() => this.editTicket(i)}
                        >
                          <Pencil />
                        </div>
                      ) : (
                        ""
                      )}
                      {editIndex === i ? (
                        <>
                          <div
                            className="svg_icon"
                            onClick={() => this.Reject(i)}
                          >
                            <Reject />
                          </div>
                          {selectedNumber.ticketNumbers.length === 6 &&
                          selectedNumber.Luckynumber ? (
                            <div
                              className="svg_icon"
                              onClick={() => this.EditedTicket(i)}
                            >
                              <Accept />
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      <div
                        className="svg_icon"
                        onClick={() => this.removeTicket(i)}
                      >
                        <Trash />
                      </div>
                      <div className="final-number">
                        {newTicket.Luckynumber ? newTicket.Luckynumber : ""}
                      </div>
                    </div>
                  ))}

                  {seletedTickets.length + newTickets.length + 1 <= 14 ? (
                    <div className="game-number-row current">
                      <div className="tag">
                        {seletedTickets.length + newTickets.length + 1}
                      </div>
                      <div className="selected-numbers">
                        {selectedNumber.ticketNumbers.length &&
                        !editIndex &&
                        editIndex !== 0 ? (
                          selectedNumber.ticketNumbers.map((number) => {
                            return (
                              <div key={number} className="num">
                                {number}
                              </div>
                            );
                          })
                        ) : (
                          <>
                            <div className="num"></div>
                            <div className="num"></div>
                            <div className="num"></div>
                            <div className="num"></div>
                            <div className="num"></div>
                            <div className="num"></div>
                          </>
                        )}
                      </div>
                      <div className="final-number">
                        {selectedNumber.Luckynumber &&
                        !editIndex &&
                        editIndex !== 0
                          ? selectedNumber.Luckynumber
                          : ""}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* row */}
                </Element>
                {/* // end game body */}

                <div className="Conclusion_row">
                  {interpreter("totalPriceOfPurchasedTickets")}
                  <span className="colored">
                    {numberWithCommas(totalTicketPrice)}
                  </span>
                  {interpreter("Toman")}
                </div>

                <div className="Conclusion_row">
                  {interpreter("eachTicketPrice")}
                  <span className="colored">
                    {numberWithCommas(ticketPrice)}
                  </span>
                  {interpreter("Toman")}
                </div>
                {userAuth && userAuth.loginStatus ? (
                  userAuth.userBalance >= totalNewTicketPrice ? (
                    <div className="text-center">
                      <div>
                        <button
                          onClick={this.SelectRandomNumber}
                          className="button confrim"
                        >
                          {interpreter("randomTicket")}
                        </button>
                      </div>
                      <button
                        className="blue_button button"
                        onClick={this.handleSubmit}
                      >
                        {interpreter("submitTickets")}
                      </button>
                    </div>
                  ) : (
                    <div className="text-center">
                      <div>
                        <button
                          onClick={this.SelectRandomNumber}
                          className="button confrim"
                        >
                          {interpreter("randomTicket")}
                        </button>
                      </div>
                      <button
                        className="blue_button button"
                        onClick={this.submitAddBlance}
                      >
                        {interpreter("balanceIsNotEnough")}
                      </button>
                    </div>
                  )
                ) : (
                  <div className="text-center">
                    <div>
                      <button
                        onClick={this.SelectRandomNumber}
                        className="button confrim"
                      >
                        {interpreter("randomTicket")}
                      </button>
                    </div>
                    <button
                      className="blue_button button"
                      onClick={this.submitLogin}
                    >
                      {interpreter("YouMustLogin")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Layout(TornomentNew);
